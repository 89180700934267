import { type FC, type PropsWithChildren, useEffect, useState, useContext } from "react";
import useSurveyWSNotification from "./hooks/websocket/survey/useSurvey";
import SurveyPassingModal from "./components/modal/compositions/survey-passing-modal";
import { ProfileContext } from "./context/profile";

interface SurveyModalControllerProps extends PropsWithChildren {}

const SurveyModalController: FC<SurveyModalControllerProps> = ({ children }) => {
  const [isModalShow, setIsModalShow] = useState(false);
  const { latestSurvey, getQuestionById, currentQuestion, updateQuestionAnswers } =
    useSurveyWSNotification();
  const { profile, settings } = useContext(ProfileContext);
  useEffect(() => {
    if (
      latestSurvey &&
      !latestSurvey.finished &&
      !profile?.isEmptyProfile &&
      !settings?.isEmptySettings
    ) {
      setIsModalShow(true);
    }
  }, [latestSurvey, profile, settings]);
  return (
    <>
      {children}
      <SurveyPassingModal
        updateQuestionAnswers={updateQuestionAnswers}
        currentQuestion={currentQuestion}
        getQuestionById={getQuestionById}
        surveyData={latestSurvey}
        showModal={isModalShow}
        onClose={() => {
          setIsModalShow(false);
        }}
      />
    </>
  );
};

export default SurveyModalController;

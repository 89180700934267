import classNames from "classnames";
import { type InputHTMLAttributes, forwardRef } from "react";

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & { error?: string };

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ className, type = "text", error, ...restProps }, ref) => {
    return (
      <>
        <input
          ref={ref}
          type={type}
          style={error ? { border: "1px solid #EC0B43" } : {}}
          className={classNames(
            "rounded-[10px] border border-[#EDEDED] p-4 text-fonts-darken disabled:border tb:rounded-xl tb:p-6 dk:rounded-2xl dk:p-8",
            "disabled: disabled:opacity-1 border-0 focus:border-[1px] focus:border-input-outline disabled:border-[#BFBFBF] disabled:bg-[#fff] disabled:text-[#bfbfbf]",
            className,
          )}
          {...restProps}
        />
        <div className="mx-[6px] my-[2px] text-sm leading-4 text-warn tb:text-lg tb:leading-5 dk:text-2xl dk:leading-6">
          {error}
        </div>
      </>
    );
  },
);
TextInput.displayName = "TextInput";
export default TextInput;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import ReactModal from "react-modal";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// import * as Sentry from "@sentry/react";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const timer = require("moment-timer"); // init moment-timer (plugin for momentjs)

// Sentry.init({
//   dsn: "https://4cffba687ef5b4fe5dfeac1516509f2c@o4506242522087424.ingest.sentry.io/4506242534998016",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["t1.dbagel.co"],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 0.1, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
ReactModal.setAppElement(rootElement);

// Use your config values here.
export const firebaseApp = initializeApp({
  apiKey: "AIzaSyAWiixG7PxQhE5hcEJLvQACc9S0LxiIAT0",
  authDomain: "double-bagel-2.firebaseapp.com",
  projectId: "double-bagel-2",
  storageBucket: "double-bagel-2.appspot.com",
  messagingSenderId: "910557452817",
  appId: "1:910557452817:web:6d7c244b661b898aff2fb0",
  measurementId: "G-XYMVS90NB9",
});
let firebaseMessaging;
try {
  firebaseMessaging = getMessaging(firebaseApp);
} catch (e: any) {
  firebaseMessaging = undefined;
}
export const messaging = firebaseMessaging;
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { type FC, useState } from "react";
import Image from "@double-bagel/components/image";
import DefaultModal from "../modal/default";

const FindCourtButton: FC<{ courtBookingImage: string; courtBookingUrl: string }> = ({
  courtBookingImage,
  courtBookingUrl,
}) => {
  const [showBookingModal, setShowBookingModal] = useState(false);

  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => {
          setShowBookingModal(true);
        }}
      >
        <div className="flex gap-4 rounded-xl bg-white p-5 text-blueActive">
          <div className="flex flex-shrink flex-grow-0">
            <Image
              src={courtBookingImage}
              alt="Map Image"
              classNameWrapper="max-h-[45px] tb:max-h-[55px] dk:max-h-[65px]"
              className="max-h-[45px] tb:max-h-[55px] dk:max-h-[65px]"
            />
          </div>
          <div className="flex-shrink flex-grow-0 basis-4/5 cursor-pointer self-center font-GolosText text-base tb:text-lg dk:text-xl">
            Find and book the court to play your match
          </div>
        </div>
      </div>
      {courtBookingImage && courtBookingUrl && (
        <DefaultModal
          fullSize
          widthExtraSpaceBottom
          title="Courts map"
          showModal={showBookingModal}
          onClose={() => {
            setShowBookingModal(false);
          }}
        >
          <iframe src={courtBookingUrl} className="h-full w-full" />
        </DefaultModal>
      )}
    </>
  );
};

export default FindCourtButton;

import { type FC } from "react";
import useAuth from "@double-bagel/hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "@double-bagel/assets/logo.svg";
import Image from "../image";
import classNames from "classnames";
import NotificationBellButton from "@double-bagel/components/buttons/header/notification-bell-button";
import LeaderboardButton from "@double-bagel/components/buttons/header/leaderboard-button";
import NavigationBack from "../buttons/nav-back";

interface AuthorizedHeaderProps {
  image?: string;
  page?: string;
  title?: string;
  highlighed: boolean;
  isShowNotificationsButton: boolean;
}

const AuthorizedHeader: FC<AuthorizedHeaderProps> = ({
  image,
  page,
  title,
  highlighed,
  isShowNotificationsButton,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isShowNavBack = window.isMobileApp && location.key !== "default";
  const cls = classNames("rounded-full", {
    "border border-[2px] border-border-greenAcid dk:p-1 tb:p-[3px] p-[1px] object-cover aspect-[1] object-center":
      highlighed,
    "border-0 p-2 dk:w-[65px] dk:h-[65px] tb:w-[60px] tb:h-[60px] w-11 h-11": !highlighed,
  });
  const { authState } = useAuth();
  return (
    <div className="grid min-h-[72px] grid-cols-3 bg-[#FFFFFF] px-5 py-4 tb:px-10 tb:py-5 dk:px-10 dk:py-6">
      {isShowNavBack && location.pathname !== "/" && (
        <NavigationBack
          className="h-[14px] w-[12px] tb:h-[17.5px] tb:w-[15px] dk:h-[21px] dk:w-[18px]"
          onClick={() => {
            navigate(-1);
          }}
        />
      )}
      {(!isShowNavBack || location.pathname === "/") && (
        <Link to="/">
          <Image
            src={Logo}
            alt="logo"
            className="h-10 cursor-pointer xs:h-10 xs:w-[80px] tb:h-[65px] tb:w-[135px] dk:h-[75px] dk:w-[150px]"
          />
        </Link>
      )}

      <div className="ThreeDots self-center justify-self-center text-center tb:text-xl dk:text-3xl">
        {title}
      </div>
      <div className="self-center justify-self-end">
        {authState?.userId && (
          <div className="flex-flow-row flex items-center gap-0.5 tb:gap-1.5 dk:gap-0">
            <LeaderboardButton />
            {isShowNotificationsButton && <NotificationBellButton />}
            <Link to={`${page}`}>
              <div className="relative ml-2 flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-background tb:ml-2 tb:h-[63px] tb:w-[63px] dk:ml-[20px] dk:h-[76px] dk:w-[75px]">
                {image && (
                  <Image
                    className={classNames("flex rounded-full", cls)}
                    classNameWrapper="z-10"
                    src={image ?? ""}
                    alt={"Current user avatar"}
                  />
                )}

                <div
                  className={classNames(
                    "bg-skeleton-animated absolute z-0 h-full w-full animate-animateSkeletonBackdrop rounded-full opacity-80",
                  )}
                />
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthorizedHeader;

import axios from "axios";
import { type UserSettings, type UserProfile, type UserAvatar } from "./@types/user";
import { type ClientModel } from "./adapter/client-models";
import asHook from "./adapter/hook";
import { type BackendModels } from "./@types/models";
import { type PhoneCodeResponse } from "./enpdoints.auth";

type UpdatePorfileData = Partial<
  ClientModel<
    Omit<UserProfile, "racket_brand" | "avatar"> & {
      racketBrand: BackendModels.ID;
      avatar: BackendModels.ID;
    }
  >
>;
export const useUpdateUserProfile = asHook(
  async (data: UpdatePorfileData): Promise<UserProfile> => {
    const formattedData: Partial<UpdatePorfileData> = {
      first_name: data.firstName,
      email: data.email,
      city: data.city,
      gender: data.gender,
      level: data.level,
      strategy: data.strategy,
      racket_brand: data.racketBrand,
      hand: data.hand,
      height: data.height,
      avatar: data.avatar,
      bio: data.bio,
    };
    const result = await axios.patch<UserProfile>(`/profile/self_update/`, {
      ...formattedData,
    });
    return result.data;
  },
  "UserProfile",
);

export const useGetCurrentUser = asHook(async () => {
  const result = await axios.get<UserProfile>(`/profile/self/`);
  return result.data;
}, "UserProfile");

export const useGetUserById = asHook(async (id: BackendModels.ID) => {
  const result = await axios.get<UserProfile>(`/profile/${id}/`);
  return result.data;
}, "UserProfile");

export const useUpdateUserSettings = asHook(
  async (data: Partial<ClientModel<UserSettings>>): Promise<UserSettings> => {
    const result = await axios.patch<UserSettings>(`/settings/self_update/`, {
      ...data,
      unit_of_len: data.unitOfLen,
      notifications: {
        notifications_by_email: data.notifications?.notificationsByEmail,
        email_congratulations: data.notifications?.emailCongratulations,
        email_game_reminders: data.notifications?.emailGameReminders,
        email_leaderboard_updated: data.notifications?.emailLeaderboardUpdated,
        email_promos: data.notifications?.emailPromos,
        email_score_set_reminder: data.notifications?.smsScoreSetReminder,
        notifications_by_sms: data.notifications?.notificationsBySms,
        sms_congratulations: data.notifications?.smsCongratulations,
        sms_game_reminders: data.notifications?.smsGameReminders,
        sms_leaderboard_updated: data.notifications?.smsLeaderboardUpdated,
        sms_promos: data.notifications?.smsPromos,
        sms_score_set_reminder: data.notifications?.smsScoreSetReminder,
      },
    });
    return result.data;
  },
  "UserSettings",
);

export const useGetSetiings = asHook(async (): Promise<UserSettings> => {
  const result = await axios.get<UserSettings>(`/settings/self/`);
  return result.data;
}, "UserSettings");

export const useGetDefaultAvatars = asHook(async (): Promise<UserAvatar[]> => {
  const result = await axios.get<UserAvatar[]>("/avatar/");
  return result.data;
}, "UserAvatar");

export const useUpdatePhoneSendCurrentCode = asHook(
  async (phone: string): Promise<PhoneCodeResponse> => {
    const result = await axios.post<PhoneCodeResponse>("/phone/send_current/", {
      phone,
    });
    return result.data;
  },
  "",
);

export const useUpdatePhoneSendVerifyCurrentCode = asHook(
  async (code: string): Promise<{ verification_id: BackendModels.ID }> => {
    const result = await axios.post<{ code: string; verification_id: BackendModels.ID }>(
      "/phone/verify_current/",
      { code },
    );
    return result.data;
  },
  "",
);

export const useUpdatePhoneSendNewPhoneNumber = asHook(
  async (oldVerificationId: string, phone: string): Promise<PhoneCodeResponse> => {
    const result = await axios.post<PhoneCodeResponse>("/phone/send_new/", {
      phone,
      old_verification_id: oldVerificationId,
    });
    return result.data;
  },
  "",
);

export const useUpdatePhone = asHook(
  async (
    verificationId: string,
    oldVerificationId: string,
    code: string,
  ): Promise<Record<string, unknown>> => {
    const result = await axios.post<Record<string, unknown>>("/phone/update_phone/", {
      verification_id: verificationId,
      old_verification_id: oldVerificationId,
      code,
    });
    return result.data;
  },
  "",
);

export const useUpdateEmail = asHook(async (newEmail: string): Promise<Record<string, unknown>> => {
  const result = await axios.post<Record<string, unknown>>("/email/update_email/", {
    email: newEmail,
  });
  return result.data;
}, "");

export const useVerifyEmail = asHook(async (key: string): Promise<Record<string, unknown>> => {
  const result = await axios.post<Record<string, unknown>>("/email/verify_email/", {
    key,
  });
  return result.data;
}, "");

export const useUploadCustomAvatar = asHook(
  async (file: File): Promise<{ id: BackendModels.ID; image: string }> => {
    const result = await axios.patch<{ id: BackendModels.ID; image: string }>(
      "/avatar/self_update/",
      {
        file,
      },
      { headers: { "Content-Type": "multipart/form-data" } },
    );
    return result.data;
  },
  "",
);

export const useGetUserTotalResult = asHook(async (userId: BackendModels.ID) => {
  const result = await axios.get<BackendModels.TeamTotalResult>(`/user/${userId}/results/total`);
  return result.data;
}, "UserTotalResult");

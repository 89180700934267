import classNames from "classnames";
import { type FC, type HTMLAttributes } from "react";

const TextButton: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...rest }) => (
  <div
    className={classNames(
      "cursor-pointer text-center text-[14px] leading-[20px] text-blueActive",
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);

export default TextButton;

import PlayerAvatar from "@double-bagel/components/avatar/default";
import AuthorizedLayout from "@double-bagel/components/layouts/AuthorizedLayout";
import { ProfileContext } from "@double-bagel/context/profile";
import { useGetTeamDetail } from "@double-bagel/endpoints/endpoint.team";
import useAuth from "@double-bagel/hooks/useAuth";
import classNames from "classnames";
import { useContext, useEffect, type FC } from "react";
import { Link, useParams } from "react-router-dom";
import Image from "@double-bagel/components/image";
import NavIcon from "@double-bagel/assets/Icons/arrow.svg";

const TeamDetailPage: FC = () => {
  const { profile } = useContext(ProfileContext);
  const { id } = useParams();
  const { execute: getTeam, value: teamData, isFetching: isTeamFetching } = useGetTeamDetail();
  useEffect(() => {
    if (id) {
      void getTeam(Number(id));
    }
  }, []);
  const { authState } = useAuth();
  return (
    <AuthorizedLayout
      title={teamData?.name ?? ""}
      image={profile?.avatar?.image ?? ""}
      page="/user"
      cls="dk:mt-6 tb:mt-5 mt-1"
      isLoading={isTeamFetching}
    >
      {teamData?.members && (
        <div className="flex w-full flex-col gap-4 rounded-xl bg-white px-4 py-5 tb:px-5 tb:py-6 dk:px-6 dk:py-7">
          {teamData.members.map((player, index) => (
            <Link
              key={player.id}
              className={classNames(
                "text-base tb:text-xl dk:text-3xl",
                {
                  "font-bold text-[#A7CE00]": player.isMe,
                  "": !player.isMe,
                },
                "cursor-pointer",
              )}
              to={`/user/${player.user}`}
            >
              <div className={"flex basis-full"}>
                <div className="flex grow flex-row gap-2 tb:gap-3 dk:gap-4">
                  <div className="flex items-center">
                    <span
                      className={classNames(
                        "rounded-[5px] bg-[#141E23] px-2 py-1.5 text-[10px]  leading-3 text-[#FFFFFF] tb:px-3 tb:text-sm tb:leading-5 dk:rounded-[7px] dk:px-4 dk:text-lg dk:leading-7",
                      )}
                    >
                      {index + 1}
                    </span>
                  </div>
                  <div className="flex items-center justify-center">
                    <PlayerAvatar
                      className={classNames(
                        "h-10 w-10 tb:h-[60px] tb:w-[60px] dk:h-[100px] dk:w-[100px]",
                        "rounded-full",
                        {
                          "border-[2px] border-border-greenAcid p-[1px] tb:p-[3px] dk:p-1":
                            player.user.toString() === authState?.userId,
                          "border-0": !player.isMe,
                        },
                      )}
                      classNameWrapper="h-10 w-10 tb:h-[60px] tb:w-[60px] dk:h-[100px] dk:w-[100px]"
                      image={player?.avatar?.image ?? ""}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-center">{player.firstName}</div>
                </div>
                <div className="flex justify-items-end self-center justify-self-end">
                  <Link to={`/user/${player.user}`}>
                    <Image
                      alt="arrow"
                      src={NavIcon}
                      className={classNames("mt-1.5 h-3 w-3 tb:h-4 tb:w-4 dk:h-4 dk:w-6")}
                    />
                  </Link>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </AuthorizedLayout>
  );
};

export default TeamDetailPage;

import { type FC } from "react";
import { TournamentLevel, TournamentGenders } from "@double-bagel/endpoints/@types/tournament";
import type { TournamentStatus } from "@double-bagel/endpoints/@types/tournament";
import classNames from "classnames";
import Emoji from "a11y-react-emoji/lib/Emoji";

const imageByLevel = {
  [TournamentLevel.CASUAL]: <Emoji symbol={"💪"} className="text-xs tb:text-xl dk:text-2xl" />,
  [TournamentLevel.EXPERT]: <Emoji symbol={"😈"} className="text-xs tb:text-xl dk:text-2xl" />,
  [TournamentLevel.ROOKIES]: <Emoji symbol={"👶"} className="text-xs tb:text-xl dk:text-2xl " />,
};

const imageByGender = {
  [TournamentGenders.MAN]: <Emoji symbol={"👨"} className="text-xs tb:text-xl dk:text-2xl" />,
  [TournamentGenders.WOMAN]: <Emoji symbol={"👩"} className="text-xs tb:text-xl dk:text-2xl" />,
  [TournamentGenders.DIVERSE]: (
    <div>
      <Emoji symbol={"👨"} className="text-xs tb:text-xl dk:text-2xl" />
      <Emoji symbol={"👩"} className="text-xs tb:text-xl dk:text-2xl" />
    </div>
  ),
};

type GenderWithLevelProps = {
  gender: keyof typeof TournamentGenders;
  level: keyof typeof TournamentLevel;
  className?: string;
  status: keyof typeof TournamentStatus;
};

const genderTitle = {
  [TournamentGenders.MAN]: "men",
  [TournamentGenders.WOMAN]: "women",
  [TournamentGenders.DIVERSE]: "diverse",
};
type BackgroundProps = {
  gender: keyof typeof TournamentGenders;
  level: keyof typeof TournamentLevel;
  status: keyof typeof TournamentStatus;
};

function BackgroundByLevelFuntion({ gender, level, status }: BackgroundProps): string {
  const backgroundByLevel = {
    [TournamentLevel.EXPERT]: {
      MAN: gender === "MAN" ? "bg-[#AADEFF]" : "",
      WOMAN: gender === "WOMAN" ? "bg-[#EEB7FF]" : "",
      DIVERSE: "bg-[#BBFFDB]",
    },
    [TournamentLevel.ROOKIES]: {
      MAN: gender === "MAN" ? "bg-[#EDF8FF]" : "",
      WOMAN: gender === "WOMAN" ? "bg-[#FAEAFF]" : "",
      DIVERSE: "bg-[#BBFFDB]",
    },
    [TournamentLevel.CASUAL]: {
      MAN: gender === "MAN" ? "bg-[#CEECFF]" : "",
      WOMAN: gender === "WOMAN" ? "bg-[#F3CCFF]" : "",
      DIVERSE: "bg-[#BBFFDB]",
    },
  };
  let backgroundClass = "";

  if (status !== "FINISHED") {
    backgroundClass = backgroundByLevel[level][gender];
  } else {
    backgroundClass = "bg-[#DBDBDB]";
  }
  return backgroundClass;
}

function BackgroundByGenderFuntion({ gender, status }: BackgroundProps): string {
  const backgroundByGender = {
    [TournamentGenders.MAN]: "bg-[#C8F8FF]",
    [TournamentGenders.WOMAN]: "bg-[#F8CBF4]",
    [TournamentGenders.DIVERSE]: "bg-[#FFADB7]",
  };
  let backgroundClass = "";

  if (status !== "FINISHED") {
    backgroundClass = backgroundByGender[gender];
  } else {
    backgroundClass = "bg-[#DBDBDB]";
  }
  return backgroundClass;
}

const GenderWithLevel: FC<GenderWithLevelProps> = ({ gender, level, className, status }) => {
  const getBackgroundByLevelClass = BackgroundByLevelFuntion({ gender, level, status });
  const getBackgroundByGenderClass = BackgroundByGenderFuntion({ gender, level, status });
  return (
    <div className={classNames("flex flex-1 ", className)}>
      <div
        className={classNames(
          "mr-1 flex flex-initial items-center rounded-[7px] border-2 border-[#FFFFFF] py-1.5 pl-3 pr-4 text-[10px] font-bold lowercase leading-normal text-blackPearl tb:mr-2 tb:rounded-[10px] tb:py-2 tb:pl-5 tb:pr-6 tb:text-sm dk:mr-3 dk:rounded-[14px] dk:border-[5px] dk:py-3 dk:pl-6 dk:pr-7 dk:text-lg",
          getBackgroundByGenderClass,
        )}
      >
        <span className="mr-1 flex self-center tb:mr-1 tb:pb-1 dk:mr-2">
          {imageByGender[gender]}
        </span>{" "}
        <span className="flex self-center">{genderTitle[gender]}</span>
      </div>
      <div
        className={classNames(
          "mr-1 flex flex-initial items-center rounded-[7px] border-2 border-[#FFFFFF] px-3 py-1.5  pr-4 text-[10px] font-bold lowercase leading-normal text-blackPearl tb:mr-2 tb:rounded-[10px] tb:py-2 tb:pl-5 tb:pr-6 tb:text-sm dk:mr-3 dk:rounded-[14px] dk:border-[5px] dk:py-3 dk:pl-6 dk:pr-7 dk:text-lg",
          getBackgroundByLevelClass,
        )}
      >
        <span className="mr-1 flex self-center tb:mr-1 tb:pb-1 dk:mr-2">{imageByLevel[level]}</span>{" "}
        <span className="flex self-center">{level === "EXPERT" ? "beasts" : level}</span>
      </div>
    </div>
  );
};

export default GenderWithLevel;

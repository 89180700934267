import { AuthLocalStorage } from "@double-bagel/auth-local-storage";
import { getAppConfigValue } from "@double-bagel/helpers";
import { useCallback, useEffect, useState } from "react";
import useWebSocket, { type ReadyState } from "react-use-websocket";
import { type SendJsonMessage } from "react-use-websocket/dist/lib/types";

export type WSMessage<T> = {
  [P in keyof T]: T[P];
} & { MSG_TYPE: "GET_SURVEY" | "GET_QUESTION" | "CHANGE_ANSWERS"; error?: string };

export type SurveyNotificationMessage = WSMessage<{
  id: number;
  name: string;
  actualQID: number;
  questions: Array<number>;
  finished: boolean;
}>;

export type QuestionByIdResult = WSMessage<{
  pk: number;
  type: "SINGLE" | "MULTIPLE";
  value: string;
  answers: {
    [id: number]: number | string;
  };
  variants: {
    [id: number]: string;
  };
}>;

const authStorage = new AuthLocalStorage();

const deserializeMessage = (msg: string): SurveyNotificationMessage | QuestionByIdResult =>
  JSON.parse(msg);

const useSurveyWSNotification = (): {
  latestSurvey: SurveyNotificationMessage | null;
  readyState: ReadyState;
  getQuestionById: (questionId: number) => void;
  currentQuestion: QuestionByIdResult | null;
  updateQuestionAnswers: (
    questionId: number,
    variantIds?: Array<number>,
    variantText?: string,
  ) => void;
  sendJsonMessage: SendJsonMessage;
} => {
  const wsApiRoot = getAppConfigValue("WS_API_ENDPOINT") ?? "ws://127.0.0.1:8000/ws/";
  const surveyWsUrl = `${wsApiRoot}survey/`;

  const token = authStorage.getToken();
  const { lastMessage, readyState, sendJsonMessage } = useWebSocket<never>(surveyWsUrl, {
    protocols: [token],
    retryOnError: true,
  });
  const [latestSurvey, setLatestSurvey] = useState<SurveyNotificationMessage | null>(null);
  const [currentQuestion, setCurrentQuestion] = useState<QuestionByIdResult | null>(null);
  useEffect(() => {
    if (lastMessage?.data) {
      const obj = deserializeMessage(lastMessage?.data);
      if (obj.error) {
        console.error(obj.error);
      }
      switch (obj.MSG_TYPE) {
        case "GET_SURVEY": {
          const obj = deserializeMessage(lastMessage?.data);
          setLatestSurvey(obj as SurveyNotificationMessage);
          break;
        }
        case "GET_QUESTION": {
          const obj = deserializeMessage(lastMessage?.data);
          setCurrentQuestion(obj as QuestionByIdResult);
          break;
        }
        case "CHANGE_ANSWERS": {
          const obj = deserializeMessage(lastMessage?.data);
          setLatestSurvey(obj as SurveyNotificationMessage);
          break;
        }
      }
    }
  }, [lastMessage]);
  const getQuestionById = useCallback((questionId: number) => {
    sendJsonMessage({
      MSG_TYPE: "GET_QUESTION",
      id: questionId,
    });
  }, []);
  const updateQuestionAnswers = useCallback(
    (questionId: number, variantIds?: Array<number>, variantText?: string) => {
      sendJsonMessage({
        MSG_TYPE: "CHANGE_ANSWERS",
        question_id: questionId,
        ...(variantIds ? { variant_ids: variantIds } : {}),
        ...(variantText ? { variant_text: variantText } : {}),
      });
    },
    [],
  );
  return {
    latestSurvey,
    readyState,
    sendJsonMessage,
    getQuestionById,
    currentQuestion,
    updateQuestionAnswers,
  };
};

export default useSurveyWSNotification;

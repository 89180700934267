import { useContext, useEffect, useState } from "react";
import { AuthContext, type AuthState, type AuthContextType } from "@double-bagel/context/auth";
import {
  type PhoneCodeResponse,
  type VerifyCodeResponse,
  type SignUpResponse,
  useSendPhoneCode,
  useVerifyPhoneCode,
  useSignUp,
} from "@double-bagel/endpoints/enpdoints.auth";
import { DoubleBagelAppException } from "@double-bagel/endpoints/adapter/errors";
import { AuthLocalStorage } from "@double-bagel/auth-local-storage";

const STORAGE_INSTANCE = new AuthLocalStorage();

type useAuthInitResult = {
  value: {
    auth: AuthState | null;
    setAuth: React.Dispatch<React.SetStateAction<AuthState | null>>;
  };
  Provider: React.Provider<AuthContextType>;
};
export const useAuthInit = (): useAuthInitResult => {
  const [auth, setAuth] = useState<AuthState | null>(null);
  useEffect(() => {
    if (!auth?.userId) {
      const fromJWT = STORAGE_INSTANCE.deserialize();
      if (fromJWT) setAuth((prev) => ({ ...fromJWT }));
    }
  }, []);
  return { value: { auth, setAuth }, Provider: AuthContext.Provider };
};
export const useAuthContext = (): AuthContextType => {
  return useContext(AuthContext);
};

type ResultWithError<R, E = Error> = {
  result: R | null;
  error: E | null;
};

export default function useAuth(): {
  authState?: AuthState | null;
  sendVerificationCode: (phone: string) => Promise<ResultWithError<PhoneCodeResponse | null>>;
  verifyCode: (
    smsCode: string,
    verificationId?: string,
  ) => Promise<ResultWithError<VerifyCodeResponse | null>>;
  signUpByPhoneVerificationId: (
    verificationId: string,
  ) => Promise<ResultWithError<SignUpResponse | null>>;
  logout: () => void;
  error?: Error;
  isAuthenticated: () => boolean;
} {
  const [error, setError] = useState<Error | undefined>();
  const { setAuth, auth: authState } = useAuthContext();
  const { execute: sendPhoneCode, value: phoneCodeResponse } = useSendPhoneCode();
  const { execute: verifyPhoneCode } = useVerifyPhoneCode();
  const { execute: signUp } = useSignUp();
  const sendVerificationCode = async (
    phone: string,
  ): Promise<ResultWithError<PhoneCodeResponse | null>> => {
    setError(undefined);
    const { result, error } = await sendPhoneCode(phone);
    if (error) {
      setError(error);
      return { result: null, error };
    }
    return { result, error: null };
  };

  const verifyCode = async (
    smsCode: string,
    verificationId?: string,
  ): Promise<ResultWithError<VerifyCodeResponse | null>> => {
    if (!phoneCodeResponse?.verificationId && !verificationId) {
      throw new DoubleBagelAppException("TEMP: Check useAuth function."); // TODO проверка хуеты
    }
    const { result, error } = await verifyPhoneCode(
      verificationId ?? (phoneCodeResponse?.verificationId as string),
      smsCode,
    );
    if (error) {
      setError(error);
      return { result: null, error };
    }
    if (result?.auth?.token) {
      STORAGE_INSTANCE.setToken(result?.auth?.token);
      setAuth(STORAGE_INSTANCE.deserialize());
    }
    return { result, error: null };
  };

  const signUpByPhoneVerificationId = async (
    verificationId: string,
  ): Promise<ResultWithError<SignUpResponse | null>> => {
    const { result, error } = await signUp(verificationId);
    if (error) {
      setError(error);
      return { result: null, error };
    }
    if (result) {
      STORAGE_INSTANCE.setToken(result.auth.token);
      setAuth(STORAGE_INSTANCE.deserialize());
    }
    return { result, error: null };
  };

  const logout = (): void => {
    STORAGE_INSTANCE.clear();
    setAuth(STORAGE_INSTANCE.deserialize());
  };
  const isAuthenticated = (): boolean => !!authState?.exp;
  return {
    authState,
    sendVerificationCode,
    verifyCode,
    signUpByPhoneVerificationId,
    logout,
    error,
    isAuthenticated,
  };
}

import Modal from "react-modal";
import { type FC } from "react";
import SucssessBackground from "@double-bagel/assets/background/success.svg";

type CentralizedModalProps = {
  showModal: boolean;
  children?: React.ReactNode;
};

const CentrilizedContent = (
  props: React.ComponentPropsWithRef<"div">,
  children: React.ReactNode,
): React.ReactElement => {
  const { style, className, ...restProps } = props;
  return (
    <div
      {...restProps}
      className="absolute left-[50%] top-[50%] m-auto h-auto w-[90%] translate-x-[-50%] translate-y-[-50%] overflow-auto bg-[#ffffff00] tb:w-auto"
    >
      {children}
    </div>
  );
};

export const CentralizedModal: FC<CentralizedModalProps> = ({ showModal, children }) => {
  return (
    <Modal
      isOpen={showModal}
      overlayClassName={"fixed bg-[#00000099] inset-0 z-50"}
      contentElement={CentrilizedContent}
    >
      <div className="w-full rounded-[25px] bg-[#fff] p-[30px] tb:w-[550px] dk:w-[750px]">
        <div className="flex h-full w-full flex-col items-center justify-center gap-0">
          {children}
        </div>
      </div>
    </Modal>
  );
};

export const SuccessCentralizedModal: FC<CentralizedModalProps> = ({ showModal, children }) => {
  return (
    <Modal
      isOpen={showModal}
      overlayClassName={"fixed bg-[#00000099] inset-0 z-50"}
      contentElement={CentrilizedContent}
    >
      <div className="w-full rounded-[25px] bg-[#fff] p-[30px] tb:w-[550px] dk:w-[750px]">
        <div className="flex h-full w-full flex-col items-center justify-center gap-0">
          {children}
        </div>
        <div>
          <img
            src={SucssessBackground}
            className="absolute inset-0 aspect-auto h-full w-full object-cover opacity-50"
          />
        </div>
      </div>
    </Modal>
  );
};

export default CentralizedModal;

import { type FC, useContext, useEffect, useRef, type MutableRefObject, useState } from "react";
import DefaultModal, { type DefaultModalProps } from "../default";
import { Select, type Value } from "baseui/select";
import RadioButtonGroup from "@double-bagel/components/input/radio-button-group";
import ResponsiveEmoji from "@double-bagel/components/responsive/emoji";

import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";
import Emoji from "a11y-react-emoji/lib/Emoji";
import classNames from "classnames";
import {
  FiltersContext,
  type FiltersState,
  GendersFilter,
  LevelsFilter,
  EmptyFilterValue,
} from "@double-bagel/context/filter";
import { ProfileContext } from "@double-bagel/context/profile";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type CityModel } from "@double-bagel/endpoints/@types/city";

type MatchFilterModalProps = Pick<DefaultModalProps, "onClose" | "showModal">;

const EmojiGender: FC<{ children?: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <div className={classNames(className, "flex")}>
    <div className="relative w-8 tb:mr-2 dk:mr-3">
      <Emoji symbol="👨" className="absolute left-0" />
      <Emoji symbol="👩" className="absolute left-[8px]" />
    </div>
    {children}
  </div>
);

const EmojiLevel: FC<{ children?: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <div className={classNames(className, "flex")}>
    <Emoji symbol="🤓" className="mr-1 tb:mr-2 dk:mr-3" />
    {children}
  </div>
);

export const DescriptionLevel: FC<{
  title?: string;
  description?: string;
  detailNTPR?: string;
}> = ({ title, description, detailNTPR }) => (
  <div>
    <span className="font-bold">{title} </span>
    {description}
    <p className="font-bold">{detailNTPR}</p>
  </div>
);

interface City {
  label: string;
  id: string;
}

const MatchFilterModal: FC<
  MatchFilterModalProps & {
    cities?: ClientModel<CityModel>[];
  }
> = ({ onClose, showModal, cities }) => {
  const ref = useRef<HTMLDivElement>();
  const { filters, setFilters } = useContext(FiltersContext);
  const { settings } = useContext(ProfileContext);
  const [city, setCity] = useState<Value>([]);
  useEffect(() => {
    if (filters?.city) {
      setCity([{ label: filters?.city, id: filters?.city }]);
    } else {
      setCity([]);
    }
  }, [filters?.city, cities]);
  const FilterItemDescription = {
    Level: {
      [LevelsFilter.ROOKIES]: (
        <DescriptionLevel
          title="Newcomers"
          description="still learning basic strokes and understanding court coverage. It's about enjoying the game and gradually improving, with plenty of fun along the way."
          detailNTPR="Expected NTPR Rating: 2.0-3.0 and above"
        />
      ),
      [LevelsFilter.CASUAL]: (
        <DescriptionLevel
          title="Intermediate players"
          description="seeking a balance of competitive play and social interaction. Expect consistent strokes, fewer double faults, and a growing sense of strategy."
          detailNTPR="Expected NTPR Rating: 3.5-4.0 and above"
        />
      ),
      [LevelsFilter.EXPERT]: (
        <DescriptionLevel
          title="Expert players"
          description="dominating the courts and ruling local tournaments. Powerful strokes, minimal errors, and advanced strategic gameplay."
          detailNTPR="Expected NTPR Rating: 4.5 and above"
        />
      ),
    },
    Gender: {},
  };
  const options: Record<string, City[]> | undefined = cities?.reduce<Record<string, City[]>>(
    (acc, city) => ({
      ...acc,
      [city.country]: [...(acc?.[city.country] || []), { label: city.name, id: city.name }],
    }),
    {},
  );
  return (
    <DefaultModal
      fullSize
      widthExtraSpaceBottom
      title="Filter"
      showModal={showModal}
      onClose={() => {
        onClose();
      }}
    >
      <div
        className="max-h-[calc(100svh-100px)] overflow-y-auto px-4 py-4 dk:px-8 dk:py-8"
        ref={ref as MutableRefObject<HTMLDivElement>}
      >
        <h4 className="pb-3 font-Unbounded text-lg leading-[28px] text-fonts-primary tb:mr-3 tb:pb-4 tb:text-2xl dk:pb-6 dk:text-3xl">
          <ResponsiveEmoji symbol="📍" className="mr-1 tb:mr-2 dk:mr-3" />
          Location
        </h4>

        <Select
          escapeClearsValue
          clearable={false}
          mountNode={ref.current}
          placeholder="Select your city"
          value={city?.[0]?.id === EmptyFilterValue ? undefined : city}
          onChange={(params) => {
            const selectedCity = params.option?.id?.toString();
            const { type } = params;
            if (selectedCity && type === "select") {
              setFilters((prev) => (prev ? { ...prev, city: selectedCity } : prev));
            } else {
              setFilters((prev) => (prev ? { ...prev, city: EmptyFilterValue } : prev));
            }
          }}
          options={options}
        />
        <div className="dk:text2xl pb-8 pt-[14px] font-GolosText text-sm leading-[20px] text-fonts-optional tb:pb-10 tb:pt-4 tb:text-lg dk:pb-16 dk:pt-6">
          City not listed?{" "}
          <a
            className="text-blueActive"
            href="https://doublebagel.co/launch_my_city"
            target="_blank"
            rel="noreferrer"
          >
            Let us know
          </a>{" "}
          to bump it up the queue!
        </div>
        <div className="flex flex-col gap-2">
          <RadioButtonGroup
            selectedOption={filters?.gender}
            options={
              {
                [GendersFilter.MAN]: "Men",
                [GendersFilter.WOMAN]: "Women",
              } as const
            }
            title={{
              value: "Gender",
              titleClass:
                "font-Unbounded text-fonts-primary text-lg tb:text-2xl dk:text-3xl leading-[28px] dk:leading-9 tb:leading-9 pb-2 tb:pb-4 dk:pb-6",
              component: EmojiGender,
            }}
            renderItemDescription={(option) => {
              const value =
                FilterItemDescription.Gender[
                  option as keyof (typeof FilterItemDescription)["Gender"]
                ];
              return (
                value && (
                  <div className="dk:text2xl w-min  min-w-[80%] font-GolosText text-sm text-fonts-optional tb:text-lg ">
                    {value}
                  </div>
                )
              );
            }}
            onOptionSelect={function (option): void {
              let value: FiltersState["gender"] = GendersFilter.NoFilter;
              if (option === GendersFilter.MAN) {
                value = GendersFilter.MAN;
              }
              if (option === GendersFilter.WOMAN) {
                value = GendersFilter.WOMAN;
              }
              setFilters((prev) => (prev ? { ...prev, ...{ gender: value } } : prev));
            }}
          />
        </div>
        <div className="mb-10 flex flex-col gap-[8px] pt-8 tb:pt-10 dk:mb-16 dk:pt-16">
          <RadioButtonGroup
            selectedOption={filters?.level}
            options={
              {
                [LevelsFilter.ROOKIES]: "Rookie",
                [LevelsFilter.CASUAL]: "Casual",
                [LevelsFilter.EXPERT]: "Beast",
              } as const
            }
            title={{
              value: "Level of play",
              titleClass:
                "font-Unbounded text-fonts-primary text-lg leading-[28px] tb:text-2xl dk:text-3xl leading-[28px] dk:leading-9 tb:leading-9 pb-2 tb:pb-4 dk:pb-6",
              component: EmojiLevel,
            }}
            renderItemDescription={(option) => (
              <div className="dk:text2xl min-w-[80%] font-GolosText text-sm text-fonts-optional tb:text-lg">
                {
                  FilterItemDescription.Level[
                    option as keyof (typeof FilterItemDescription)["Level"]
                  ]
                }
              </div>
            )}
            onOptionSelect={function (option): void {
              if (option !== "LOCKED_EXPERT") {
                setFilters((prev: any) => ({
                  ...prev,
                  ...{ level: option as FiltersState["level"] },
                }));
              }
            }}
          />
        </div>
        <div className="mb-3 flex flex-col justify-stretch gap-3 tb:mb-6 tb:gap-6 dk:mb-8 dk:gap-8">
          <Button style={ButtonStyle.Primary} onClick={onClose}>
            See tournaments
          </Button>
          <Button
            style={ButtonStyle.Simple}
            onClick={() => {
              if (settings) {
                setFilters({
                  city: settings.city,
                  gender: settings?.gender ?? EmptyFilterValue,
                  level: settings?.level ?? EmptyFilterValue,
                  status: EmptyFilterValue,
                });
              }
            }}
          >
            Reset to default
          </Button>
        </div>
      </div>
    </DefaultModal>
  );
};

export default MatchFilterModal;

import { type FC } from "react";
import DefaultModal, { type DefaultModalProps } from "../default";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type RacketBrandModel } from "@double-bagel/endpoints/@types/racket-brand";
import { type BackendModels } from "@double-bagel/endpoints/@types/models";

type AllRulesModalProps = Pick<DefaultModalProps, "onClose" | "showModal"> & {
  selectedBrandId?: BackendModels.ID;
  onChange?: (value: ClientModel<RacketBrandModel>) => void;
};
const AllRulesModal: FC<AllRulesModalProps> = ({ showModal, onClose }) => {
  return (
    <DefaultModal
      fullSize
      widthExtraSpaceBottom
      title="Double Bagel: Rules and Code of Conduct"
      showModal={showModal}
      onClose={() => {
        onClose();
      }}
    >
      <iframe className="h-[calc(100vh_-_105px)] w-full" src={"https://static.dbagel.co/rules"} />
    </DefaultModal>
  );
};

export default AllRulesModal;

import classNames from "classnames";
import { type FC } from "react";

interface FreeEntryBadgeProps {
  className?: string;
  text?: string;
}

const FreeEntryBadge: FC<FreeEntryBadgeProps> = ({ className, text = "FREE entry!" }) => (
  <span
    className={classNames(
      "font-Unbounded font-bold text-button xs:text-minimal sm:text-xs",
      className,
    )}
  >
    {text}
  </span>
);

export default FreeEntryBadge;

import { type FC } from "react";
import NavigationBack from "../buttons/nav-back";
import { useNavigate } from "react-router-dom";
import CommonProgressBar from "../bars/common-progress-bar";

interface ProgressBarHeaderProps {
  progressbarFill: number;
  maxValue: number;
  onBackClick?: () => void;
}

const ProgressBarHeader: FC<ProgressBarHeaderProps> = ({
  progressbarFill,
  maxValue,
  onBackClick,
}) => {
  const handleBackClick = (): void => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate(-1);
    }
  };
  const navigate = useNavigate();
  return (
    <div className="grid min-h-[72px] bg-[#FFFFFF] px-5 py-4 xs:grid-cols-12 tb:grid-cols-10 tb:px-10 tb:py-5 dk:grid-cols-12 dk:px-10 dk:py-6">
      <div className="self-center xs:col-span-2 tb:col-span-1 dk:col-span-1">
        <NavigationBack
          className="h-[14px] w-[12px] tb:h-[17.5px] tb:w-[15px] dk:h-[21px] dk:w-[18px]"
          onClick={handleBackClick}
        />
      </div>
      <div className="self-center xs:col-span-10 tb:col-span-9 dk:col-span-11">
        <CommonProgressBar
          currentValue={progressbarFill + 1}
          maxValue={maxValue + 1}
          classname={"border-border-[#19A1FC] bg-[#19A1FC]"}
        ></CommonProgressBar>
      </div>
    </div>
  );
};

export default ProgressBarHeader;

import type React from "react";
import { type FC } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

export enum ButtonType {
  NavLink,
  Button,
}

export enum ButtonStyle {
  Primary,
  Secondary,
  Simple,
  Filter,
  InsideInput,
  Shadow,
  Warning,
  WarningSecondary,
  WarningSimple,
  Disabled,
  Complete,
  CompleteBig,
  ShadowBig,
  SecondaryBig,
  Copy,
}

export enum ButtonFontStyle {
  Unbounded,
  GolosText,
}

interface ButtonProps {
  style: ButtonStyle;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  label?: string;
  id?: string;
  buttonFontClass?: ButtonFontStyle;
  labelClassName?: string;
  isDisabled?: boolean;
  buttonType?: ButtonType;
  navTo?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  className?: string;
}

const Button: FC<ButtonProps> = ({
  style,
  onClick,
  children,
  label,
  id,
  buttonFontClass = ButtonFontStyle.GolosText,
  labelClassName,
  isDisabled = false,
  buttonType = ButtonType.Button,
  navTo,
  type,
  className,
}) => {
  const DefaultStyle =
    "dk:p-8 tb:p-4 p-6 dk:h-[100px] tb:h-[60px] xs:h-[40px] justify-center items-center";
  const ButtonClasses = {
    [ButtonStyle.Primary]: "bg-[#19a1fc] text-[#FFFFFF] hover:bg-[#007acb]" + DefaultStyle,
    [ButtonStyle.Secondary]:
      "text-[#19a1fc] border-[#19a1fc] dk:border-3 tb:border-2 border hover:bg-[#19a1fc] hover:text-[#FFFFFF]" +
      DefaultStyle,
    [ButtonStyle.Simple]: "text-[#19a1fc] hover:text-[#007acb] justify-center",
    [ButtonStyle.Filter]: "text-[#19a1fc] hover:text-[#007acb] justify-end font-normal",
    [ButtonStyle.InsideInput]:
      "text-[#19a1fc] hover:text-[#007acb] absolute right-[30px] top-3 tb:right-10 tb:top-4 dk:right-[60px] dk:top-[30px]",
    [ButtonStyle.Shadow]:
      "dk:shadow-[0px_8px_0px_0px_#007acb] tb:shadow-[0px_6px_0px_0px_#007acb] shadow-[0px_4px_0px_0px_#007acb] bg-[#19a1fc] text-[#FFFFFF] hover:shadow-[0px_0px_0px_0px_#007acb]" +
      DefaultStyle,
    [ButtonStyle.Warning]: "bg-[#ec0b43] text-[#FFFFFF] hover:bg-[#cb0334]" + DefaultStyle,
    [ButtonStyle.WarningSecondary]:
      "text-[#ec0b43] border-[#ec0b43] dk:border-3 tb:border-2 border hover:bg-[#ec0b43] hover:text-[#FFFFFF] " +
      DefaultStyle,
    [ButtonStyle.WarningSimple]: "text-[#ec0b43] hover:text-[#cb0334] justify-center",
    [ButtonStyle.Disabled]: "bg-[#868686] text-[#ffffff] " + DefaultStyle,
    [ButtonStyle.Complete]: "bg-[#c7f600] text-[#141e23]" + DefaultStyle,
    [ButtonStyle.CompleteBig]:
      "bg-[#c7f600] text-[#141e23] w-[auto] xs:text-base tb:text-xl dk:text-3xl h-10 lg:h-14 xl:h-18 text-sm lg:text-lg" +
      DefaultStyle,
    [ButtonStyle.ShadowBig]:
      "dk:shadow-[0px_8px_0px_0px_#007acb] tb:shadow-[0px_6px_0px_0px_#007acb] shadow-[0px_4px_0px_0px_#007acb] bg-[#19a1fc] text-[#FFFFFF] hover:shadow-[0px_0px_0px_0px_#007acb] w-[auto] xs:text-base tb:text-xl dk:text-3xl h-10 lg:h-14 xl:h-18 text-sm lg:text-lg" +
      DefaultStyle,
    [ButtonStyle.SecondaryBig]:
      "text-[#19a1fc] border-[#19a1fc] dk:border-3 tb:border-2 border hover:bg-[#19a1fc] hover:text-[#FFFFFF] w-[auto] border-[2px] text-sm uppercase tb:border-[2px] tb:text-xl dk:border-[3px] dk:text-3xl" +
      DefaultStyle,
    [ButtonStyle.Copy]:
      "bg-[#19a1fc] text-[#FFFFFF] hover:bg-[#007acb] flex min-w-[122px] items-center justify-center" +
      DefaultStyle,
  };

  const fontClasses = {
    [ButtonFontStyle.Unbounded]: "font-Unbounded",
    [ButtonFontStyle.GolosText]: "font-GolosText",
  };

  const buttonFont = fontClasses[buttonFontClass];
  const buttonStyle = ButtonClasses[style];
  return (
    <>
      {buttonType === ButtonType.Button ? (
        <button
          id={id}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => onClick?.(event)}
          className={classNames(
            "disabled::bg-dis flex rounded-lg text-base  tb:rounded-xl tb:text-xl dk:rounded-2xl dk:text-3xl ",
            buttonStyle,
            buttonFont,
            {
              "hover:text-white": style === ButtonStyle.Secondary,
            },
            className,
          )}
          disabled={isDisabled}
          type={type}
        >
          <div className={classNames("flex-flow-row flex", labelClassName)}>
            {label}
            {children}
          </div>
        </button>
      ) : (
        <NavLink
          className={classNames(
            "flex rounded-lg text-base tb:rounded-xl  tb:text-xl dk:rounded-2xl dk:text-3xl",
            buttonStyle,
            buttonFont,
            {
              "hover:text-white": style === ButtonStyle.Secondary,
            },
            className,
          )}
          to={navTo ?? "#"}
        >
          <div className={classNames("flex-flow-row flex items-center", labelClassName)}>
            {label}
            {children}
          </div>
        </NavLink>
      )}
    </>
  );
};

export default Button;

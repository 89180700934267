import { type BackendModels } from "./models";

export const FeedbackScoreType = {
  SMILES: "SMILES",
  STYLES: "STYLES",
  SKILLS: "SKILLS",
} as const;

export interface CommunityRatingObj {
  skills: number | null;
  styles: number | null;
  smiles: number | null;
}

export interface UserFeedbackScoreModel extends BackendModels.BaseModel {
  match_team: BackendModels.ID;
  type: keyof typeof FeedbackScoreType;
  value: number;
  giver: BackendModels.ID;
  receiver: BackendModels.ID;
}

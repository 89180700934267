import Resizer from "react-image-file-resizer";
import useAsync, { type AsyncStatuses } from "@double-bagel/hooks/useAsync";

type CompressFileResult = string | File | Blob | ProgressEvent<FileReader>;
const compressImage = async (file: Blob): Promise<CompressFileResult> => {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        Resizer.imageFileResizer(
          file,
          512,
          512,
          "JPEG",
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          "file",
          128,
          128,
        );
      } catch (e) {
        reject(e);
      }
    }, 0);
  });
};
const useImageCompressor = (): {
  compres: (image: Blob) => void;
  value?: CompressFileResult;
  status?: AsyncStatuses;
  error?: Error;
} => {
  const { execute, value, status, error } = useAsync(compressImage);
  return {
    compres: (image: Blob) => {
      void execute(image);
    },
    value,
    status,
    error,
  };
};

export default useImageCompressor;

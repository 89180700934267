import { type FC } from "react";
import { isDesktop, isMobile } from "react-device-detect";

import DefaultModal, { type DefaultModalProps } from "../default";

import Image from "@double-bagel/components/image";
import TextMSGIcon from "assets/Icons/social/messenger.svg";
import WhatsAppIcon from "assets/Icons/social/whatsapp.svg";
import InAppMessenger from "assets/Icons/social/inapp-messanger.svg";
import { useInAppMessenger } from "@double-bagel/MinChatMessenger.hooks";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type TournamentMatchModel } from "@double-bagel/endpoints/@types/match";
import { type UserProfile } from "@double-bagel/endpoints/@types/user";

type ConnectWithOpponentModalProps = Pick<DefaultModalProps, "onClose" | "showModal"> & {
  opponent: ClientModel<UserProfile>;
  match: ClientModel<TournamentMatchModel>;
  msg?: string;
  tournamentName?: string;
};

const ConenctWithOpponentModal: FC<ConnectWithOpponentModalProps> = ({
  showModal,
  onClose,
  msg,
  opponent,
  match,
  tournamentName,
}) => {
  const opponentPhone = opponent?.phone;
  const {
    getOrCreateMatchChat,
    messengerContext: { openMessenger },
  } = useInAppMessenger();
  const openInAppChat = async (): Promise<void> => {
    await getOrCreateMatchChat(match, tournamentName);
    openMessenger();
    onClose();
  };
  return (
    <DefaultModal title={"Connect via"} fullSize={false} showModal={showModal} onClose={onClose}>
      <div className="flex flex-col gap-[12px] p-[16px]">
        {isMobile && (
          <>
            <div
              onClick={openInAppChat as unknown as any}
              className="flex w-full cursor-pointer justify-between rounded-xl border py-[14px] pl-[20px] text-[16px] leading-[24px] text-fonts-primary"
            >
              In-app chat
              <Image
                src={InAppMessenger}
                alt="whatsapp"
                className="h-[20px] w-[20px]"
                classNameWrapper="pr-[20px]"
              />
            </div>
            <a
              id="connect_via_sms"
              rel="noopener noreferrer"
              target="_blank"
              href={`sms:${opponentPhone}?&body=${msg}`}
              className="flex w-full cursor-pointer justify-between rounded-xl border py-[14px] pl-[20px] text-[16px] leading-[24px] text-fonts-primary"
            >
              Text message
              <Image
                src={TextMSGIcon}
                alt="text-message"
                className="h-[20px] w-[20px]"
                classNameWrapper="pr-[20px]"
              />
            </a>
            <a
              id="connect_via_whatsapp"
              rel="noopener noreferrer"
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=${opponentPhone?.replace("+", "")}`}
              className="flex w-full cursor-pointer justify-between rounded-xl border py-[14px] pl-[20px] text-[16px] leading-[24px] text-fonts-primary"
            >
              WhatsApp
              <Image
                src={WhatsAppIcon}
                alt="whatsapp-message"
                classNameWrapper="pr-[20px]"
                className="h-[20px] w-[20px]"
              />
            </a>
          </>
        )}
        {isDesktop && (
          <>
            <div
              onClick={openInAppChat as unknown as any}
              className="flex w-full cursor-pointer justify-between rounded-xl border py-[14px] pl-[20px] text-[16px] leading-[24px] text-fonts-primary"
            >
              In-app chat
              <Image
                src={InAppMessenger}
                alt="whatsapp"
                className="h-[20px] w-[20px]"
                classNameWrapper="pr-[20px]"
              />
            </div>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=${opponentPhone?.replace("+", "")}`}
              className="flex w-full cursor-pointer justify-between  rounded-xl border py-[14px] pl-[20px] text-[16px] leading-[24px] text-fonts-primary"
            >
              WhatsApp
              <Image
                src={WhatsAppIcon}
                alt="text"
                className="h-[20px] w-[20px]"
                classNameWrapper="pr-[20px]"
              />
            </a>
          </>
        )}
      </div>
    </DefaultModal>
  );
};

export default ConenctWithOpponentModal;

import axios from "axios";
import { type BackendModels } from "./@types/models";
import asHook from "./adapter/hook";

export const useGetCurrentUserInvitePromo = asHook(async () => {
  const result = await axios.get<BackendModels.Promocode[]>(`promo/sendable`);
  return result?.data?.filter((item) => item.campaign === "invite");
}, "Promocode");

export const useGetActualPromoCodes = asHook(async () => {
  const result = await axios.get<BackendModels.Promocode[]>(`promo/receivable`);
  return result.data;
}, "");

export const useAcceptPromocode = asHook(async (promocode: string) => {
  const result = await axios.post<BackendModels.Promocode>(`promo/receivable/accept/`, {
    code: promocode,
  });
  return result.data;
}, "");

import classNames from "classnames";
import { useState, type FC, useEffect } from "react";
import ScoresInput, { type ScoreValue } from "./scores-input";
import PlayerAvatar from "../avatar/default";
import useFocus from "@double-bagel/hooks/useFocus";
import { zip } from "lodash";

export type GameScore = number | undefined;

type ScoresInputProps = {
  ownScores: ScoreValue;
  oppoScores: ScoreValue;
  onOwnScoresChanged: (value: ScoreValue) => void;
  onOppoScoresChanged: (value: ScoreValue) => void;
  className?: string;
  currentUserAvatar?: string | string[];
  opponentAvatar?: string | string[];
  opponentName?: string;
  disabled?: boolean;
};

const ScoresInputGroup: FC<ScoresInputProps> = ({
  ownScores,
  oppoScores,
  onOwnScoresChanged,
  onOppoScoresChanged,
  className,
  currentUserAvatar,
  opponentAvatar,
  opponentName,
  disabled,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isScoreCellActive, setIsScoreCellActive] = useState<[boolean, boolean, boolean]>([
    true,
    false,
    false,
  ]);

  const [ref1, setRef1Focus] = useFocus<HTMLInputElement>();
  const [ref2, setRef2Focus] = useFocus<HTMLInputElement>();
  const [ref3, setRef3Focus] = useFocus<HTMLInputElement>();
  const [ref4, setRef4Focus] = useFocus<HTMLInputElement>();
  const [ref5, setRef5Focus] = useFocus<HTMLInputElement>();
  const [ref6, setRef6Focus] = useFocus<HTMLInputElement>();

  const refsFocusOrder = [
    setRef1Focus,
    setRef4Focus,
    setRef2Focus,
    setRef5Focus,
    setRef3Focus,
    setRef6Focus,
  ];

  useEffect(() => {
    let oppoWins = 0;
    let ownWins = 0;
    for (let i = 0; i < 3; i++) {
      if (ownScores?.[i] === undefined || oppoScores?.[i] === undefined) {
        let availableCells: boolean[] = [];
        for (let j = 0; j < 3; j++) {
          availableCells = availableCells.concat(j <= i);
        }
        setIsScoreCellActive(availableCells as [boolean, boolean, boolean]);
        break;
      } else {
        if ((oppoScores[i] ?? 0) > (ownScores[i] ?? 0)) {
          oppoWins++;
        }
        if ((oppoScores[i] ?? 0) < (ownScores[i] ?? 0)) {
          ownWins++;
        }
      }
    }
    if ((oppoWins === 2 && ownWins === 0) || (ownWins === 2 && oppoWins === 0)) {
      setIsScoreCellActive((prev) => [true, true, false]);
      return;
    }
    const fieldValuesInFocusOrder = zip(ownScores, oppoScores).flatMap(([own, oppo]) => [
      own,
      oppo,
    ]);
    const indexOfFirstEmptyCell = fieldValuesInFocusOrder.findIndex((item) => item === undefined);
    refsFocusOrder[indexOfFirstEmptyCell]?.();
  }, [ownScores, oppoScores]);
  return (
    <>
      <div
        className={classNames("flex flex-col justify-center gap-2 tb:gap-4 dk:gap-6", className)}
      >
        <div className="td:gap-[22px] flex flex-row items-center gap-[10px] tb:gap-[16px]">
          {Array.isArray(currentUserAvatar) && currentUserAvatar.length === 1 && (
            <PlayerAvatar
              highlighted
              image={currentUserAvatar[0]}
              className="flex max-w-[30px] tb:max-w-[55px] dk:max-w-[64px]"
            />
          )}
          <div
            className={classNames(
              "grid max-w-[76px] grid-cols-4 grid-rows-1 tb:max-w-[128px] dk:max-w-[154px]",
            )}
          >
            {Array.isArray(currentUserAvatar) &&
              currentUserAvatar?.length > 1 &&
              currentUserAvatar.map((item, index) => (
                <div
                  className={classNames({
                    "z-[10] col-start-3 col-end-4 row-start-1": index === 0,
                    "z-[11] col-start-2 col-end-3 row-start-1": index === 1,
                    "z-[12] col-start-1 col-end-2 row-start-1": index === 2,
                  })}
                  key={index}
                >
                  <PlayerAvatar
                    image={item}
                    classNameWrapper={classNames("dk:w-16 dk:h-16 tb:w-14 tb:h-14 w-8 h-8")}
                    className="h-8 w-8 bg-clip-content p-[1px] tb:h-14 tb:w-14 tb:p-[2px] dk:h-16 dk:w-16 dk:p-1"
                  />
                </div>
              ))}
          </div>
          <span className="flex-grow text-[16px] font-semibold leading-[18px] text-fonts-accentColor tb:text-[24px] dk:text-[32px]">
            Me
          </span>
          <ScoresInput
            maxLength={2}
            value={ownScores}
            onChange={onOwnScoresChanged}
            isItemsActive={isScoreCellActive}
            disabled={disabled}
            ref1={ref1}
            ref2={ref2}
            ref3={ref3}
          />
        </div>
        <hr className="text-[#141E23]" />
        <div className="flex flex-row items-center gap-[10px]">
          {Array.isArray(opponentAvatar) && opponentAvatar.length === 1 && (
            <PlayerAvatar
              image={opponentAvatar[0]}
              className="flex max-w-[30px] tb:max-w-[55px] dk:max-w-[64px]"
            />
          )}
          <div
            className={classNames(
              "grid max-w-[76px] grid-cols-4 grid-rows-1 self-center tb:max-w-[128px] dk:max-w-[154px]",
            )}
          >
            {Array.isArray(opponentAvatar) &&
              opponentAvatar?.length > 1 &&
              opponentAvatar.map((item, index) => (
                <div
                  className={classNames({
                    "z-[10] col-start-3 col-end-4 row-start-1": index === 0,
                    "z-[11] col-start-2 col-end-3 row-start-1": index === 1,
                    "z-[12] col-start-1 col-end-2 row-start-1": index === 2,
                  })}
                  key={index}
                >
                  <PlayerAvatar
                    image={item}
                    classNameWrapper={classNames("dk:w-16 dk:h-16 tb:w-14 tb:h-14 w-8 h-8")}
                    className="h-8 w-8 bg-clip-content p-[1px] tb:h-14 tb:w-14 tb:p-[2px] dk:h-16 dk:w-16 dk:p-1"
                  />
                </div>
              ))}
          </div>
          <span className="inline grow text-base leading-6 tb:text-2xl dk:text-3xl">
            {opponentName ?? "Opponent"}
          </span>
          <ScoresInput
            maxLength={2}
            value={oppoScores}
            onChange={onOppoScoresChanged}
            isItemsActive={isScoreCellActive}
            disabled={disabled}
            ref1={ref4}
            ref2={ref5}
            ref3={ref6}
          />
        </div>
      </div>
    </>
  );
};

export default ScoresInputGroup;

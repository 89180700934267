import { type FC } from "react";
import Logo from "@double-bagel/assets/logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Image from "@double-bagel/components/image";
import NavigationBack from "../buttons/nav-back";

interface UnauthorizedHeaderProps {
  title?: string;
  backButton: boolean;
}

const UnauthorizedHeader: FC<UnauthorizedHeaderProps> = ({ title, backButton }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isShowNavBack = window.isMobileApp && location.key !== "default";
  return (
    <div className="grid min-h-[72px] grid-cols-3 bg-[#FFFFFF] px-5 py-4 shadow-[2px_0px_5px_0px_#00000042] tb:px-10 tb:py-5 dk:px-10 dk:py-6">
      {backButton && isShowNavBack ? (
        <NavigationBack
          className="h-[14px] w-[12px] tb:h-[17.5px] tb:w-[15px] dk:h-[21px] dk:w-[18px]"
          onClick={() => {
            navigate(-1);
          }}
        />
      ) : (
        <Image
          alt="logo"
          src={Logo}
          className="h-[40px] w-[80px] cursor-pointer tb:h-[65px] tb:w-[135px] dk:h-[75px] dk:w-[150px]"
          onClick={() => {
            navigate("/");
          }}
        />
      )}
      <div className="w-max self-center justify-self-center text-base tb:text-xl dk:text-3xl">
        {title}
      </div>
    </div>
  );
};

export default UnauthorizedHeader;

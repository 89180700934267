import { type FC } from "react";
import ProgressBarHeader from "./ProgressBarHeader";
import classNames from "classnames";
import { useScrollToTop } from "@double-bagel/hooks/useScrollTop";
import PageLayoutFading from "../animation/navigation-fading";

interface ProgressBarLayoutProps {
  children?: React.ReactNode;
  isLoading?: boolean;
  progressbarFill: number;
  fullSize?: boolean;
  maxValue: number;
  onBackButtonClick?: () => void;
}
const classname = "px-4 max-w-[inherit] py-4 tb:py-10 dk:py-16 dk:px-[162px] tb:px-10";

const ProgressBarLayout: FC<ProgressBarLayoutProps> = ({
  children,
  isLoading = false,
  progressbarFill,
  fullSize = false,
  maxValue,
  onBackButtonClick,
}) => {
  useScrollToTop();
  return (
    <PageLayoutFading isLoading={isLoading}>
      <ProgressBarHeader
        progressbarFill={progressbarFill}
        maxValue={maxValue}
        onBackClick={onBackButtonClick}
      />
      <div className={classNames({ [classname]: !fullSize })}>{children}</div>
    </PageLayoutFading>
  );
};

export default ProgressBarLayout;

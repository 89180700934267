import { type FC, type ChangeEventHandler, useState, useEffect } from "react";

import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";
import RadioButton from "@double-bagel/components/input/radio";
import TextInput from "@double-bagel/components/input/text";

import { ResultFailReasonMapping } from "@double-bagel/endpoints/@types/tournament";
import { useSendMatchReport } from "@double-bagel/endpoints/endpoint.match";

import useFocus from "@double-bagel/hooks/useFocus";

type MatchReportProps = {
  tournamentId: number;
  tournamentMatchTeamId: number;
  onReportSuccess: () => void;
};

const ReportProblemModal: FC<MatchReportProps> = ({
  tournamentId,
  tournamentMatchTeamId,
  onReportSuccess,
}) => {
  const [reportValue, setReportValue] = useState<keyof typeof ResultFailReasonMapping>();
  const [reportValueOther, setReportValueOther] = useState<string>("");
  const {
    execute: sendReport,
    status: sendReportStatus,
    isFetching: isSendReportFetching,
  } = useSendMatchReport();
  const [textInputRef, focusOnInput] = useFocus<HTMLInputElement>();
  const onRadioReportChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setReportValue(event.target.value as keyof typeof ResultFailReasonMapping);
  };

  useEffect(() => {
    if (reportValue === ResultFailReasonMapping.OTHER) {
      focusOnInput();
    }
  }, [reportValue]);

  useEffect(() => {
    if (sendReportStatus === "success") {
      onReportSuccess();
    }
  }, [sendReportStatus]);

  return (
    <div className="px-4 py-5">
      <div className=" text-4 leading-4.75 font-semibold text-[#000]">Tell us what went wrong:</div>
      <form className="flex flex-col gap-5 pt-5">
        <div>
          <RadioButton
            value={ResultFailReasonMapping.NEVER_ANSWERED}
            checked={reportValue === ResultFailReasonMapping.NEVER_ANSWERED}
            onChange={onRadioReportChange}
          >
            My opponent never answered
          </RadioButton>
        </div>
        <div>
          <RadioButton
            value={ResultFailReasonMapping.NEVER_SHOWED_UP}
            checked={reportValue === ResultFailReasonMapping.NEVER_SHOWED_UP}
            onChange={onRadioReportChange}
          >
            My opponent didn’t show up
          </RadioButton>
        </div>
        <div>
          <RadioButton
            value={ResultFailReasonMapping.OPPONENT_RETIRED}
            checked={reportValue === ResultFailReasonMapping.OPPONENT_RETIRED}
            onChange={onRadioReportChange}
          >
            My opponent retired
          </RadioButton>
        </div>
        <div>
          <RadioButton
            value={ResultFailReasonMapping.OWN_RETIRED}
            checked={reportValue === ResultFailReasonMapping.OWN_RETIRED}
            onChange={onRadioReportChange}
          >
            I’m retiring from the match
          </RadioButton>
        </div>
        <div>
          <RadioButton
            value={ResultFailReasonMapping.DROP_TOURNAMENT}
            checked={reportValue === ResultFailReasonMapping.DROP_TOURNAMENT}
            onChange={onRadioReportChange}
          >
            I’m withdrawing from the tournament
          </RadioButton>
        </div>
        <div>
          <RadioButton
            value={ResultFailReasonMapping.OTHER}
            checked={reportValue === ResultFailReasonMapping.OTHER}
            onChange={onRadioReportChange}
          >
            Other
          </RadioButton>
        </div>
        <div>
          <TextInput
            placeholder="Describe your issue"
            ref={textInputRef}
            autoFocus={reportValue === ResultFailReasonMapping.OTHER}
            className="font-3.5 w-full  leading-5"
            disabled={reportValue !== ResultFailReasonMapping.OTHER}
            onChange={(event) => {
              setReportValueOther(event.target.value);
            }}
            value={reportValueOther}
          />
        </div>
        <div className="mb-7 mt-16 flex flex-col">
          <Button
            style={isSendReportFetching ? ButtonStyle.Disabled : ButtonStyle.Primary}
            isDisabled={isSendReportFetching}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async (e) => {
              e.preventDefault();
              if (isSendReportFetching) {
                return;
              }
              await sendReport(tournamentId, {
                failReason: reportValue,
                failReasonDescription: reportValueOther,
                tournamentMatchTeamId,
              });
            }}
          >
            Send report
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ReportProblemModal;

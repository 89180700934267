import memoize from "util/memoize";

const isEmojiSupported = memoize((): boolean => {
  if (typeof window === "undefined") {
    return false;
  }
  const node = window.document.createElement("canvas");
  const ctx = node.getContext("2d");
  if (!ctx) {
    return false;
  }
  const fontSize = 32;

  ctx.fillStyle = "#f00";
  ctx.textBaseline = "top";
  ctx.font = `${fontSize}px Arial`;
  ctx.fillText("🔴", 0, 0);

  const offsetToProbe = fontSize / 2;
  const pixelProbe = ctx.getImageData(offsetToProbe, offsetToProbe, 2, 2);
  const result = new Uint32Array(pixelProbe.data.buffer).every((pixCol) => pixCol !== 0);
  return result;
});

export default isEmojiSupported;

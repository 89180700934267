import { type FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import classNames from "classnames";
interface ImageProps {
  src: string;
  alt: string;
  onClick?: () => void;
  className?: string;
  classNameWrapper?: string;
  width?: number | string;
  height?: number | string;
  afterLoad?: () => void;
}

const Image: FC<ImageProps> = ({
  src,
  alt,
  className,
  onClick,
  classNameWrapper: wrapperClassName,
  width,
  height,
  afterLoad,
}) => {
  return (
    <LazyLoadImage
      useIntersectionObserver
      wrapperClassName={classNames(wrapperClassName, "block")}
      className={classNames(className)}
      onClick={onClick}
      alt={alt}
      src={src}
      effect="opacity"
      height={height}
      width={width}
      onLoad={afterLoad}
    />
  );
};

export default Image;

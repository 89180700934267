import { useState, type FC } from "react";
import { CentralizedModal } from "../centralized";
import Image from "@double-bagel/components/image";
import ImageSource from "@double-bagel/assets/Images/bagel-cartoon-rocket 3.png";
import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";
import AllRulesModal from "./all-rules-modal";

type NotificationModalProps = {
  showModal: boolean;
  onClose: () => void;
  message?: string;
};
const ValidationScoreModal: FC<NotificationModalProps> = ({ showModal, onClose, message }) => {
  const [showAllRulesModal, setShowAllRulesModal] = useState(false);
  return (
    <>
      <CentralizedModal showModal={showModal}>
        <Image
          src={ImageSource}
          className="h-[106px] tb:h-[130px] dk:h-[180px] "
          alt="bagel-cartoon"
        />
        <div className="text-base font-bold leading-6 tb:text-xl tb:leading-8 dk:text-3xl dk:leading-9">
          Ooops! Wrong score
        </div>
        <div className="max-w-[92.5%] pt-0 text-center font-GolosText text-sm tb:pt-1 tb:text-lg dk:pt-1 dk:text-2xl">
          {message}
        </div>
        <div className="mt-3 flex w-full flex-col gap-3 tb:mt-4 tb:gap-4  dk:mt-6  dk:gap-6">
          <Button
            style={ButtonStyle.Primary}
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
          <Button
            style={ButtonStyle.Simple}
            onClick={() => {
              setShowAllRulesModal(true);
            }}
          >
            Go to the rules page
          </Button>
        </div>
      </CentralizedModal>
      <AllRulesModal
        showModal={showAllRulesModal}
        onClose={() => {
          setShowAllRulesModal(false);
        }}
      />
    </>
  );
};

export default ValidationScoreModal;

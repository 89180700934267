import { AuthLocalStorage } from "@double-bagel/auth-local-storage";
import axios, { type AxiosError } from "axios";

const session = new AuthLocalStorage();
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT ?? "http://127.0.0.1:8000/api/";
axios.defaults.baseURL = API_ENDPOINT;
axios.interceptors.request.use((config) => {
  const token = session.getToken();
  if (token) {
    config.headers.Authorization = `JWT ${token}`;
    // config.headers["Content-Type"] = "application/json";
  }
  return config;
});
axios.interceptors.response.use(
  (config) => config,
  async (error: AxiosError) => {
    if (error?.response?.status === 401) {
      session.clear();
    }
    return await Promise.reject(error);
  },
);

import { type FC } from "react";
import UnauthorizedHeader from "@double-bagel/components/layouts/UnauthorizedHeader";
import { useScrollToTop } from "@double-bagel/hooks/useScrollTop";
import PageLayoutFading from "../animation/navigation-fading";

interface UnauthorizedLayoutProps {
  children?: React.ReactNode;
  title?: string;
  isLoading?: boolean;
  backButton?: boolean;
}

const UnauthorizedLayout: FC<UnauthorizedLayoutProps> = ({
  children,
  title,
  isLoading = false,
  backButton = false,
}) => {
  useScrollToTop();
  return (
    <PageLayoutFading isLoading={isLoading}>
      {title && <UnauthorizedHeader title={title} backButton={backButton} />}
      <div className="py-4 xs:px-[26px] md:px-[48px] xl:px-[96px]">{children}</div>
    </PageLayoutFading>
  );
};

export default UnauthorizedLayout;

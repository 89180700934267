import { type FC, type CSSProperties } from "react";
import Image from "../image";
import LockImage from "assets/Icons/mono/padlock.svg";
import classNames from "classnames";
import { type APIResponse } from "@double-bagel/endpoints/@types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { type TeamModel } from "@double-bagel/endpoints/@types/team";
import { type PlayerModel } from "@double-bagel/endpoints/@types/player";

interface TournamentParticipantsProps {
  players: APIResponse.NestedCamelize<PlayerModel>[];
  teams: APIResponse.NestedCamelize<TeamModel>[];
  totalSlots: number;
}

interface PlayerColelctionSlotProps {
  image?: string;
  className?: string;
  style?: CSSProperties | undefined;
}

const TournamentParticipantAvatar: FC<PlayerColelctionSlotProps> = ({
  image,
  className,
  style,
}) => {
  if (image) {
    return (
      <>
        <LazyLoadImage
          style={style}
          src={image}
          alt={"Player Slot"}
          wrapperClassName={className}
          className={classNames(
            "w-8, aspect-[1] h-8 rounded-full bg-[#D9D9D9] object-cover  object-center",
          )}
        />
      </>
    );
  } else {
    return <div className={classNames("h-8 rounded-full bg-[#D9D9D9]", className)} />;
  }
};

const TournamentCardChallengePlayers: FC<TournamentParticipantsProps> = ({
  teams,
  players,
  totalSlots,
}) => {
  return (
    <>
      <div className="flex flex-col gap-[30px]">
        <div className="grid h-5 grid-cols-7 grid-rows-1 gap-0">
          {teams
            .concat(new Array(Math.max(0, totalSlots)).fill({}))
            .slice(0, 7)
            .map((team, index) => {
              return (
                <div key={index} className="grid h-full w-full grid-cols-1 grid-rows-3 flex-col">
                  {team?.members?.length === 1 && (
                    <>
                      <TournamentParticipantAvatar image="" />
                      <TournamentParticipantAvatar image="" />
                      <TournamentParticipantAvatar
                        key={team?.members?.[0]?.id}
                        image={team?.members?.[0]?.avatar?.image}
                      />
                    </>
                  )}
                  {team?.members?.length === 2 && (
                    <>
                      <TournamentParticipantAvatar
                        key={team?.members?.[0]?.id}
                        image={team?.members?.[0]?.avatar?.image}
                      />
                      <TournamentParticipantAvatar image="" className="opacity-0" />
                      <TournamentParticipantAvatar
                        key={team?.members?.[1]?.id}
                        image={team?.members?.[1]?.avatar?.image}
                      />
                    </>
                  )}
                  {team?.members?.length === 3 && (
                    <>
                      <TournamentParticipantAvatar
                        key={team?.members?.[0]?.id}
                        image={team?.members?.[0]?.avatar?.image}
                      />
                      <TournamentParticipantAvatar
                        key={team?.members?.[1]?.id}
                        image={team?.members?.[1]?.avatar?.image}
                      />
                      <TournamentParticipantAvatar
                        key={team?.members?.[2]?.id}
                        image={team?.members?.[2]?.avatar?.image}
                      />
                    </>
                  )}
                </div>
              );
            })}
        </div>
        <div className="flex items-center gap-1.5 text-xs text-fonts-optional tb:gap-2 sm:text-sm dk:gap-2.5">
          {players.length === totalSlots && (
            <Image
              src={LockImage}
              className="h-3 w-2 tb:h-[14px] tb:w-3 dk:h-5 dk:w-4"
              alt="LockImage"
            />
          )}
          <span className="text-xs leading-4 tb:pl-0.5 tb:pt-1.5 tb:text-base tb:leading-6 dk:pl-[3px] dk:pt-2 dk:text-xl dk:leading-8">
            {players.length}/{totalSlots} joined
          </span>
        </div>
      </div>
      {teams.length > 7 && (
        <div className="mb-4 pl-2 text-xs font-semibold tb:mb-9 tb:text-sm dk:mb-11 dk:text-base">
          +{teams.length - 7}
        </div>
      )}
    </>
  );
};

export default TournamentCardChallengePlayers;

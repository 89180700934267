// import useAsync from "@double-bagel/hooks/useAsync";
import { Button } from "@double-bagel/components/buttons";
import { ButtonStyle } from "@double-bagel/components/buttons/default-button";
import classNames from "classnames";
import { useRef, useState, type FC } from "react";
import {
  CircleStencil,
  Cropper,
  type DefaultSize,
  type ExtendedSettings,
  ImageRestriction,
  type CropperRef,
  // StencilWrapper,
  // type StencilGridProps,
} from "react-advanced-cropper";
import ReactModal from "react-modal";

type AvatarPickerCropModalProps = {
  showModal: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  imgSrc: any;
  onCrop: (img: string, fileImage: File) => void;
  fileName: string;
};
const CropperContentElement = (
  props: React.ComponentPropsWithRef<"div">,
  children: React.ReactNode,
): React.ReactElement => {
  const { style, className, ...restProps } = props;
  return (
    <div className={classNames("h-[100%] w-[100%]", className)} {...restProps}>
      <div className="h-[10vh] text-center text-lg text-[#F3F3F3]">
        <h3 className="pt-4">Move and Scale</h3>
      </div>
      {children}
    </div>
  );
};

const AvatarPickerCropModal: FC<AvatarPickerCropModalProps> = ({
  showModal,
  children,
  imgSrc,
  onClose,
  onCrop,
  fileName,
}) => {
  const ASPECT = 4 / 4;
  const defaultSize: DefaultSize<ExtendedSettings<any>> = ({ imageSize, visibleArea }) => {
    return {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      width: imageSize.width,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      height: (visibleArea || imageSize).height,
    };
  };
  const [fileImage, setFileImage] = useState<File | undefined>();
  const onChange = (cropper: CropperRef): void => {
    const cropperImage = cropper.getImage();
    if (!cropperImage?.arrayBuffer) return;
    const blobImage = new Blob([new Uint8Array(cropperImage.arrayBuffer).buffer]);
    const file = new File([blobImage], fileName, { type: "image/jpeg" });
    setFileImage(file);
  };
  const cropperRef = useRef<CropperRef>(null);
  return (
    <ReactModal
      isOpen={showModal}
      bodyOpenClassName={"fixed w-[100vw] text-modal"}
      overlayClassName={classNames("fixed bg-[black] w-full z-50 h-[100vh] top-0")}
      contentElement={CropperContentElement}
    >
      <div className="flex h-[90vh] flex-col bg-[black]">
        <Cropper
          ref={cropperRef}
          defaultSize={defaultSize}
          stencilComponent={CircleStencil}
          imageRestriction={ImageRestriction.fitArea}
          src={imgSrc}
          onUpdate={onChange}
          className={"max-h-[80vh] min-h-[75vh]"}
          aspectRatio={(state, settings) => ASPECT}
        />
        {children}
        <div className="flex h-[10vh] w-full items-center justify-between px-[10%]">
          <Button
            className="text-lg text-[#F3F3F3]"
            onClick={() => {
              onClose();
            }}
            style={ButtonStyle.Simple}
          >
            Cancel
          </Button>
          <Button
            className="cursor-pointer text-lg text-[#F3F3F3]"
            style={ButtonStyle.Simple}
            onClick={() => {
              if (fileImage) {
                const croppedImage = cropperRef?.current?.getCanvas()?.toDataURL();
                if (!croppedImage) return;
                onCrop(croppedImage, fileImage);
                onClose();
              }
            }}
          >
            Choose
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};

export default AvatarPickerCropModal;

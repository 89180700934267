import Bell from "@double-bagel/assets/Icons/Bell.svg";
import { useEffect, useState, type FC } from "react";
import Image from "@double-bagel/components/image";
import { Link } from "react-router-dom";
import useMinchatUnreadMessages from "@double-bagel/hooks/useMinchatUnreadMessages";
import { useUser } from "@minchat/react";
import classNames from "classnames";

const NotificationBellButton: FC = () => {
  const user = useUser();
  const { value: unreadMessagesCount } = useMinchatUnreadMessages(user?.id);
  const [hasUnreaded, setHasUnreaded] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    setHasUnreaded(unreadMessagesCount > 0);
  }, [unreadMessagesCount]);
  return (
    <Link
      to={"/chat"}
      className="relative ml-2 flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-background tb:ml-2 tb:h-[63px] tb:w-[63px] dk:ml-[20px] dk:h-[76px] dk:w-[75px]"
    >
      <Image src={Bell} alt={"chat-button"} className="h-6 w-6 tb:h-8 tb:w-8 dk:h-10 dk:w-10" />
      <div
        className={classNames(
          "absolute bottom-[10px] right-2 z-10 h-[10px] w-[10px] animate-bounce rounded-full border-[2px] border-[#fff] bg-[#EC0B43] transition-opacity tb:bottom-[15px] tb:right-3 tb:h-[15px] tb:w-[15px] dk:bottom-[20px] dk:right-4",
          { "opacity-100": hasUnreaded, "opacity-0": !hasUnreaded },
        )}
      />
    </Link>
  );
};

export default NotificationBellButton;

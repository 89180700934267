import { type FC } from "react";
import classNames from "classnames";
import Image from "components/image";

interface PlayerColelctionSlotProps {
  image?: string;
  highlighted?: boolean;
  style?: React.CSSProperties;
  classNameWrapper?: string;
  className?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}

const PlayerAvatar: FC<PlayerColelctionSlotProps> = ({
  image,
  style,
  className,
  classNameWrapper,
  highlighted = false,
  onClick,
}) => {
  const cls = classNames("rounded-full", {
    "outline outline-[2px] outline-border-greenAcid": highlighted,
    "outline outline-0": !highlighted,
  });
  return (
    <div
      onClick={onClick}
      className={classNames("relative flex items-center bg-white", cls, classNameWrapper)}
      style={style}
    >
      {image && (
        <Image
          className={classNames(
            "relative z-10 aspect-[1] rounded-full object-cover object-center",
            className,
          )}
          src={image ?? ""}
          alt={"Player Slot"}
          classNameWrapper={classNameWrapper}
        />
      )}
      {!image && (
        <div
          className={classNames(
            "bg-skeleton-animated absolute z-0 animate-animateSkeletonBackdrop rounded-full opacity-80",
            className,
          )}
        />
      )}
    </div>
  );
};

export default PlayerAvatar;

import { type FC } from "react";
import Emoji from "a11y-react-emoji";
import classNames from "classnames";

const ResponsiveEmoji: FC<{ symbol: string; className?: string }> = ({ symbol, className }) => (
  <Emoji
    symbol={symbol}
    className={classNames("pb-1 text-base tb:pb-0.5 tb:text-2xl dk:text-3xl", className)}
  />
);

export default ResponsiveEmoji;

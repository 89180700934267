import { type FC } from "react";
import AuthorizedHeader from "@double-bagel/components/layouts/AuthorizedHeader";
import classNames from "classnames";
import { useScrollToTop } from "@double-bagel/hooks/useScrollTop";
import PageLayoutFading from "../animation/navigation-fading";
interface AuthorizedLayoutProps {
  children?: React.ReactNode;
  title: string;
  image: string;
  page: string;
  highlighed?: boolean;
  isLoading?: boolean;
  cls?: string;
  fullSize?: boolean;
  isShowNotificationsButton?: boolean;
}
const classname =
  "px-4 max-w-[inherit] py-4 tb:py-10 dk:py-16 dk:px-[162px] tb:px-10 bg-[#FAFAFA] ";

const AuthorizedLayout: FC<AuthorizedLayoutProps> = ({
  children,
  title,
  image,
  page,
  highlighed = true,
  isLoading = false,
  cls,
  fullSize = false,
  isShowNotificationsButton = false,
}) => {
  useScrollToTop();
  return (
    <PageLayoutFading isLoading={isLoading}>
      <AuthorizedHeader
        image={image}
        page={page}
        title={title}
        highlighed={highlighed}
        isShowNotificationsButton={isShowNotificationsButton}
      />
      <div className={classNames(cls, { [classname]: !fullSize })}>{children}</div>
    </PageLayoutFading>
  );
};

export default AuthorizedLayout;

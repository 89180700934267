import { isNil, omitBy } from "lodash";
import { isArray, isObject } from "@double-bagel/helpers";
import { type APIResponse } from "@double-bagel/endpoints/@types";

const formatParts = (string: string): string[] => {
  const parts: string[] = [];
  string.replace(/([_\-A-Z]|^[^_\-A-Z])([^_\-A-Z]*)/g, (_, $1: string, $2: string) => {
    const part = /[_-]/.exec($1) != null ? $2 : $1 + $2;
    parts.push(part.toLowerCase());
    return "";
  });
  return parts;
};

export const toCamelCase = <T extends string>(string: T): APIResponse.CamelizeString<T> => {
  let output = "";
  formatParts(string).forEach((part, index) => {
    output += index !== 0 ? part.charAt(0).toUpperCase() + part.substring(1) : part;
  });
  return output as APIResponse.CamelizeString<T>;
};
export function camelizeArrayOfObjects<D extends any[] = any[]>(
  data: D,
): APIResponse.CamelizeStruct<D>[] {
  if (Array.isArray(data)) {
    const result = data.map((item) =>
      isObject(item) ? camelizeObject(item) : isArray(item) ? camelizeArrayOfObjects(item) : item,
    );
    return result;
  }
  return [];
}

export function camelizeObject<
  D extends { [P in keyof D]: D[P] },
  R = APIResponse.CamelizeStruct<D>,
>(data: D): R {
  if (isObject(data)) {
    const result = Object.entries<D>(data).reduce<
      APIResponse.CamelizeStruct<D> | Record<string, unknown>
    >((acc, [key, value]) => {
      if (isObject(value)) {
        return {
          ...acc,
          [toCamelCase(key)]: camelizeObject<R>(value),
        };
      }
      if (isArray(value)) {
        return {
          ...acc,
          [toCamelCase(key)]: camelizeArrayOfObjects(value),
        };
      }
      return { ...acc, [toCamelCase(key)]: value };
    }, {});
    return result as R;
  }
  return {} as unknown as R;
}

export const queryParamsFromDict = (params: {
  [name: string]: Array<string | number> | string | number | undefined;
}): string => {
  const predicate = (value: unknown): boolean =>
    isNil(value) || (Array.isArray(value) && value.length === 0) || value === "";
  const clean = omitBy(params, predicate) as Record<string, string>;
  return new URLSearchParams(clean).toString();
};

import { type APIResponse } from "../@types";
import { camelizeObject } from "./util";

type ModelMeta = {
  _modelName?: string;
};
export type ClientModel<T> = {
  [K in keyof APIResponse.CamelizeStruct<T>]: APIResponse.CamelizeStruct<T>[K];
} & ModelMeta;

export function toModel<P extends object>(modelName: string, fields: P): ClientModel<P> {
  return {
    ...camelizeObject(fields),
    _modelName: modelName,
  };
}

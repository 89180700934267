import { type FC } from "react";
import { AssessmentPoints } from "../raiting-card";

interface RaitingScaleProps {
  label: { left: string; right: string };
  assessmentField: AssessmentPoints;
}

const RatingScale: FC<RaitingScaleProps> = ({ label, assessmentField }) => {
  const EmojiSet = {
    [AssessmentPoints.SKILLS]: ["👎", "👍"],
    [AssessmentPoints.STYLES]: ["🤓", "😎"],
    [AssessmentPoints.SMILES]: ["😒", "☺️"],
  };
  const requiredEmoji = EmojiSet[assessmentField];
  return (
    <div className="mt-2 grid grid-cols-2 tb:mt-4 dk:mt-4">
      <div className="bottom-1 left-5 z-10 flex gap-1 self-center justify-self-start tb:bottom-4 tb:left-5 tb:gap-0.5 dk:bottom-6 dk:left-10 dk:gap-1.5">
        <div className="mt-[3px] text-xs tb:mt-0.5 tb:text-xl dk:mt-1 dk:text-2xl">
          {requiredEmoji[0]}
        </div>
        <div className="text-sm leading-5 text-fonts-optional tb:text-base tb:leading-7 dk:text-2xl dk:leading-9">
          {label.left}
        </div>
      </div>
      <div className="bottom-1 right-5 z-10 flex items-center gap-1 self-center justify-self-end tb:bottom-4 tb:right-5 tb:gap-0.5 dk:bottom-6 dk:right-10 dk:gap-1.5">
        <div className="text-sm leading-5 text-fonts-optional tb:text-base tb:leading-7 dk:text-2xl dk:leading-9">
          {label.right}
        </div>
        <div className=" self-center text-xs tb:mt-[-3px] tb:text-xl dk:mt-0 dk:text-2xl">
          {requiredEmoji[1]}
        </div>
      </div>
    </div>
  );
};

export default RatingScale;

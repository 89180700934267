import { initializeApp } from "firebase/app";
import { getToken, getMessaging } from "firebase/messaging";
import { getAppConfigValue } from "./helpers";
// Scripts for firebase and firebase messaging
if (typeof importScripts === "function") {
  // eslint-disable-next-line no-undef
  importScripts("https://www.gstatic.com/firebasejs/9.0.0/firebase-app-compat.js");
  // eslint-disable-next-line no-undef
  importScripts("https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging-compat.js");
}
// Initialize the Firebase app in the service worker by passing the generated config
const firebaseConfig = {
  apiKey: "AIzaSyAWiixG7PxQhE5hcEJLvQACc9S0LxiIAT0",
  authDomain: "double-bagel-2.firebaseapp.com",
  projectId: "double-bagel-2",
  storageBucket: "double-bagel-2.appspot.com",
  messagingSenderId: "910557452817",
  appId: "1:910557452817:web:6d7c244b661b898aff2fb0",
  measurementId: "G-XYMVS90NB9",
};
let messaging;
try {
  const firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
} catch (e) {
  console.log(e);
}
export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator && typeof navigator.serviceWorker !== "undefined") {
    return navigator?.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window?.navigator?.serviceWorker.register("/firebase-messaging-sw.js", {
          scope: "/firebase-push-notification-scope",
        });
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};
// getFirebaseToken function generates the FCM token
export const getFirebaseToken = async () => {
  const messagingResolve = messaging;
  if (messagingResolve) {
    const serviceWorkerRegistration = await getOrRegisterServiceWorker();
    return await getToken(messagingResolve, {
      vapidKey: getAppConfigValue("FIREBASE_VAPID_KEY"),
      serviceWorkerRegistration,
    });
  }
};

import { useCallback, useContext, useEffect, type FC } from "react";
import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";
import { type Value } from "baseui/select";
import { NavLink } from "react-router-dom";
import { useGetDefaultAvatars, useUpdateUserProfile } from "@double-bagel/endpoints/endpoints.user";
import AvatarPicker from "@double-bagel/components/widgets/avatar-picker/avatar-picker-new";
import { Controller, useForm } from "react-hook-form";
import UnauthorizedLayout from "@double-bagel/components/layouts/UnauthorizedLayout";
import { type TournamentModel } from "@double-bagel/endpoints/@types/tournament";
import { ProfileContext } from "@double-bagel/context/profile";
import useIntercomHidden from "@double-bagel/hooks/useIntercomHidden";

type TFormValues = {
  city: Value;
  level?: TournamentModel["level"];
  gender?: "WOMAN" | "MAN";
  avatar?: string;
};

const SignUpAvatar: FC = () => {
  const { value: avatars, execute: fetchAvatars } = useGetDefaultAvatars();
  useEffect(() => {
    void fetchAvatars?.();
  }, []);
  const { execute: updateProfile, value: updatedProfileValue } = useUpdateUserProfile();
  const { setProfile } = useContext(ProfileContext);
  const {
    control: formControl,
    handleSubmit,
    watch,
    setValue,
  } = useForm<TFormValues>({
    shouldFocusError: true,
  });
  const updateAvatar = handleSubmit(async (data): Promise<void> => {
    const { avatar } = data;
    void updateProfile({
      avatar: Number(avatar),
    });
    // location.reload();
  });
  useEffect(() => {
    if (updatedProfileValue) {
      setProfile(updatedProfileValue);
    }
  }, [updatedProfileValue]);
  const randomizeOnSkip = useCallback(() => {
    if (avatars) {
      return avatars[Math.floor(Math.random() * avatars.length)]?.id;
    }
    return null;
  }, [avatars]);
  const avatar = watch("avatar");
  useIntercomHidden(true);
  return (
    <UnauthorizedLayout title="Profile picture" backButton={true}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          void (async () => {
            await updateAvatar(event);
          })();
        }}
      >
        <Controller
          name="avatar"
          control={formControl}
          render={({ field }) => (
            <AvatarPicker
              className="tb:pt-[64px] dk:pt-[106px]"
              onAvatarChange={(id) => {
                field.onChange(id);
              }}
              onAvatarCancel={() => {
                setValue("avatar", undefined);
              }}
            />
          )}
        />
        <div className="mt-[40px] flex flex-col gap-4 tb:mt-[60px] dk:mt-[80px]">
          <Button
            style={!avatar ? ButtonStyle.Disabled : ButtonStyle.Primary}
            id="auth_stage_3_submit"
            type="submit"
            isDisabled={!avatar}
          >
            Continue
          </Button>
          <Button
            isDisabled={!!avatar}
            labelClassName={"text-fonts-gray"}
            style={ButtonStyle.Simple}
            id="auth_stage_3_submit"
            type="button"
            onClick={() => {
              const randomAvatarId = randomizeOnSkip();
              if (randomAvatarId) {
                void updateProfile({
                  avatar: Number(randomAvatarId),
                });
              }
            }}
          >
            Skip
          </Button>
        </div>
      </form>
      <div className="px-[30px] pt-[20px] text-center text-xs leading-4 text-fonts-optional tb:text-base tb:leading-6 dk:pt-[32px] dk:text-xl dk:leading-8">
        By proceeding I agree with <br />
        <NavLink className="underline " to="https://doublebagel.co/terms-of-service">
          Terms and Conditions
        </NavLink>
        <span className="mx-2">and</span>
        <NavLink className="underline " to="https://doublebagel.co/privacy_policy">
          Privacy Policy
        </NavLink>
      </div>
    </UnauthorizedLayout>
  );
};

export default SignUpAvatar;

import { type TournamentDetailContextType } from "@double-bagel/context/tournament-detail";
import { useState } from "react";

type useTournamentsCacheInitResult = {
  leaderboard: TournamentDetailContextType["leaderboard"];
  tournamentMatches: TournamentDetailContextType["tournamentMatches"];
  currentUserMatches: TournamentDetailContextType["currentUserMatches"];
  tournaments: TournamentDetailContextType["tournaments"];
  setLeaderboard: TournamentDetailContextType["setLeaderboard"];
  setTournamentMatches: TournamentDetailContextType["setTournamentMatches"];
  setCurrentUserMatches: TournamentDetailContextType["setCurrentUserMatches"];
  setTournaments: TournamentDetailContextType["setTournaments"];
};
export const useTournamentsCacheInit = (): useTournamentsCacheInitResult => {
  const [leaderboard, setLeaderboard] = useState<TournamentDetailContextType["leaderboard"] | null>(
    null,
  );
  const [tournamentMatches, setTournamentMatches] = useState<
    TournamentDetailContextType["tournamentMatches"] | null
  >(null);
  const [currentUserMatches, setCurrentUserMatches] = useState<
    TournamentDetailContextType["currentUserMatches"] | null
  >(null);
  const [tournaments, setTournaments] = useState<TournamentDetailContextType["tournaments"] | null>(
    null,
  );
  return {
    leaderboard,
    tournamentMatches,
    currentUserMatches,
    tournaments,
    setTournaments,
    setCurrentUserMatches,
    setTournamentMatches,
    setLeaderboard,
  };
};

import useAsync from "./useAsync";
import axios from "axios";
import { getAppConfigValue } from "@double-bagel/helpers";
import { useEffect } from "react";

const MINCHAT_API_ENDPOINT = "https://api.minchat.io/v1/";
const UNREAD_MESSAGES_PATH_PATTERN = `user/:userId/messages/unread_count`;
const LOCAL_STORAGE_UNREAD_COUNT_KEY = "chat_unread_count";

const instance = axios.create({
  baseURL: MINCHAT_API_ENDPOINT,
  timeout: 1000,
  headers: {
    Authorization: `Bearer ${getAppConfigValue("MINCHAT_API_KEY")}`,
  },
});

const usePattern = (pattern: string, params: Record<string, string>): string =>
  Object.keys(params).reduce((prev, current, index) => {
    const paramDelim = ":";
    const paramKey = current;
    const paramValue = params[paramKey];
    return pattern.replace(paramDelim + paramKey, paramValue);
  }, pattern);

const useMinchatUnreadMessages = (minchatUserId?: string): any => {
  const { value, execute } = useAsync(async () => {
    if (!minchatUserId) return;
    const uri =
      `${MINCHAT_API_ENDPOINT}` +
      usePattern(UNREAD_MESSAGES_PATH_PATTERN, { userId: minchatUserId });
    const result = await instance.get<{ count: number }>(uri, {
      headers: {
        Authorization: `Bearer ${getAppConfigValue("MINCHAT_API_KEY")}`,
      },
    });
    const msgCount = result.data.count;
    localStorage.setItem(LOCAL_STORAGE_UNREAD_COUNT_KEY, msgCount.toString());
    return msgCount;
  });
  useEffect(() => {
    if (minchatUserId) {
      void execute();
    }
  }, [minchatUserId]);
  return { value };
};
export default useMinchatUnreadMessages;

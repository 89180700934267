import { type FC } from "react";
import { renderToString } from "react-dom/server";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { type PaginationOptions } from "swiper/types";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type BackendModels } from "@double-bagel/endpoints/@types/models";
import MatchReportWidget from "@double-bagel/components/widgets/match-report";
import { useNavigate } from "react-router-dom";
import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";

const setBottomSpaceForPagination = (
  controlHeight: number,
  extraClass?: string,
): Record<string, any> => {
  return {
    className: classNames(
      "after:content-[''] after:block after:h-[var(--controlHeight)]",
      extraClass,
    ),
    style: {
      "--controlHeight": `${controlHeight}px`,
      "--swiper-pagination-bottom": "0",
    },
  };
};

const pagination: PaginationOptions = {
  clickable: true,
  bulletActiveClass: "bg-border-secondary opacity-100",
  renderBullet: function (index: number, className: string) {
    return renderToString(<div className={classNames(className, { hidden: index > 16 })} />);
  },
};

type UpcomingMatchesSliderProps = {
  matches: ClientModel<BackendModels.TournamentMatch>[];
  className?: string;
};

const matchComparator = (
  match1: ClientModel<BackendModels.TournamentMatch>,
  match2: ClientModel<BackendModels.TournamentMatch>,
): number => {
  if (match1.bracketPosition < match2.bracketPosition && match1.tournament === match2.tournament) {
    return -1;
  } else if (
    match1.bracketPosition > match2.bracketPosition &&
    match1.tournament === match2.tournament
  ) {
    return 1;
  } else {
    return match1.tournament < match2.tournament ? -1 : 1;
  }
};

const UpcomingMatchesSlider: FC<UpcomingMatchesSliderProps> = ({ matches, className }) => {
  const navigate = useNavigate();
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      modules={[Pagination]}
      pagination={pagination}
      {...setBottomSpaceForPagination(matches?.length === 1 ? 0 : 28, className)}
    >
      {matches.sort(matchComparator).map((item, index) => (
        <SwiperSlide key={index}>
          <>
            <MatchReportWidget match={item} tournamentMatchTeamId={item.tournamentMatchTeams[0]} />
            <div className="mt-3 flex w-full justify-center">
              <Button
                style={ButtonStyle.Simple}
                onClick={() => {
                  navigate(`/tournament/${item.tournament}`);
                }}
              >
                Go to the tournament
              </Button>
            </div>
          </>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default UpcomingMatchesSlider;

import type React from "react";
import { useEffect, type FC } from "react";
import classNames from "classnames";
import Emoji from "a11y-react-emoji/lib/Emoji";
import isEmojiSupported from "util/is-emoji-supported";
import useAsync from "hooks/useAsync";
import Image from "components/image";

// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397;
// const FLAGS_PATH = "./assets/flags/4x3";

// code as ISO-3166
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getFlagSrc = async (countryCode: string) => {
  const filename = countryCode.toLowerCase() + ".svg";
  // eslint-disable-next-line no-template-curly-in-string
  const res = await import("assets/flags/4x3/" + filename);
  return res.default;
};
type ReactCountryFlagProps = {
  countryCode: string;
  style?: React.CSSProperties;
  forceSvg?: boolean;
  className?: string;
};

export const ReactCountryFlag: FC<ReactCountryFlagProps> = ({
  countryCode,
  style,
  forceSvg = false,
  className,
}) => {
  const { value, execute } = useAsync(async () => await getFlagSrc(countryCode), false);
  useEffect(() => {
    void execute();
  }, [countryCode]);
  if (!isEmojiSupported() || forceSvg) {
    return <Image src={value} classNameWrapper={classNames(className, "w-[1em]")} alt="country" />;
  }
  const emoji = countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + OFFSET));
  return <Emoji className={classNames(className)} symbol={emoji} style={style} />;
};

export default ReactCountryFlag;

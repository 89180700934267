import { useState, type FC } from "react";
import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";

type CopyButtonProps = {
  className?: string;
  children?: React.ReactElement;
  text?: string;
  copyValue: string;
  style?: ButtonStyle;
};

const CopyButton: FC<CopyButtonProps> = ({
  copyValue,
  children,
  text = "Copy",
  style = ButtonStyle.Copy,
}) => {
  const [label, setLabel] = useState(text);
  const [showShareText] = useState(false);

  return (
    <>
      <Button
        style={style}
        labelClassName={"items-center gap-2 tb:gap-3 dk:gap-4"}
        onClick={() => {
          void navigator.clipboard.writeText(copyValue).then(() => {
            setLabel("Copied!");
          });
        }}
      >
        <div className="self-center">{label}</div>
        {children}
      </Button>
      {showShareText && (
        <div className="my-auto mt-0.5 w-min whitespace-nowrap text-sm leading-5 text-[#868686] tb:absolute tb:mt-[95px] tb:text-xl dk:absolute dk:mt-[140px] dk:text-2xl">
          Share with friends! 🚀📲
        </div>
      )}
    </>
  );
};

export default CopyButton;

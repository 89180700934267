import classNames from "classnames";
import { type FC } from "react";

type UnderlineTextProps = {
  value?: string;
  className?: string;
};
const UnderlineText: FC<UnderlineTextProps> = ({ value, className }) => (
  <div className={classNames("relative inline-flex", className)}>
    <span className="cursor-pointer text-xl text-fonts-accentColor tb:text-2xl dk:text-5xl">
      {value}
    </span>
    <div className="absolute bottom-0 h-0.5 w-[100%] translate-y-[2px] rotate-[-0.5deg] rounded-[10px] bg-fonts-accentColor dk:h-1" />
  </div>
);

export default UnderlineText;

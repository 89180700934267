import React from "react";
import {
  TournamentGenders,
  TournamentLevel,
  TournamentStatus,
} from "@double-bagel/endpoints/@types/tournament";
import { DominantHand, FavoriteStrategy } from "@double-bagel/endpoints/@types/user";

export const EmptyFilterValue = "None" as const;

export const GendersFilter = { NoFilter: EmptyFilterValue, ...TournamentGenders };
export const LevelsFilter = { NoFilter: EmptyFilterValue, ...TournamentLevel };
export const StatusFilter = { NoFilter: EmptyFilterValue, ...TournamentStatus };
export const HandFilter = { NodeFilter: EmptyFilterValue, ...DominantHand };
export const FavoriteStrategyFilter = { NodeFilter: EmptyFilterValue, ...FavoriteStrategy };

export type FiltersState = {
  city: string;
  gender: keyof typeof TournamentGenders | typeof EmptyFilterValue;
  level: keyof typeof TournamentLevel | typeof EmptyFilterValue;
  status: keyof typeof TournamentStatus | typeof EmptyFilterValue;
  hand?: keyof typeof DominantHand | typeof EmptyFilterValue;
  strategy?: keyof typeof FavoriteStrategy | typeof EmptyFilterValue;
};
export const DefaultFilterState = undefined;
export const FiltersContext = React.createContext<
  { filters?: FiltersState } & {
    setFilters: React.Dispatch<React.SetStateAction<FiltersState | typeof DefaultFilterState>>;
  }
>({ setFilters: () => {}, filters: DefaultFilterState });

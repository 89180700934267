import { useRef } from "react";

type SetFocusFunc = () => void;
const useFocus = <T extends HTMLElement>(): [React.RefObject<T>, SetFocusFunc] => {
  const htmlElRef = useRef<T>(null);
  const setFocus: SetFocusFunc = () => {
    if (htmlElRef.current) htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

export default useFocus;

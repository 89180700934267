import { Checkbox } from "baseui/checkbox";

export interface MultipleChoiceItem {
  id: string;
  label: string;
}
export type MultipleChoiceOptionType = {
  type: "TEXT" | "ID";
  value: string;
};
const MultipleChoice: React.FC<{
  onOptionSelect: (options: MultipleChoiceOptionType[]) => void;
  selectedOptions: MultipleChoiceOptionType[];
  options: MultipleChoiceItem[];
}> = ({ options, onOptionSelect, selectedOptions }) => {
  const selectedIdsOptions = selectedOptions?.filter((item) => item.type === "ID");
  const textOption = selectedOptions?.find((item) => item.type === "TEXT");
  const handleCheckboxChange = (optionId: string): void => {
    if (selectedIdsOptions.map((item) => item.value).includes(optionId)) {
      let updatedOptions = selectedIdsOptions.filter((item) => item.value !== optionId);
      if (textOption) {
        updatedOptions = [textOption, ...updatedOptions];
      }
      onOptionSelect(updatedOptions);
    } else {
      let updatedOptions = selectedIdsOptions.concat({ type: "ID", value: optionId });
      if (textOption) {
        updatedOptions = [textOption, ...updatedOptions];
      }
      onOptionSelect(updatedOptions);
    }
  };

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onOptionSelect([{ type: "TEXT", value: e.target.value }, ...selectedIdsOptions]);
  };
  return (
    <div className="mt-3 flex flex-col gap-3 rounded-[10px] bg-[#fff] p-4 tb:mt-4 tb:rounded-xl tb:p-5 dk:mt-6 dk:rounded-2xl dk:p-8">
      {options.map((option) => (
        <label
          key={option.id}
          className="align-center grid cursor-pointer grid-cols-[1fr_auto] grid-rows-[auto] items-center gap-3"
        >
          <span className="ml-2 text-base tb:text-xl dk:text-2xl">{option.label}</span>
          <Checkbox
            checked={selectedIdsOptions?.map((item) => item.value).includes(option.id)}
            onChange={() => {
              handleCheckboxChange(option.id);
            }}
            overrides={{
              Label: {
                style: {
                  color: "#868686",
                  fontFamily: '"GolosText", sans-serif',
                  fontWeight: 400,
                },
              },
              Checkmark: {
                style: {
                  // borderRadius: "5px",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  backgroundColor: selectedIdsOptions?.map((item) => item.value).includes(option.id)
                    ? "#19A1FC"
                    : "transparent",
                  padding: "8px",
                  marginTop: "3px",
                },
              },
              Root: {
                props: {
                  className: "",
                },
              },
            }}
          />
        </label>
      ))}
      <div className="ml-2 flex flex-col gap-2">
        <span className="text-base tb:text-xl dk:text-2xl">Other:</span>
        <input
          type="text"
          className="h-12 rounded-lg border border-[#EDEDED] p-4 text-base focus:border-input-outline tb:h-14 tb:text-xl dk:h-20 dk:text-2xl"
          placeholder="Please specify"
          onChange={handleTextInputChange}
          value={textOption?.value ?? ""}
        />
      </div>
    </div>
  );
};

export default MultipleChoice;

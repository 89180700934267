import classNames from "classnames";
import { useRef, type ChangeEventHandler, type FC, type FocusEventHandler } from "react";
import uuid from "util/uuid";

type RadioButtonProps = {
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value: string;
  id?: string;
  className?: string;
  labelClassName?: string;
  children?: React.ReactNode;
  inputRef?: React.Ref<HTMLInputElement>;
};

const RadioButton: FC<RadioButtonProps> = ({
  checked,
  onChange,
  onBlur,
  value,
  className,
  labelClassName,
  id = useRef<string>(uuid()).current,
  children,
  inputRef,
}) => {
  return (
    <>
      <input
        id={id}
        type="radio"
        className={classNames("radio-button", className)}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        ref={inputRef}
      />
      <label htmlFor={id} className={labelClassName}>
        <p className="pl-5">{children}</p>
      </label>
    </>
  );
};

export default RadioButton;

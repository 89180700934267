import classNames from "classnames";
import { type FC } from "react";

const DefaultBadge: FC<{
  title?: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}> = ({ title, children, className }) => (
  <div className={classNames("flex w-fit items-center border", className)}>{title ?? children}</div>
);

export default DefaultBadge;

import React from "react";
import { type UserSettings, type UserProfile } from "@double-bagel/endpoints/@types/user";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";

export const DefaultProfileState = {};
export type UserProfileContextType = {
  profile: ClientModel<UserProfile> | null | undefined;
  settings: ClientModel<UserSettings> | null | undefined;
} & {
  setProfile: React.Dispatch<React.SetStateAction<ClientModel<UserProfile> | null | undefined>>;
  setSettings: React.Dispatch<React.SetStateAction<ClientModel<UserSettings> | null | undefined>>;
};
export const ProfileContext = React.createContext<UserProfileContextType>({
  setProfile: () => {},
  setSettings: () => {},
  settings: null,
  profile: null,
});

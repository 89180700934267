import classNames from "classnames";
import DefaultBadge from "components/badges/default";
import { type FC } from "react";
import RaitingCardOutput from "@double-bagel/components/cards/parts/raiting-line-output";
import RaitingsLineInput from "./parts/raiting-line-input";
import RatingScale from "./parts/rating-scale";

type Subject = {
  param: number;
  view: React.ReactNode;
};

type RaitingCardProps = {
  title: AssessmentPoints;
  left: {
    label: string;
  };
  right: {
    label: string;
  };
  raitings?: Subject[];
  type?: "input" | "output";
  onChange?: (value: number[]) => void;
  value?: number;
  bgColor?: string;
};

export enum AssessmentPoints {
  SKILLS = "Skills",
  STYLES = "Styles",
  SMILES = "Smiles",
}

const RaitingCard: FC<RaitingCardProps> = ({
  onChange,
  title,
  left,
  right,
  raitings,
  value,
  type = "output",
  bgColor = "#FFFFFF",
}) => {
  return (
    <div
      className={classNames("relative w-full rounded-lg tb:rounded-[10] dk:rounded-[15px]", {
        "px-5 py-3 tb:px-8 tb:py-7 dk:px-9 dk:py-8": type === "output",
        "px-3 py-2 tb:px-6 tb:py-5 dk:px-9 dk:py-8": type === "input",
      })}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className="mt-3 grid min-h-[15px] grid-rows-2 tb:min-h-[91px] dk:min-h-[130px]">
        {title && (
          <DefaultBadge
            className="absolute-x-center absolute top-[-13px] rounded-[40px] bg-fonts-darken px-3 py-1.5 text-center text-xs leading-4 text-[#fff] tb:top-[-19px] tb:px-4 tb:py-2 tb:text-base tb:leading-6 dk:top-[-26px] dk:px-5 dk:py-2.5 dk:text-xl dk:leading-9"
            title={title}
          />
        )}

        <div className={classNames("relative mt-[-10px] self-center rounded-[10px]")}>
          {type === "output" && <RaitingCardOutput raitings={raitings} />}
          {type === "input" && <RaitingsLineInput onChange={onChange} value={value} />}
        </div>
        <RatingScale label={{ left: left.label, right: right.label }} assessmentField={title} />
      </div>
    </div>
  );
};

export default RaitingCard;

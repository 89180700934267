import Modal from "react-modal";
import { useLayoutEffect, type FC, useState } from "react";
import { ReactComponent as ExitIcon } from "assets/Icons/buttons/exit.svg";
import classNames from "classnames";

export type DefaultModalProps = {
  showModal: boolean;
  fullSize: boolean;
  onClose: () => void;
  title: string;
  children?: React.ReactNode;
  widthExtraSpaceBottom?: boolean;
  withHeader?: boolean;
};

const TransitionFull: FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  const [styleTransition, setStyleTransition] = useState({
    marginTop: "100vh",
  });
  useLayoutEffect(() => {
    setStyleTransition({ marginTop: "40px" });
  }, []);
  return (
    <div className={classNames(className, "transition-all")} style={styleTransition}>
      {children}
    </div>
  );
};

const Transition: FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  const [styleTransition, setStyleTransition] = useState({
    marginBottom: "-100%",
  });
  useLayoutEffect(() => {
    setStyleTransition({ marginBottom: "0" });
  }, []);
  return (
    <div className={classNames(className, "transition-all")} style={styleTransition}>
      {children}
    </div>
  );
};

const ModalContentFull = (
  props: React.ComponentPropsWithRef<"div">,
  children: React.ReactNode,
): React.ReactElement => {
  const { style, className, ...restProps } = props;

  return (
    <div {...restProps} style={{ zIndex: 200 }}>
      <TransitionFull className="h-[100svh] rounded-t-[20px] border-t-0 bg-[#fff] shadow-modal tb:mx-[42px] dk:mx-[162px]">
        {children}
      </TransitionFull>
    </div>
  );
};

const ModalContent = (
  props: React.ComponentPropsWithRef<"div">,
  children: React.ReactNode,
): React.ReactElement => {
  const { style, className, ...restProps } = props;
  return (
    <div {...restProps} style={{ zIndex: 200 }} className="w-full self-end">
      <Transition className="mt-[5px] rounded-t-[20px] border-t-0 bg-[#fff] shadow-modal">
        {children}
      </Transition>
    </div>
  );
};

const DefaultModal: FC<DefaultModalProps> = ({
  showModal,
  fullSize,
  onClose,
  widthExtraSpaceBottom = true,
  withHeader = true,
  children,
  title,
}) => {
  const ContentElement = fullSize ? ModalContentFull : ModalContent;
  return (
    <Modal
      contentElement={ContentElement}
      isOpen={showModal}
      bodyOpenClassName={"fixed w-[100vw] text-modal"}
      overlayClassName={classNames("fixed w-full z-50 h-[100vh]", {
        "bg-[#00000099] top-0": fullSize,
        "bg-[#00000099] bottom-0 flex": !fullSize,
      })}
      shouldCloseOnOverlayClick
      onRequestClose={() => {
        onClose();
      }}
    >
      {withHeader && (
        <div className="flex border-b-[2px] border-hrLine-default py-[18px] pl-[16px] pr-[0]">
          <div
            onClick={onClose}
            className="relative flex h-fit self-center rounded-full bg-hrLine-default font-Unbounded text-[14px]"
          >
            <div className="z-2 flex h-[23px] w-[23px] items-center justify-center">
              <ExitIcon className="cursor-pointer fill-[black]" />
            </div>
          </div>
          <div
            className={classNames(
              "text-title-modal flex w-full items-center justify-center",
              { "pr-[40px]": title?.length <= 30 },
              { "text-center xs:ml-[50px] xs:w-[230px] tb:ml-0 tb:w-[100%]": title?.length >= 39 },
            )}
          >
            {title}
          </div>
        </div>
      )}
      <>
        {children}
        <div
          className={classNames("p-[8px] tb:p-[16px]", {
            "modal-bottom-space": widthExtraSpaceBottom,
          })}
        />
      </>
    </Modal>
  );
};

export default DefaultModal;

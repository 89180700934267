import { type FC } from "react";
import classNames from "classnames";
import uuid from "@double-bagel/util/uuid";

type Subject = {
  param: number;
  view: React.ReactNode;
};

interface RaitingCardOutputProps {
  lineHeight?: number;
  raitings?: Subject[];
}

const RaitingCardOutput: FC<RaitingCardOutputProps> = ({ raitings, lineHeight = 4 }) => {
  return (
    <>
      <hr
        className="absolute-x-center absolute my-auto mt-0.5 h-1 w-[100%] rounded-[10px] bg-fonts-primary text-fonts-primary before:content-[''] after:content-[''] tb:mt-1 tb:h-1 dk:mt-1 dk:h-1.5"
        style={{ top: `calc(45% - ${Math.floor(lineHeight / 2)}px)` }}
      />
      {raitings?.map((raiting, key) => (
        <div
          key={uuid().toString() + key.toString()}
          className={classNames("absolute z-10 mt-[-15px] h-full tb:mt-[-25px] dk:mt-[-32px]", {
            "animate-animatePulseOverlay1":
              Math.abs(raitings?.[0]?.param - raitings?.[1]?.param) < 0.1 && key === 0,
            "animate-animatePulseOverlay2":
              Math.abs(raitings?.[0]?.param - raitings?.[1]?.param) < 0.1 && key === 1,
          })}
          style={{
            ...(raiting?.param * 20 < 50
              ? { left: `${raiting.param * 20}%` }
              : { right: `${100 - raiting.param * 20}%` }),
          }}
        >
          {raiting.view}
        </div>
      ))}
    </>
  );
};

export default RaitingCardOutput;

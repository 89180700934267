import { type BackendModels } from "@double-bagel/endpoints/@types/models";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { useState, type FC } from "react";
import SoloMatchCard from "../cards/match/solo";
import useAuth from "@double-bagel/hooks/useAuth";
import ConenctWithOpponentModal from "@double-bagel/components/modal/compositions/connect-with-opponent";
import { ReactComponent as ArrowWhite } from "@double-bagel/assets/Icons/arrow-white.svg";
import CopyButton from "@double-bagel/components/buttons/copy-text";
import Button, { ButtonStyle } from "../buttons/default-button";
import { useNavigate } from "react-router-dom";
import DefaultModal from "../modal/default";
import ReportProblemModal from "../modal/compositions/report-problem-modal";
import NotificationModal from "../modal/compositions/notification-modal";
import { CentralizedModal } from "../modal/centralized";
import { TournamentType } from "@double-bagel/endpoints/@types/tournament";
import TeamMatchCard from "../cards/match/team";
import classNames from "classnames";
import MatchReportModalsController from "../modal/compositions/match-report/match-report-controller";

type MatchReportWidgetProps = {
  match: ClientModel<BackendModels.TournamentMatch>;
  tournamentMatchTeamId: number;
  showInfoBox?: boolean;
  opponentName?: string;
  opponentId?: string;
  isMessengerOpened?: boolean;
  hiddenHeader?: boolean;
};

const MatchReportWidget: FC<MatchReportWidgetProps> = ({
  match,
  tournamentMatchTeamId,
  showInfoBox = false,
  hiddenHeader = false,
}) => {
  const { authState } = useAuth();
  const [isConnectViaShow, setIsConnectViaShow] = useState(false);
  const isFinished = !!match?.orderedResult?.results?.[0] || !!match?.orderedResult?.results?.[1];
  const currentUserMatchTeam = match?.tournamentMatchTeams?.[0];
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isReportSuccessShow, setIsReportSuccessShow] = useState(false);
  const [isMatchReportModalsOpened, setIsMatchReportModalsOpened] = useState(false);
  const navigate = useNavigate();
  const oppoTeam = match?.orderedResult?.teams?.[1];
  const [reportModalType, setReportModalType] = useState<"rate" | "score" | undefined>(undefined);
  return (
    <>
      {match && match.tournamentType !== TournamentType.CHALLENGE && (
        <SoloMatchCard
          hiddenHeader={hiddenHeader}
          currentUserId={Number(authState?.userId as string)}
          actualMatch={match}
        />
      )}
      {match && match.tournamentType === TournamentType.CHALLENGE && (
        <TeamMatchCard
          hiddenHeader={hiddenHeader}
          currentUserId={Number(authState?.userId as string)}
          actualMatch={match}
        />
      )}
      {showInfoBox && match.orderedResult.results[0] === null && (
        <div className="mt-6 rounded-xl bg-[#FFFFFF] px-5 py-5 text-[14px] tb:mt-8 tb:p-8 tb:text-lg dk:mt-10 dk:rounded-2xl dk:p-10 dk:text-3xl">
          <p>You’re in! What’s next?</p>
          <ul className="pb-5">
            <li className="font-bold">1. Connect with your opponent.</li>
            <li className="font-bold">2. Play your match and submit the results.</li>
          </ul>
          <p>
            Got a silent partner? Can’t play within the deadline? <strong>Report any issues</strong>
            , and you might advance as the winner.
          </p>
        </div>
      )}
      {isFinished && (
        <div className="grid grid-flow-col content-center gap-3 pt-6 tb:grid-flow-col tb:gap-4 tb:pt-8 dk:grid-flow-col dk:gap-6 dk:pt-10">
          <CopyButton
            className="py-3.5 tb:mr-3 tb:w-[100%] tb:py-3.5 tb:text-xl dk:py-8 dk:text-3xl"
            text="Share results"
            copyValue={document.location.href}
          >
            <ArrowWhite className="mb-[-3px] h-5 w-5 cursor-pointer stroke-white tb:h-6 tb:w-6 dk:h-8 dk:w-8" />
          </CopyButton>
          <Button
            style={ButtonStyle.Secondary}
            onClick={() => {
              setReportModalType("rate");
              setIsMatchReportModalsOpened(true);
            }}
          >
            Rate opponent
          </Button>
        </div>
      )}

      <div
        className={classNames(
          "content-center gap-3 tb:grid-flow-col tb:gap-4  dk:grid-flow-col dk:gap-6 ",
          !isFinished ? "grid grid-rows-2 pt-6 tb:pt-8 dk:pt-10" : "grid pt-3 tb:pt-4 dk:pt-6",
        )}
      >
        {!isFinished && (
          <>
            <Button
              style={ButtonStyle.Primary}
              onClick={() => {
                setIsConnectViaShow(true);
              }}
              className="col-start-1 col-end-3"
            >
              Connect with opponent
            </Button>
            <Button
              style={ButtonStyle.Secondary}
              onClick={() => {
                setReportModalType(undefined);
                setIsMatchReportModalsOpened(true);
              }}
            >
              Submit results
            </Button>
          </>
        )}

        <Button
          style={ButtonStyle.WarningSecondary}
          onClick={() => {
            setIsReportModalOpen(true);
          }}
        >
          Report a problem
        </Button>
      </div>

      {match.tournament && (
        <ConenctWithOpponentModal
          match={match}
          showModal={isConnectViaShow}
          onClose={() => {
            setIsConnectViaShow(false);
          }}
          opponent={oppoTeam?.captain ?? oppoTeam.members?.[0]}
          msg={`Hey, I’m ${oppoTeam?.captain?.firstName ?? oppoTeam.members?.[0].firstName}, we're paired for the next DB match. Let's set a time and place?`}
        />
      )}
      <DefaultModal
        showModal={isReportModalOpen}
        fullSize
        onClose={() => {
          setIsReportModalOpen(false);
        }}
        title={"Report a problem"}
      >
        {tournamentMatchTeamId && (
          <ReportProblemModal
            tournamentId={match.tournament}
            tournamentMatchTeamId={tournamentMatchTeamId}
            onReportSuccess={() => {
              setIsReportModalOpen(false);
              setIsReportSuccessShow(true);
            }}
          />
        )}
      </DefaultModal>
      <CentralizedModal showModal={isReportSuccessShow}>
        <NotificationModal
          text="Problem report has been submitted. We will notify you about the results ASAP."
          heading="Thank you!"
          buttonLabel="Close"
          onClose={() => {
            setIsReportSuccessShow(false);
            navigate(`/tournament/${match.tournament ?? ""}/`);
          }}
        />
      </CentralizedModal>
      <MatchReportModalsController
        tournamentId={match.tournament}
        matchTeamId={currentUserMatchTeam}
        showModal={isMatchReportModalsOpened}
        onClose={() => {
          setIsMatchReportModalsOpened(false);
        }}
        specificModal={reportModalType}
      />
    </>
  );
};

export default MatchReportWidget;

import { type FC } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { Data as AnimationData } from "@double-bagel/components/loaders/data-animation-loader";

type LoaderProps = {
  className?: string;
};

export const Loader: FC<LoaderProps> = () => {
  const DeserializedLoaderAnimation = JSON.parse(AnimationData);
  return (
    <Player
      src={DeserializedLoaderAnimation}
      autoplay
      loop
      className="absolute left-[50%] top-[50%] h-[150px] w-[150px] translate-x-[-50%] translate-y-[-50%]"
    />
  );
};

export default Loader;

import { DatePicker, type DatepickerProps } from "baseui/datepicker";
import { useRef, type FC } from "react";
import TextInput from "./text";

const DateTimePicker: FC<DatepickerProps & { className?: string; inputBgColor?: string }> = ({
  onOpen,
  className,
  onChange,
  inputBgColor = "#FFFFFF",
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<DatePicker<Date>>(null);
  const test = document.querySelector("body");
  return (
    <div
      ref={ref}
      className={className}
      onClick={() => {
        if (!calendarRef?.current?.state.isOpen) calendarRef?.current?.open();
      }}
    >
      <DatePicker
        onChange={(e) => {
          onChange?.(e);
        }}
        onDayClick={() => {
          calendarRef.current?.close();
        }}
        ref={calendarRef}
        formatString={"MMM dd, yyyy hh:mm"}
        mountNode={test as any}
        overrides={{
          Root: {
            props: {
              ref: (el: HTMLDivElement) => {
                if (el) {
                  el?.parentElement?.parentElement?.addEventListener("transitionend", (e) => {
                    el.scrollIntoView();
                  });
                }
              },
            },
          },
          Input: {
            component: TextInput,
            props: {
              style: { width: "100%", opacity: 1, color: "#c3c3c3", backgroundColor: inputBgColor },
            },
          },
        }}
        {...props}
      />
    </div>
  );
};

export default DateTimePicker;

import { type FC } from "react";

export type GameResult =
  | {
      total: number | null;
    }
  | undefined;
export type BO3Result = [GameResult, GameResult, GameResult];

const GameResults: FC<{
  user1Result: BO3Result | "win" | "ret.";
  user2Result: BO3Result | "win" | "ret.";
}> = ({
  user1Result = [undefined, undefined, undefined],
  user2Result = [undefined, undefined, undefined],
}) => {
  return (
    <div className="flex justify-end xs:ml-[10px]">
      <div className="flex flex-[0_1_110px] flex-col rounded-[3px] border-[2px] border-fonts-darken tb:rounded-[10px] tb:border-[3px] dk:rounded-[10px] dk:border-[3px]">
        <div className="flex h-1/2 flex-row items-center justify-around gap-3 px-3 font-Inter text-sm font-semibold text-fonts-darken tb:px-3 tb:text-2xl dk:px-5 dk:text-3xl">
          {Array.isArray(user1Result) ? (
            user1Result?.map((item, gameNum) => (
              <span
                key={`${gameNum}`}
                className={
                  "flex w-2 justify-center py-4 before:content-[attr(content-attr)] tb:w-9 tb:py-4 dk:w-[60px] dk:py-10"
                }
                {...{ "content-attr": `${item === undefined ? "-" : item.total}` }}
              />
            ))
          ) : (
            <>
              <span className={"my-auto w-2 tb:w-9 dk:w-[60px]"} />
              <span
                className={"my-auto flex basis-1/3 before:content-[attr(content-attr)]"}
                {...{ "content-attr": user1Result }}
              />
              <span className={"my-auto w-2 tb:w-9 dk:w-[60px]"} />
            </>
          )}
        </div>
        <span className="flex-[0_0_1.5px] bg-fonts-primary tb:flex-[0_0_2px] dk:flex-[0_0_2px]"></span>
        <div className="flex h-1/2 flex-row items-center justify-around gap-3 px-3 font-Inter text-sm font-semibold text-fonts-darken tb:px-3 tb:text-2xl dk:px-5 dk:text-3xl">
          {Array.isArray(user2Result) ? (
            user2Result?.map((item, gameNum) => (
              <span
                key={`${gameNum}`}
                className={
                  "flex w-2 justify-center py-4 before:content-[attr(content-attr)] tb:w-9 tb:py-4 dk:w-[60px] dk:py-10"
                }
                {...{ "content-attr": `${item === undefined ? "-" : item.total}` }}
              />
            ))
          ) : (
            <>
              <span className={"my-auto w-2 tb:w-9 dk:w-[60px]"} />
              <span
                className={"my-auto flex before:content-[attr(content-attr)]"}
                {...{ "content-attr": user2Result }}
              />
              <span className={"my-auto w-2 tb:w-9 dk:w-[60px]"} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameResults;

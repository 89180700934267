import { type FC, useEffect, useState, useRef, useContext } from "react";
import Emoji from "a11y-react-emoji";
import DefaultHeadline from "@double-bagel/components/headlines/default";
import DescriptionText from "@double-bagel/components/headlines/description-text";
import { useGetCurrentUser, useUploadCustomAvatar } from "@double-bagel/endpoints/endpoints.user";
import classNames from "classnames";
import Image from "@double-bagel/components/image";
import { type BackendModels } from "@double-bagel/endpoints/@types/models";
import useImageCompressor from "@double-bagel/components/widgets/avatar-picker/compressor/useImageCompressor";
import useAsync from "@double-bagel/hooks/useAsync";
import Camera from "@double-bagel/assets/Icons/mono/photo.svg";
import AvatarPickerCropModal from "./avatar-picker-crop-modal";
import "react-advanced-cropper/dist/style.css";
import { ReactComponent as ResetImageIcon } from "@double-bagel/assets/Icons/buttons/exit.svg";
import { ProfileContext } from "@double-bagel/context/profile";

const UploadAvatarButton: FC<{
  className?: string;
  onFileSelect?: (file: FileList | null) => void;
  error?: boolean;
}> = ({ className, onFileSelect, error }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = useState(false);
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const hasError = error || fileError;
  return (
    <div
      className={classNames(
        "relative flex justify-around overflow-hidden rounded-[10px] border-2 border-dashed tb:rounded-xl tb:border-[3px] dk:rounded-[15px] dk:border-4",
        className,
        { "border-[#19A1FC] bg-[#EFF6FA] ": !hasError },
        { "border-warn bg-[#F9EEF1]": hasError },
      )}
      onClick={() => {
        ref.current?.click();
      }}
    >
      <div className="absolute z-10 flex h-full flex-col items-center justify-center">
        <Image
          src={Camera}
          alt="add"
          className={classNames(
            "h-[25.5px] w-[30px] tb:h-[35px] tb:w-[42px] dk:h-[65px] dk:w-[77px]",
            { "fill-[#19A1FC] ": !hasError },
            { "hue-rotate-[150deg]": hasError },
          )}
        />
        <p
          className={classNames(
            "mt-1 text-center text-xs leading-3 text-blueActive tb:mt-1.5 tb:text-lg tb:leading-5 dk:mt-2.5 dk:text-2xl dk:leading-6",
            { "text-blueActive": !hasError },
            { "text-warn": hasError },
          )}
        >
          upload photo
        </p>
        <input
          type="file"
          className="h-0 w-0 opacity-0"
          ref={ref}
          accept="image/jpeg, image/png, image/jpg"
          onChange={(item) => {
            try {
              onFileSelect?.(item.target.files);
            } catch (e) {
              setFileError(true);
            }
          }}
        />
      </div>
    </div>
  );
};

const toBase64 = async (file: File): Promise<string> =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
  });
type AvatarPickerProps = {
  onAvatarChange?: (id: BackendModels.ID | undefined) => void;
  className?: string;
  onAvatarCancel: () => void;
};
const AvatarPicker: FC<AvatarPickerProps> = ({ className, onAvatarChange, onAvatarCancel }) => {
  const { value, execute: reloadCurrentUser } = useGetCurrentUser();
  const { profile, setProfile } = useContext(ProfileContext);
  useEffect(() => {
    if (profile) {
      setSelectedAvatar(profile?.avatar?.id);
    }
  }, [profile]);
  useEffect(() => {
    if (value) {
      setProfile(value);
    }
  }, [value]);
  const [selectedAvatar, setSelectedAvatar] = useState<number>();
  useEffect(() => {
    if (selectedAvatar) onAvatarChange?.(selectedAvatar);
  }, [selectedAvatar]);

  const {
    execute: uploadAvatar,
    value: uploadedAvatar,
    status: uploadAvatarStatus,
  } = useUploadCustomAvatar();

  const [base64BaseImage, setBase64BaseImage] = useState<string>();
  const { compres, value: compresedImage, error } = useImageCompressor();
  const { execute: convertTobase64, value: convertedBase64Image } = useAsync(toBase64);
  const [originFilename, setOriginFilename] = useState<string | undefined>();
  useEffect(() => {
    if (compresedImage) {
      const imageFile = compresedImage as File;
      setOriginFilename(imageFile?.name);
      void convertTobase64(imageFile);
    }
  }, [compresedImage]);
  useEffect(() => {
    if (convertedBase64Image) {
      setBase64BaseImage(convertedBase64Image);
    }
  }, [convertedBase64Image]);

  const [resultFile, setResultFile] = useState<File | undefined>();
  const [resultBase64, setResultBase64] = useState<string | undefined>();
  const handleCrop = (image: string, file: File): void => {
    setResultFile(file);
    setResultBase64(image);
  };
  useEffect(() => {
    if (resultFile) {
      void uploadAvatar(resultFile);
    }
  }, [resultFile]);
  useEffect(() => {
    if (uploadedAvatar) {
      setSelectedAvatar(uploadedAvatar?.id);
      void reloadCurrentUser();
    }
  }, [uploadedAvatar]);
  return (
    <div className={className}>
      <DefaultHeadline className="flex items-center">
        <Emoji
          symbol="🤳"
          className="pr-1 text-base xs:pb-1 tb:pb-0.5 tb:pr-2 tb:text-2xl dk:pr-3 dk:text-3xl"
        />
        <p className="text-lg leading-7 tb:text-2xl tb:leading-9 dk:text-3xl dk:leading-9">
          Profile picture
        </p>
      </DefaultHeadline>
      <DescriptionText>
        {
          "Use a real photo to help your opponents easily spot you on the court. Or skip, and we'll assign you a random Bagel avatar!"
        }
      </DescriptionText>
      <div className="flex gap-5 pt-3 tb:mx-[-48px] tb:px-[48px] tb:pt-4 dk:mx-[-96px] dk:px-[96px] dk:pt-6">
        {!resultBase64 && (
          <UploadAvatarButton
            error={uploadAvatarStatus === "error" || !!error}
            className="grow-1 h-[130px] basis-full cursor-pointer tb:h-[180px] dk:h-[230px]"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onFileSelect={async (file) => {
              if (file) {
                compres(file[0]);
              }
            }}
          />
        )}
        {resultBase64 && (
          <div className="relative m-auto">
            <Image
              src={resultBase64}
              alt={"preview"}
              classNameWrapper="rounded-full w-[190px] mx-auto self-center tb:w-[220px] dk:w-[260px]"
              className="rounded-full shadow-[0px_4px_4px_0_#00000040]"
            />
            <div className="absolute bottom-[10%] right-0 h-[40px] w-[40px] cursor-pointer overflow-hidden rounded-full bg-[black] p-3 hover:bg-fonts-darken tb:h-[60px] tb:w-[60px] dk:h-[80px] dk:w-[80px]">
              <ResetImageIcon
                className="m-auto h-full w-full fill-[#EFF6FA]"
                onClick={() => {
                  setResultBase64(undefined);
                  setResultFile(undefined);
                  onAvatarCancel();
                }}
              />
            </div>
          </div>
        )}
        {base64BaseImage && !resultBase64 && (
          <AvatarPickerCropModal
            onCrop={handleCrop}
            fileName={originFilename ?? `avatar.jpeg`}
            showModal={!!base64BaseImage && !resultBase64}
            imgSrc={base64BaseImage}
            onClose={() => {
              setBase64BaseImage(undefined);
            }}
          >
            <></>
          </AvatarPickerCropModal>
        )}
      </div>
    </div>
  );
};

export default AvatarPicker;

import { type FC } from "react";
import RadioButtonGroup from "@double-bagel/components/input/radio-button-group";
import classNames from "classnames";

export type SingleChoicePropsOption = {
  [id: string]: string;
};

interface SingleChoiceProps {
  options: SingleChoicePropsOption;
  onOptionSelect: (option: string) => void;
  selectedOption?: string;
  error?: string;
}

const SingleChoice: FC<SingleChoiceProps> = ({
  options,
  selectedOption,
  onOptionSelect,
  error,
}) => {
  return (
    <>
      <RadioButtonGroup
        variantsClassName={classNames(
          "dk:p-8 tb:p-5 p-4  bg-[#fff] dk:rounded-2xl tb:rounded-xl rounded-[10px] dk:mt-6 tb:mt-4 mt-3",
          { "border border-warn": error },
        )}
        options={options}
        onOptionSelect={(key) => {
          if (key) onOptionSelect(key);
        }}
        selectedOption={selectedOption}
      />
    </>
  );
};

export default SingleChoice;

import { type FC } from "react";
import TournamentCardPlayers from "@double-bagel/components/collections/players";
import { useNavigate } from "react-router-dom";
import FreeEntryBadge from "components/badges/free-entry";
import PlayerAvatar from "components/avatar/default";
import { type BackendModels } from "@double-bagel/endpoints/@types/models";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import {
  TournamentGenders,
  TournamentLevel,
  TournamentStatus,
} from "@double-bagel/endpoints/@types/tournament";
import classNames from "classnames";
import GenderWithLevel from "@double-bagel/components/cards/parts/tournament-card";
import Image from "@double-bagel/components/image";
import Button, {
  ButtonStyle,
  ButtonFontStyle,
} from "@double-bagel/components/buttons/default-button";
import useTournamentCardColors from "@double-bagel/components/cards/tournament/tournament-cards.hooks";

interface TournamentCardProps {
  tournament: ClientModel<BackendModels.Tournament>;
}

const TournamentCard: FC<TournamentCardProps> = ({ tournament }) => {
  const {
    id,
    city,
    level = TournamentLevel.ROOKIES,
    gender = TournamentGenders.MAN,
    status = TournamentStatus.FINISHED,
    players,
    winner,
    title,
    playersToStart,
    tournamentProduct,
  } = tournament;
  const navigate = useNavigate();
  const { style } = useTournamentCardColors(tournament);
  const { backgroundColor, color } = style;
  return (
    <div
      onClick={() => {
        navigate(`/tournament/${id.toString()}/`);
      }}
      className="transform cursor-pointer overflow-hidden rounded-[10px] border-[1px] bg-white shadow-base hover:translate-x-[1px] hover:translate-y-[1px] hover:scale-[1.01] hover:shadow-[1px_1px_0px_black] hover:transition tb:rounded-2xl tb:border-[2px] dk:rounded-[20px] dk:border-[3px]"
    >
      <div
        className={`flex w-full justify-between gap-x-1 overflow-hidden rounded-t-[9px] tb:rounded-t-[15px] dk:rounded-t-[17px]`}
        style={{ backgroundColor }}
      >
        <h5
          className={`mb-4 self-center pl-5 font-Unbounded font-black uppercase leading-[18px] tb:py-8 tb:pl-8 tb:text-[28px] tb:leading-[32px] dk:text-4xl`}
          style={{ color }}
        >
          {title}
        </h5>
        <Image
          src={city?.cardImage ?? tournament.cardImage}
          alt={city?.name ?? tournament.title}
          classNameWrapper="w-[84px] h-[76px] tb:w-[120px] tb:h-[128px] dk:w-[250px] dk:h-[180px] overflow-hidden !flex items-center"
          className={classNames(
            "h-full w-[84px] object-cover object-left tb:w-[120px] dk:w-[250px]",
            { "grayscale filter": status === TournamentStatus.FINISHED },
          )}
        />
      </div>

      <div className="grid-flow-column-dense -mt-4 grid gap-3 px-5 tb:gap-7 tb:px-8 dk:-mt-[35px] dk:gap-8">
        <GenderWithLevel
          className="text-[10px] leading-[14px]"
          level={level}
          gender={gender}
          status={status}
        />
      </div>

      <div
        className={classNames(
          "mt-3 flex items-center justify-between px-5 pb-5 text-center tb:mt-4 tb:px-8 tb:pb-8 dk:mt-6",
        )}
      >
        {status !== TournamentStatus.FINISHED && (
          <TournamentCardPlayers totalSlots={playersToStart} players={players} />
        )}
        {/* STATUS */}
        {status === TournamentStatus.FINISHED && (
          <TournamentWinner
            fullName={winner?.profile?.firstName ?? ""}
            avatar={winner?.profile?.avatar?.image}
          />
        )}
        {status === TournamentStatus.LIVE && (
          <span className="ml-auto flex items-center pr-5 text-xs leading-4 tb:pr-9 tb:text-lg tb:leading-7 dk:pr-16 dk:text-3xl dk:leading-9">
            ● live
          </span>
        )}
        {status === TournamentStatus.UPCOMING && (
          <div className="flex w-[86px] flex-col tb:w-[171px] dk:w-[269px]">
            <Button
              style={ButtonStyle.Primary}
              buttonFontClass={ButtonFontStyle.Unbounded}
              onClick={() => {
                navigate(`/tournament/${id.toString()}`);
              }}
            >
              VIEW
            </Button>
            {!tournamentProduct && (
              <FreeEntryBadge className="pt-1 text-[8px] leading-[8px] tb:text-xs tb:leading-3 dk:text-base dk:leading-4" />
            )}
          </div>
        )}
        {status === TournamentStatus.FINISHED && (
          <div className="my-[10px] flex flex-auto flex-col items-end pr-0 tb:my-0 tb:pr-8 dk:my-0 dk:pr-[40px]">
            <Button
              style={ButtonStyle.Simple}
              labelClassName="dk:leading-10 tb:leading-7 leading-5 tb:pr-0 xs:pr-3"
            >
              view <br />
              results
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const TournamentWinner: FC<{ fullName: string; avatar?: string }> = ({ fullName, avatar }) => (
  <div className="flex w-[200px] gap-2 rounded-[8px] bg-[#FFE3FB] px-3 py-2 tb:w-[345px] tb:gap-[14px] tb:rounded-xl tb:bg-[#FF8DEA] tb:py-4 dk:w-[580px] dk:gap-[18px] dk:rounded-2xl dk:bg-[#FF8DEA] dk:px-6 dk:py-5">
    <PlayerAvatar
      className="flex h-11 w-11 tb:h-[58px] tb:w-[58px] dk:h-[72px] dk:w-[72px]"
      image={avatar}
    />
    <div className="grid grid-flow-row leading-3 tb:leading-4 dk:leading-6 ">
      <span className="my-text font-TitilliumWeb text-[26px] tb:text-[32px] dk:text-[40px]">
        WINNER
      </span>
      <span className="font-Unbounded text-[10px] tb:ml-1 tb:text-base dk:ml-1 dk:text-xl">
        {fullName}
      </span>
    </div>
  </div>
);

export default TournamentCard;

import { type FC } from "react";
import { Slider } from "baseui/slider";

interface RaitingsLineInputProps {
  onChange?: (value: number[]) => void;
  value?: number;
}

const RaitingsLineInput: FC<RaitingsLineInputProps> = ({ onChange, value }) => {
  return (
    <Slider
      min={1}
      max={5}
      overrides={{
        InnerThumb: { style: { display: "none" } },
        ThumbValue: { style: { display: "none" } },
        TickBar: { style: { display: "none" } },
        Root: { style: { paddingTop: "20px" }, props: { className: "dk:mt-[15px]" } },
        Thumb: {
          style: {
            border: `4px solid #19A1FC`,
            backgroundColor: "#fff",
            height: "22px",
            width: "22px",
          },
          props: { className: "" },
        },
      }}
      value={[value ?? 1]}
      onChange={({ value }) => {
        value && onChange?.(value);
      }}
      onFinalChange={({ value }) => {
        value && onChange?.(value);
      }}
    />
  );
};

export default RaitingsLineInput;

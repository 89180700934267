import { type FC, useState, useEffect, useContext, useRef, useReducer } from "react";
import AuthorizedLayout from "@double-bagel/components/layouts/AuthorizedLayout";
import { ProfileContext } from "@double-bagel/context/profile";
import TournamentsCollection from "../../components/collections/tournaments";
import Image from "components/image";
import IndexPageSlider from "@double-bagel/components/sliders/tournament-list/info";
import DownIcon from "assets/Icons/buttons/down.svg";
import Emoji from "a11y-react-emoji/lib/Emoji";
import ReactCountryFlag from "components/country-flags";
import {
  EmptyFilterValue,
  FiltersContext,
  type FiltersState,
  GendersFilter,
  LevelsFilter,
} from "@double-bagel/context/filter";
import {
  useGetCities,
  useGetPerfectTournament,
  useGetTournaments,
} from "@double-bagel/endpoints/endpoints.tournament";
import UnderlineText from "@double-bagel/components/bars/text-underline";
import { Link, createSearchParams, useLocation, useSearchParams } from "react-router-dom";
import UpcomingMatchesSlider from "@double-bagel/components/sliders/tournament-list/upcoming-matches";
import { useGetUserMatches } from "@double-bagel/endpoints/endpoints.match";
import DefaultHeadline from "@double-bagel/components/headlines/default";
import ArrowGreen from "@double-bagel/assets/Icons/arrow-down-green.svg";
import classNames from "classnames";
import Loader from "@double-bagel/components/loaders/default";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type BackendModels } from "@double-bagel/endpoints/@types/models";
import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";
import FindCourtButton from "@double-bagel/components/buttons/FindCourtButton";
import MatchFilterModal from "@double-bagel/components/modal/compositions/filter-modal";

type TournamentReducerActionTypes =
  | "UPDATE_TOURNAMENTS_BY_STATUS_FILTER"
  | "ADD_TOURNAMENT"
  | "PURE_UPDATE_TOURNAMENTS";
type TournamentReducerAction = {
  type: TournamentReducerActionTypes;
  data: {
    tournamentStatus?: BackendModels.Tournament["status"];
    tournaments?: ClientModel<BackendModels.Tournament>[];
    fullState?: TournamentReducerState;
  };
};
type TournamentReducerState = Record<
  "UPCOMING" | "LIVE" | "FINISHED",
  ClientModel<BackendModels.Tournament>[]
>;

const tournamentsInitial: TournamentReducerState = { FINISHED: [], LIVE: [], UPCOMING: [] };

const tournamentsReducer = (
  state: TournamentReducerState,
  action: TournamentReducerAction,
): TournamentReducerState => {
  const { tournamentStatus, tournaments } = action.data;
  if (action.type === "UPDATE_TOURNAMENTS_BY_STATUS_FILTER") {
    if (tournamentStatus) {
      return { ...tournamentsInitial, [tournamentStatus]: tournaments };
    }
  } else if (action.type === "ADD_TOURNAMENT") {
    if (tournamentStatus) {
      return { ...state, [tournamentStatus]: tournaments };
    }
  } else if (action.type === "PURE_UPDATE_TOURNAMENTS") {
    const { fullState } = action.data;
    return { ...state, ...(fullState ?? {}) };
  } else {
    throw Error("Unknown action for tournamentsReducer.");
  }
  return state;
};

const IndexPage: FC = () => {
  const { profile } = useContext(ProfileContext);
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { filters, setFilters } = useContext(FiltersContext);
  const [isQueryParamsRedirectStart, setIsQueryParamsRedirectStart] = useState(false);
  const isInitFiltersFromQueryParams = useRef(!!location.search);
  useEffect(() => {
    setIsQueryParamsRedirectStart(false);
  }, [location]);

  useEffect(() => {
    if (location.search && isInitFiltersFromQueryParams.current && !isQueryParamsRedirectStart) {
      const queryParams = new URLSearchParams(location.search);

      const gender = queryParams.get("gender");
      const level = queryParams.get("level");
      const city = queryParams.get("city");
      const filterState: Partial<FiltersState> = {
        gender: undefined,
        level: undefined,
        status: undefined,
        city: undefined,
      };
      if (gender === GendersFilter.MAN) filterState.gender = GendersFilter.MAN;
      else if (gender === GendersFilter.WOMAN) {
        filterState.gender = GendersFilter.WOMAN;
      }
      if (level === LevelsFilter.EXPERT) filterState.level = LevelsFilter.EXPERT;
      else if (level === LevelsFilter.ROOKIES) {
        filterState.level = LevelsFilter.ROOKIES;
      }

      if (city !== null) filterState.city = city;

      setFilters((prev) => (prev ? { ...prev, ...filterState } : undefined));
    }
  }, []);
  useEffect(() => {
    if (!filters || isQueryParamsRedirectStart || isInitFiltersFromQueryParams.current) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const gender = queryParams.get("gender");
    const level = queryParams.get("level");
    const city = queryParams.get("city");
    let updatedQueryParams: {
      gender?: string;
      city?: string;
      level?: string;
      status?: string;
    } = filters;
    if (filters.city !== EmptyFilterValue && filters.city !== city) {
      updatedQueryParams = { ...updatedQueryParams, city: filters.city };
    }
    if (filters.gender !== EmptyFilterValue && filters.gender !== gender) {
      updatedQueryParams = { ...updatedQueryParams, gender: filters.gender };
    }
    if (filters.level !== EmptyFilterValue && filters.level !== level) {
      updatedQueryParams = { ...updatedQueryParams, level: filters.level };
    }

    if (Object.keys(updatedQueryParams).length !== 0) {
      setSearchParams(createSearchParams({ ...updatedQueryParams }), { replace: true });
    }
    setIsQueryParamsRedirectStart(true);
  }, [filters]);
  const {
    value: tournaments,
    execute: fetchTournaments,
    status: fetchTournamentsStatus,
  } = useGetTournaments();
  const {
    value: perfectTournament,
    execute: fetchPerfectTournament,
    status: fetchPerfectTournamentStatus,
  } = useGetPerfectTournament();
  const [sortedTournaments, dispatchSortedTournaments] = useReducer(
    tournamentsReducer,
    tournamentsInitial,
  );
  useEffect(() => {
    if (tournaments) {
      if (filters?.status && filters?.status !== "None") {
        dispatchSortedTournaments({
          type: "UPDATE_TOURNAMENTS_BY_STATUS_FILTER",
          data: { tournamentStatus: filters.status, tournaments },
        });
      } else {
        const sortedTournaments = tournaments.reduce((prev, current) => {
          return { ...prev, [current.status]: [current, ...prev[current.status]] };
        }, tournamentsInitial);
        dispatchSortedTournaments({
          type: "PURE_UPDATE_TOURNAMENTS",
          data: { fullState: sortedTournaments },
        });
      }
    }
  }, [tournaments]);
  useEffect(() => {
    if (filters && fetchTournamentsStatus !== "pending") {
      void fetchTournaments(filters);
    }
  }, [filters]);
  useEffect(() => {
    if (filters) void fetchTournaments(filters);
  }, []);
  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);
  const { execute: fetchCities, value: cities } = useGetCities();
  const { execute: fetchUserMatches, value: userMatches } = useGetUserMatches();
  useEffect(() => {
    void fetchUserMatches();
    void fetchCities();
    void fetchPerfectTournament();
  }, []);
  useEffect(() => {
    void fetchCities();
  }, [isFilterModalOpened]);
  const currentCity = cities?.find((item) => item.name === filters?.city);
  return (
    <AuthorizedLayout
      title=""
      image={profile?.avatar?.image ?? ""}
      page="/user"
      isLoading={fetchPerfectTournamentStatus === "pending"}
      isShowNotificationsButton={true}
    >
      <div
        className={classNames("transition-all duration-1000 will-change-contents", {
          "h-0 opacity-0": !userMatches || userMatches?.length === 0,
          "h-auto opacity-100": userMatches && userMatches?.length > 0,
        })}
      >
        <DefaultHeadline className="mt-1 pb-3 text-lg tb:pb-[16px] tb:text-2xl dk:pb-6 dk:text-3xl">
          Current matches
        </DefaultHeadline>
        <UpcomingMatchesSlider
          className="mx-[-15px] mb-[32px] px-[15px]"
          matches={userMatches ?? []}
        />
      </div>
      {currentCity?.courtBookingUrl && (
        <div className="cursor-pointer pb-8">
          <DefaultHeadline className="mt-1 pb-3 text-lg tb:pb-[16px] tb:text-2xl dk:pb-6 dk:text-3xl">
            Courts map
          </DefaultHeadline>
          <FindCourtButton
            courtBookingImage={currentCity?.courtBookingImage ?? ""}
            courtBookingUrl={currentCity?.courtBookingUrl ?? ""}
          />
        </div>
      )}
      <DefaultHeadline className="dk:text-3x mt-1 pb-3 text-lg tb:pb-4 tb:text-2xl dk:pb-6">
        Top picks for you
      </DefaultHeadline>
      {perfectTournament && (
        <TournamentsCollection
          tournaments={perfectTournament}
          isFetching={fetchPerfectTournamentStatus === "pending"}
        />
      )}
      <div className="flex justify-between pb-3 pt-8 tb:pb-[16px] tb:pt-10 dk:pb-6 dk:pt-[64px]">
        <DefaultHeadline className="dk:text-3x text-lg tb:text-2xl">How it works</DefaultHeadline>
        <Link to="https://help.dbagel.co/en/articles/8856981-double-bagel-rules-and-code-of-conduct">
          <div className="font-GolosTextfont-normal flex cursor-pointer p-[3px] leading-[20px] text-[#868686] underline underline-offset-4 xs:text-sm tb:text-sm dk:text-[24px] dk:leading-9">
            all rules
          </div>
        </Link>
      </div>
      <IndexPageSlider className="mx-[-16px] px-[16px]" />
      <div className="relative inline-grid w-full grid-flow-col pb-3 pt-8 font-Unbounded text-xl font-bold leading-6 tb:pt-10 tb:text-2xl dk:pt-16 dk:text-[42px]">
        <div
          onClick={() => {
            setIsFilterModalOpened(true);
          }}
        >
          {cities && filters?.city && (
            <>
              <UnderlineText
                value={filters?.city === EmptyFilterValue ? "All World" : filters?.city}
                className="dk:pb-4"
              />
              {!filters?.city || filters?.city === EmptyFilterValue ? (
                <Emoji symbol="🗺️" className="pl-[5px]" />
              ) : (
                <ReactCountryFlag
                  countryCode={currentCity?.countryCode as string}
                  className="w-5 pl-[5px] tb:w-6 tb:pl-[7px] dk:mb-0.5 dk:w-10 dk:pl-[10px]"
                  forceSvg
                />
              )}
            </>
          )}
          <Image
            src={ArrowGreen}
            alt="Arrow"
            className="mb-1 ml-2 mr-[5px] h-[7px] w-[11px] tb:mb-0.5 tb:ml-3 tb:mr-[7px] tb:h-[9px] tb:w-[14px] dk:mb-2 dk:ml-4 dk:mr-[10px] dk:h-[14px] dk:w-6"
          />
        </div>
        <Button
          style={ButtonStyle.Filter}
          labelClassName="text-[14px] tb:text-lg dk:text-2xl"
          onClick={() => {
            setIsFilterModalOpened(true);
          }}
        >
          <div className="hover:hue-rotate-15">
            filter
            <Image
              src={DownIcon}
              alt={"open"}
              className="ml-1 h-[10px] w-[10px] cursor-pointer tb:ml-1.5 tb:h-[14px] tb:w-[14px] dk:ml-2 dk:h-[18px] dk:w-[18px] "
              classNameWrapper="self-center"
            />
          </div>
        </Button>
      </div>
      {fetchTournamentsStatus === "pending" && (
        <div className="relative flex h-[60px] items-center justify-center tb:h-[120px] dk:h-[180px]">
          <Loader />
        </div>
      )}
      {fetchTournamentsStatus === "success" && (
        <>
          {sortedTournaments.UPCOMING && sortedTournaments.UPCOMING?.length !== 0 && (
            <>
              <div className="pb-3 text-sm leading-5 text-fonts-secondary tb:pb-4 tb:pt-8 tb:text-xl tb:leading-7 dk:pb-6 dk:pt-10 dk:text-2xl dk:leading-9">
                Upcoming
              </div>
              <TournamentsCollection tournaments={sortedTournaments.UPCOMING} />
            </>
          )}
          {sortedTournaments.LIVE && sortedTournaments.LIVE?.length !== 0 && (
            <>
              <div className="pb-3 pt-5 text-sm leading-5 text-fonts-secondary tb:pb-4 tb:pt-8 tb:text-xl tb:leading-7 dk:pb-6 dk:pt-10 dk:text-2xl dk:leading-9">
                Live
              </div>
              <TournamentsCollection tournaments={sortedTournaments.LIVE} />
            </>
          )}
          {sortedTournaments.FINISHED && sortedTournaments.FINISHED?.length !== 0 && (
            <>
              <div className="pb-3 pt-5 text-sm leading-5 text-fonts-secondary tb:pb-4 tb:pt-8 tb:text-xl tb:leading-7 dk:pb-6 dk:pt-10 dk:text-2xl dk:leading-9">
                Ended
              </div>
              <TournamentsCollection tournaments={sortedTournaments.FINISHED} />
            </>
          )}
          {tournaments?.length === 0 && (
            <div className=" my-4 text-center text-fonts-optional xs:text-sm tb:text-sm dk:text-[24px]">
              No results found
            </div>
          )}
        </>
      )}
      <MatchFilterModal
        cities={cities}
        showModal={isFilterModalOpened}
        onClose={() => {
          setIsFilterModalOpened(false);
        }}
      />
    </AuthorizedLayout>
  );
};

export default IndexPage;

import { camelizeObject } from "@double-bagel/endpoints/adapter/util";
import jwt_decode from "jwt-decode";

const _events: Record<string, Array<(...args: any) => void>> = {};

class AuthStorageEvents {
  static token_changed = "token_changed";
  // eslint-disable-next-line accessor-pairs
  public set OnTokenChanged(value: (token: string) => void) {
    if (!_events?.token_changed) _events.token_changed = [value];
    else _events.token_changed = _events.token_changed.concat(value);
  }

  protected emit(event: string, ...args: any[]): void {
    _events[event]?.forEach((handler) => {
      handler(...args);
    });
  }
}

type DecodedJWT = {
  exp: number;
  iat: number;
  isRefresh: boolean;
  isEmptyProfile: boolean;
  isEmptySettings: boolean;
  userId: "1";
};

export class AuthLocalStorage extends AuthStorageEvents {
  EMPTY_TOKEN = "";
  STORAGE_KEY = "token";

  getToken(): string {
    return localStorage.getItem(this.STORAGE_KEY) ?? this.EMPTY_TOKEN;
  }

  setToken(jwt: string = this.EMPTY_TOKEN): void {
    localStorage.setItem(this.STORAGE_KEY, jwt);
    this.emit(AuthStorageEvents.token_changed, this.getToken());
  }

  isTokenExist(): boolean {
    return this.getToken() !== this.EMPTY_TOKEN;
  }

  // TODO Cache ?
  deserialize(): DecodedJWT | null {
    const token = this.getToken();
    if (token) return camelizeObject(jwt_decode<object>(token));
    return null;
  }

  clear(): void {
    localStorage.removeItem(this.STORAGE_KEY);
    this.emit(AuthStorageEvents.token_changed, this.getToken());
  }
}

import { useUser, useMinChat, MinChatProvider } from "@minchat/react";

import { type FC, type PropsWithChildren, useState, useCallback } from "react";
import { MinChatUI } from "@minchat/reactui";
import { MessengerContext, type MessengerContextType } from "@double-bagel/context/messanger";
import DefaultModal from "@double-bagel/components/modal/default";
import { getAppConfigValue } from "@double-bagel/helpers";
import { ReactComponent as ExitIcon } from "assets/Icons/buttons/exit.svg";
import MessageInput from "@double-bagel/components/minchat/input";
import useMinchatUnreadMessages from "@double-bagel/hooks/useMinchatUnreadMessages";
import { createMinChatUser, useInAppMessenger } from "@double-bagel/MinChatMessenger.hooks";
import useIntercomHidden from "./hooks/useIntercomHidden";

const MinChatMessenger: FC<{ showModal: boolean; onClose: () => void }> = ({
  showModal,
  onClose,
}) => {
  const {
    startConversation,
    messengerContext: { currentChat },
  } = useInAppMessenger();
  const currentUser = useUser();
  const minChatController = useMinChat();
  const apiKey = minChatController?.instance?.config?.apiKey;
  useMinchatUnreadMessages(currentUser?.id);
  // const metadata = currentChat?.getMetadata();
  useIntercomHidden(showModal);
  // useEffect(() => {
  //   getOrCreateMatchChat()
  // }, []);
  const chatId = currentChat?.getId();
  return (
    <DefaultModal
      showModal={showModal}
      onClose={onClose}
      fullSize
      widthExtraSpaceBottom
      title=""
      withHeader={false}
    >
      <div className="overflow-y-auto">
        {currentUser && apiKey && (
          <MinChatUI
            height="94svh"
            showAttachButton={false}
            mobileView
            user={currentUser}
            apiKey={apiKey}
            openChatId={chatId}
            startConversation={startConversation}
            renderInput={({ inputProps, isMobile, sendMessage }) => (
              <div className={isMobile ? "text-base" : "text-xl"}>
                <MessageInput
                  {...inputProps}
                  showAttachButton={false}
                  onSendMessage={(text) => {
                    sendMessage(text);
                  }}
                />
              </div>
            )}
            renderMessageListHeader={({ heading, clearMessageList, lastActive, isMobile }) => (
              <div className="flex w-full justify-center p-3">
                <div
                  onClick={onClose}
                  className="shrink-1 relative flex h-fit grow-0 self-center rounded-full bg-hrLine-default font-Unbounded text-[14px]"
                >
                  <div className="z-2 flex h-[23px] w-[23px] items-center justify-center">
                    <ExitIcon className="cursor-pointer fill-[black]" />
                  </div>
                </div>
                <div className="grow-1 flex shrink-0 basis-11/12 justify-center">
                  {heading.split(":")?.[1] ?? ""}
                </div>
              </div>
            )}
          />
        )}
      </div>
    </DefaultModal>
  );
};

interface MinChatMessangerProps extends PropsWithChildren {
  userId?: string;
  userFullName?: string;
  userAvatar?: string;
}

const Messenger: FC<MinChatMessangerProps> = ({ children, userId, userFullName, userAvatar }) => {
  const [isMessangerOpen, setIsMessangerOpen] = useState<boolean>(false);
  const openMessenger = useCallback(() => {
    setIsMessangerOpen(true);
  }, []);
  const closeMessenger = useCallback(() => {
    setIsMessangerOpen(false);
  }, []);
  const [currentConversationalists, setCurrentConversationalists] =
    useState<MessengerContextType["currentConversationalists"]>(null);
  const [currentChat, setCurrentChat] = useState<MessengerContextType["currentChat"]>(null);
  return (
    <>
      {(!userId || !userFullName) && <>{children}</>}
      {userId && userFullName && (
        <MinChatProvider
          apiKey={getAppConfigValue("MINCHAT_API_KEY") ?? ""}
          user={createMinChatUser(userId, userFullName)}
        >
          <MessengerContext.Provider
            value={{
              isMessangerOpen,
              closeMessenger,
              openMessenger,
              currentConversationalists,
              setCurrentConversationalists,
              currentChat,
              setCurrentChat,
            }}
          >
            {children}
            <MinChatMessenger
              showModal={isMessangerOpen}
              onClose={() => {
                setIsMessangerOpen(false);
              }}
            />
          </MessengerContext.Provider>
        </MinChatProvider>
      )}
    </>
  );
};

export default Messenger;

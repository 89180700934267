import { useState, type FC, useEffect, useRef } from "react";
import classNames from "classnames";

interface CommonProgressBarProps {
  currentValue: number;
  maxValue: number;
  classname: string;
}

const getDynamicWidth = (size: number) => ({ style: { flexBasis: `${size}%` } }) as const;

const CommonProgressBar: FC<CommonProgressBarProps> = ({ currentValue, maxValue, classname }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [factor, setFactor] = useState<number>();
  useEffect(() => {
    setFactor(currentValue / maxValue);
  }, [maxValue, currentValue]);
  return (
    <div className="flex gap-1 tb:gap-2 dk:gap-3">
      <div ref={ref} className="flex w-full rounded-xl bg-background">
        {ref?.current && factor !== undefined && (
          <div
            className={classNames(
              "h-3 rounded-xl border border-[#19A1FC] bg-[#19A1FC] transition-all duration-1000 tb:h-3 dk:h-3",
              classname,
            )}
            {...getDynamicWidth(factor * 100)}
          />
        )}
      </div>
    </div>
  );
};

export default CommonProgressBar;

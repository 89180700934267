import { type default as React, type FC } from "react";
import { renderToString } from "react-dom/server";
import classNames from "classnames";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { type PaginationOptions } from "swiper/types";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";

import Image from "@double-bagel/components/image";
import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";

import Slide1BackgroundImage from "assets/Images/slider/first.svg";
import Slide2BackgroundImage from "assets/Images/slider/second.svg";
import Slide3BackgroundImage from "assets/Images/slider/third.svg";

type SliderItem = {
  head: React.ReactNode;
  content: React.ReactNode;
  image?: {
    src: string;
    width?: number | string;
    height?: number | string;
    className?: string;
    classNameWrapper?: string;
  };
  bg?: string;
};

const SlideDataSet: SliderItem[] = [
  {
    head: (
      <h4 className="font-Unbounded text-[16px] font-[900] uppercase leading-[20px] text-[#0045F6] tb:text-4xl tb:leading-8 dk:text-7xl dk:leading-[70px]">
        {"One tournament three matches"}
      </h4>
    ),
    content: (
      <p className="pb-1 pt-[10px] font-GolosText font-semibold text-fonts-primary xs:w-[202px] tb:w-full tb:pb-[0.5px] tb:pt-4 dk:w-full dk:pb-[0.5px] dk:pt-8">
        {
          "Play 3 guaranteed matches in each tournament, win or lose. Every round offers a chance to earn points and rise in the leaderboard."
        }
      </p>
    ),
    image: {
      src: Slide1BackgroundImage,
      className:
        "dk:w-[380px] dk:h-[380px] tb:w-[210px] w-[250px] h-[279px] relative tb:top-[-30px] tb:left-8 dk:top-2 dk:left-[60px] bottom-[63px] left-5",
      classNameWrapper: "dk:w-[350px] dk:h-[350px] tb:w-[210px] tb:h-[210px] w-[135px] h-[135px]",
    },
    bg: "#C6F600",
  },
  {
    head: (
      <h4 className="font-Unbounded text-[16px] font-[900] uppercase leading-[20px] text-[#FF4FED] tb:text-4xl tb:leading-8 dk:text-7xl dk:leading-[70px]">
        {"How it works"}
      </h4>
    ),
    content: (
      <div className="h-[110px] pb-1 pt-4 font-GolosText text-xs font-semibold leading-[14px] text-fonts-darken tb:h-[160px] tb:w-[324px] tb:pb-6 tb:pt-4 tb:text-lg dk:h-[268px] dk:w-[500px] dk:pb-[61px] dk:pt-8 dk:text-3xl">
        1. Join the upcoming tournament that fits your profile <br />
        2. The action starts when 8 players are ready <br />
        3. Connect with your opponent and kick some ass!
      </div>
    ),
    image: {
      src: Slide2BackgroundImage,
      className:
        "dk:w-[330px] dk:h-[340px] tb:w-[210px] tb:h-[210px] w-[150px] h-[150px] relative tb:top-6 tb:left-7 dk:top-10 dk:left-[35px] bottom-[-20px] left-[20px]",
    },
    bg: "#DAF2FF",
  },
  {
    head: (
      <h4 className="w-[229px] font-Unbounded text-[16px] font-[900] uppercase leading-[20px] text-[#029AFF] tb:w-auto tb:text-4xl tb:leading-8 dk:text-7xl dk:leading-[70px]">
        {"Love Double Bagel?"}
      </h4>
    ),
    content: (
      <>
        <div className="w-[179px] py-3 font-GolosText text-xs font-semibold leading-[14px] text-fonts-darken tb:w-[320px] tb:text-lg dk:w-[600px] dk:py-[30px] dk:text-3xl">
          Complete a short survey and help us improve the app!
        </div>
        <Button
          style={ButtonStyle.Primary}
          onClick={() => window.open("https://forms.gle/TMAtByxCr9WFq3Hu6", "_blank")}
        >
          Go to survey
        </Button>
      </>
    ),
    image: {
      src: Slide3BackgroundImage,
      className: "dk:w-[330px] tb:w-[180px] w-[150px] relative bottom-[-5px]",
    },
    bg: "#FFFF",
  },
];

const pagination: PaginationOptions = {
  clickable: true,
  bulletActiveClass: "bg-border-secondary opacity-100",
  renderBullet: function (index: number, className: string) {
    return renderToString(<div className={classNames(className)} />);
  },
};

const setBottomSpaceForPagination = (
  controlHeight: number,
  extraClass?: string,
): Record<string, any> => {
  return {
    className: classNames(
      "after:content-[''] after:block after:h-[var(--controlHeight)]",
      extraClass,
    ),
    style: {
      "--controlHeight": `${controlHeight}px`,
      "--swiper-pagination-bottom": "0",
    },
  };
};

type IndexPageSliderProps = {
  className?: string;
};

const IndexPageSlider: FC<IndexPageSliderProps> = ({ className }) => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      modules={[Autoplay, Pagination]}
      pagination={pagination}
      {...setBottomSpaceForPagination(28, className)}
    >
      {SlideDataSet.map((item, index) => (
        <SwiperSlide key={index} className="h-auto">
          <div
            className="flex h-full overflow-hidden rounded-[10px] border border-[black] tb:rounded-xl tb:border-2 dk:rounded-[15px] dk:border-[3px]"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            style={{ backgroundColor: item.bg }}
          >
            <div className="flex-[0_1_215px] pb-[20px] pl-4 pt-4 tb:flex-[0_1_365px] tb:py-8 tb:pl-8 dk:flex-[0_1_700px] dk:py-10 dk:pl-10">
              {item.head}
              <div className="text-[12px] leading-[14px] tb:text-lg dk:text-3xl dk:leading-snug">
                {item.content}
              </div>
            </div>
            <div
              className="relative z-20 flex flex-auto items-center justify-end"
              style={{ backgroundColor: item.bg }}
            >
              {item.image && (
                <Image
                  src={item.image.src}
                  alt={`slide-${index}`}
                  className={classNames(item.image.className)}
                  classNameWrapper={classNames("absolute", item.image.classNameWrapper)}
                />
              )}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default IndexPageSlider;

import classNames from "classnames";
import { type FC } from "react";

type DefaultHeadlineProps = {
  children: React.ReactNode;
  className?: string;
};

const DefaultHeadline: FC<DefaultHeadlineProps> = ({ children, className }) => (
  <h3 className={classNames("title1", className)}>{children}</h3>
);

export default DefaultHeadline;

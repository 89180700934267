export const isObject = (obj: any): obj is Record<string, any> => {
  if (obj === undefined || obj === null) {
    return false;
  }
  return !isArray(obj) && typeof obj === "object";
};

export const isArray = (obj: unknown): obj is Array<unknown> => Array.isArray(obj);
type AppConfigResult = {
  NODE_ENV?: string;
  API_ENDPOINT?: string;
  WS_API_ENDPOINT?: string; // for web sockets
  INCLUDE_LEADERBOARD?: boolean;
  STRIPE_PUBLIC_KEY?: string;
  MINCHAT_API_KEY?: string;
  STAND_NAME?: typeof process.env.REACT_APP_STAND_NAME;
  FIREBASE_VAPID_KEY?: string;
};
export const getAppConfigValue = <K extends keyof AppConfigResult>(
  key: K,
): AppConfigResult[K] | undefined => {
  return {
    NODE_ENV: process.env.NODE_ENV,
    WS_API_ENDPOINT: process.env.REACT_APP_WS_API_ENDPOING,
    API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
    INCLUDE_LEADERBOARD: Boolean(Number(process.env.REACT_APP_CONFIG_INCLUDE_LEADERBOARD)),
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    MINCHAT_API_KEY: process.env.REACT_APP_MINCHAT_API_KEY ?? "clql1qe4000103kes8z395lb1",
    STAND_NAME:
      process.env.NODE_ENV === "production" ? process.env.REACT_APP_STAND_NAME ?? "local" : "local",
    FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  }[key];
};

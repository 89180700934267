import { type GameScore } from "@double-bagel/components/input/scores-input";

const isSetComplete = (set1Score: number, set2Score: number): [boolean, 1 | 2 | undefined] => {
  if (
    (set1Score === 6 && set2Score >= 0 && set2Score <= 4) ||
    (set1Score === 7 && set2Score === 5) ||
    (set1Score === 7 && set2Score === 6)
  ) {
    return [true, 1];
  } // (6, [0..4]) V (7, [5,6])

  if (
    (set2Score === 6 && set1Score >= 0 && set1Score <= 4) ||
    (set2Score === 7 && set1Score === 5) ||
    (set2Score === 7 && set1Score === 6)
  ) {
    return [true, 2];
  } // ([0..4], 6) V ([5,6], 7)
  return [false, undefined];
};

const validateTennisScore = (
  player1Scores: [GameScore, GameScore, GameScore],
  player2Scores: [GameScore, GameScore, GameScore],
  isSoloTeam: boolean = true,
): [string, boolean] => {
  const pureScores1 = player1Scores.filter((item) => item !== undefined) as number[];
  const pureScores2 = player2Scores.filter((item) => item !== undefined) as number[];

  if (pureScores1.length !== pureScores2.length) {
    return ["Both players should have a score in every set", false];
  }

  if (pureScores1.length > 3 || pureScores2.length > 3) {
    return ["", false];
  } else if (pureScores1.length < 0 || pureScores2.length < 0) {
    return ["", false];
  } // [1, 3] - available set count

  let player1Wins = 0;
  let player2Wins = 0;

  for (let currentSet = 0; currentSet < pureScores1.length; currentSet++) {
    const player1Score = pureScores1[currentSet];
    const player2Score = pureScores2[currentSet];

    const currentSetVerbose = currentSet + 1;

    if (isSoloTeam && (player1Score > 7 || player2Score > 7)) {
      return [
        `Set ${currentSetVerbose}: That's a high score! In tennis, game counts for a set shouldn't exceed 7. Please re-enter.`,
        false,
      ];
    } else if (player1Score === 7 || player2Score === 7) {
      if ((player1Score === 7 && player2Score < 5) || (player2Score === 7 && player1Score < 5)) {
        return [
          `Set ${currentSetVerbose}: You need a 2-game lead to win a set, unless it's 7-6 with a tiebreak. Double-check your entry!`,
          false,
        ];
      }
    } else if (player1Score === 0 || player2Score === 0) {
      if (player1Score === 0 && player2Score === 0) {
        return [
          `Set ${currentSetVerbose}:  Seems like the match hasn't even started. Please input the correct score. `,
          false,
        ];
      }
    } else if (player1Score < 0 || player2Score < 0) {
      return ["", false];
    } // [0, 7]
    const isLastSet = currentSetVerbose === pureScores1.length;
    if (!isLastSet) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [hasSetWinner, _] = isSetComplete(player1Score, player2Score);
      if (!hasSetWinner) {
        return [
          `Set ${currentSetVerbose}: You need a 2-game lead to win a set, unless it's 7-6 with a tiebreak. Double-check your entry!`,
          false,
        ];
      }
    }
    if (player1Score > player2Score) {
      player1Wins++;
    } else if (player1Score < player2Score) {
      player2Wins++;
    }
  }
  if (player1Wins === player2Wins) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [hasSetWinner, _] = isSetComplete(pureScores1[0], pureScores2[0]);
    if (!hasSetWinner) {
      return [`Hold on, tennis sets don't end in draws. Please adjust your score.`, false];
    }
    if (pureScores1[2] && pureScores2[2]) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [hasSetWinner, _] = isSetComplete(pureScores1[2], pureScores2[2]);
      if (!hasSetWinner) {
        return [`Hold on, tennis sets don't end in draws. Please adjust your score.`, false];
      }
    }
  }
  return ["", true];
};

export const useScoreValidation = (): {
  isSetComplete: typeof isSetComplete;
  validate: typeof validateTennisScore;
} => ({
  isSetComplete,
  validate: validateTennisScore,
});

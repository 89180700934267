import { useEffect, useState, type FC } from "react";
import DefaultModal from "@double-bagel/components/modal/default";
import DefaultHeadline from "@double-bagel/components/headlines/default";
import ScoresInputGroup from "@double-bagel/components/input/scores-input-group";
import {
  type TournamentMatchTeamResultModel,
  TournamentType,
} from "@double-bagel/endpoints/@types/tournament";
import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";
import { type ScoreValue } from "@double-bagel/components/input/scores-input";
import {
  useGetMatchTeamInfoById,
  useSendMatchResult,
} from "@double-bagel/endpoints/endpoint.match";
import { useScoreValidation } from "./submit-score-modal.hooks";
import ValidationScoreModal from "../validation-score-modal";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import classNames from "classnames";
import { type BackendModels } from "@double-bagel/endpoints/@types/models";

type SubmitScoreModalProps = {
  showModal: boolean;
  onClose: () => void;
  matchTeamId: BackendModels.ID;
  onScoreSubmit?: (response: ClientModel<TournamentMatchTeamResultModel>) => void;
};

const SubmitScoreModal: FC<SubmitScoreModalProps> = ({
  showModal,
  onClose,
  matchTeamId,
  onScoreSubmit,
}) => {
  const { validate } = useScoreValidation();
  const [currentUserScores, setCurrentUserScores] = useState<ScoreValue>([
    undefined,
    undefined,
    undefined,
  ]);
  const [opponentScores, setOpponentScores] = useState<ScoreValue>([
    undefined,
    undefined,
    undefined,
  ]);
  const {
    execute: sendResult,
    value: sendResultValue,
    isFetching: isSendResultFetching,
  } = useSendMatchResult();
  const {
    execute: fetchMatchTeamInfo,
    value: matchTeamInfo,
    isFetching: isTeamInfoFetching,
  } = useGetMatchTeamInfoById();
  const actualMatch = matchTeamInfo?.tournamentMatch;
  useEffect(() => {
    if (matchTeamInfo) {
      const ownScores = actualMatch?.orderedResult?.scores?.[0];
      const oppoScores = actualMatch?.orderedResult?.scores?.[1];
      if (ownScores) {
        setCurrentUserScores(ownScores as ScoreValue);
      }
      if (oppoScores) {
        setOpponentScores(oppoScores as ScoreValue);
      }
    }
  }, [matchTeamInfo]);
  useEffect(() => {
    if (!showModal) return;
    if (matchTeamId) void fetchMatchTeamInfo(Number(matchTeamId));
  }, [matchTeamId, showModal]);
  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const [isScoreValidationShow, setIsScoreValidationShow] = useState(false);
  useEffect(() => {
    if (sendResultValue) {
      onScoreSubmit?.(sendResultValue);
    }
  }, [sendResultValue]);
  const isSendResultDisabled = !!actualMatch?.orderedResult?.scores || isSendResultFetching;
  return (
    <>
      <DefaultModal
        showModal={showModal}
        onClose={onClose}
        fullSize
        widthExtraSpaceBottom
        title="Submit score"
      >
        <div
          className={classNames("px-4 py-5 transition-opacity tb:px-6 tb:py-7 dk:px-8 dk:py-9", {
            "opacity-0": isTeamInfoFetching,
          })}
        >
          <DefaultHeadline className="text-base tb:text-2xl dk:text-3xl">
            Submit score
          </DefaultHeadline>
          <div className="title1-description pt-[4px] text-[14px] leading-6 tb:text-[18px] dk:text-[24px]">
            Enter your match results
          </div>
          <div className="grid-cols-min mt-3 grid w-full auto-rows-min rounded-xl border border-solid bg-[#fff] font-GolosText shadow-base tb:mt-4 dk:mt-5">
            <div className="col-span-2 h-full w-full px-[16px] py-[15px] tb:px-[24px] tb:py-[20px] dk:px-[40px] dk:py-[30px]">
              <div className="grid grid-flow-col-dense">
                <ScoresInputGroup
                  disabled={isSendResultDisabled}
                  ownScores={currentUserScores}
                  oppoScores={opponentScores}
                  onOwnScoresChanged={(val) => {
                    setCurrentUserScores(val);
                  }}
                  onOppoScoresChanged={(val) => {
                    setOpponentScores(val);
                  }}
                  currentUserAvatar={actualMatch?.orderedResult?.teams?.[0]?.members?.map(
                    (item) => item.avatar?.image ?? "",
                  )}
                  opponentAvatar={actualMatch?.orderedResult?.teams?.[1]?.members?.map(
                    (item) => item.avatar?.image ?? "",
                  )}
                  opponentName={
                    actualMatch?.tournamentType === "CHALLENGE"
                      ? actualMatch?.orderedResult?.teams?.[1].name
                      : actualMatch?.orderedResult?.teams?.[1]?.members?.[0]?.firstName
                  }
                />
              </div>
            </div>
          </div>
          <div className="my-4 flex w-full flex-col justify-stretch">
            <Button
              style={isSendResultDisabled ? ButtonStyle.Disabled : ButtonStyle.Primary}
              id="send_scores"
              isDisabled={isSendResultDisabled}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={async () => {
                if (isSendResultFetching) {
                  return;
                }
                const [msg, isValid] = validate(
                  currentUserScores,
                  opponentScores,
                  matchTeamInfo?.tournamentMatch?.tournamentType !== TournamentType.CHALLENGE,
                );
                if (!isValid) {
                  setValidationErrorMsg(msg);
                  setIsScoreValidationShow(true);
                  return;
                }
                await sendResult(Number(matchTeamInfo?.tournamentMatch?.tournament), {
                  oppoScores: opponentScores.map((item: any) => Number(item)),
                  ownScores: currentUserScores.map((item: any) => Number(item)),
                  tournamentMatchTeamId: Number(matchTeamId),
                });
              }}
            >
              Save the results
            </Button>
          </div>
        </div>
      </DefaultModal>
      <ValidationScoreModal
        showModal={isScoreValidationShow}
        onClose={() => {
          setIsScoreValidationShow(false);
        }}
        message={validationErrorMsg}
      />
    </>
  );
};

export default SubmitScoreModal;

import axios from "axios";
import { type ClientModel } from "./adapter/client-models";
import asHook from "./adapter/hook";
import { type BackendModels } from "./@types/models";
import { type ResultFailReasonMapping } from "./@types/tournament";

export interface CreateMatchResultData {
  tournamentMatchTeamId: BackendModels.ID;
  oppoScores?: Array<number>;
  ownScores?: Array<number>;
}
export interface CreateMatchReportData {
  tournamentMatchTeamId: BackendModels.ID;
  failReason?: keyof typeof ResultFailReasonMapping;
  failReasonDescription?: string;
}
export const useSendMatchResult = asHook(
  async (tournamentId: BackendModels.ID, data: CreateMatchResultData) => {
    let length = 3;
    if (data.ownScores?.[2] === undefined || data.oppoScores?.[2] === undefined) {
      length = 2;
    }

    const result = await axios.post<BackendModels.TournamentMatchTeamResult>(
      `/tournament/${tournamentId}/result/`,
      {
        own_scores: data.ownScores?.slice(0, length),
        oppo_scores: data.oppoScores?.slice(0, length),
        tournament_match_team: data.tournamentMatchTeamId,
      },
    );
    return result.data;
  },
  "MatchResults",
);

export const useSendMatchReport = asHook(
  async (tournamentId: BackendModels.ID, data: CreateMatchReportData) => {
    const result = await axios.post<BackendModels.TournamentMatchTeamResult>(
      `/tournament/${tournamentId}/report/`,
      {
        tournament_match_team: data.tournamentMatchTeamId,
        fail_reason: data.failReason,
        other_fail_reason: data.failReasonDescription,
      },
    );
    return result.data;
  },
  "MatchReport",
);

export const useSendMatchFeedback = asHook(
  async (
    tournamentId: BackendModels.ID,
    data: Partial<ClientModel<BackendModels.UserFeedbackScore>>,
  ) => {
    const result = await axios.post<BackendModels.UserFeedbackScore>(
      `/tournament/${tournamentId}/feedback/`,
      {
        match_team: data.matchTeam,
        value: data.value,
        type: data.type,
      },
    );
    return result.data;
  },
  "UserFeedbackScore",
);

export const useSendMatchSurvey = asHook(
  async (
    tournamentId: BackendModels.ID,
    data: {
      dateOfMatch: ClientModel<BackendModels.MatchResultDetail>["dateOfMatch"];
      place: ClientModel<BackendModels.MatchResultDetail>["place"];
      tournamentMatchTeam: ClientModel<BackendModels.MatchResultDetail>["tournament_match_team"];
    },
  ) => {
    const result = await axios.post<BackendModels.MatchResultDetail>(
      `/tournament/${tournamentId}/survey/`,
      {
        date_of_match: data.dateOfMatch,
        place: data.place,
        tournament_match_team: data.tournamentMatchTeam,
      },
    );
    return result.data;
  },
  "MatchResultDetail",
);

export const useGetMatchById = asHook(
  async (tournamentId: BackendModels.ID, matchId: BackendModels.ID) => {
    const result = await axios.get<BackendModels.TournamentMatch>(
      `/tournament/${tournamentId}/matches/${matchId}`,
    );
    return result.data;
  },
  "TournamentMatch",
);

export const useGetMatchTeamInfoById = asHook(async (matchTeamId: BackendModels.ID) => {
  const result = await axios.get<BackendModels.TournamentMatchTeam>(`/match_team/${matchTeamId}/`);
  return result.data;
}, "TournamentMatchTeamInfo");

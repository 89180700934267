import React from "react";

export type AuthState = {
  userId?: string;
  exp?: number;
  isRefresh?: boolean;
  isEmptyProfile?: boolean;
  isEmptySettings?: boolean;
  hasSubscription?: boolean;
};

export type AuthContextType = { auth: AuthState | null } & {
  setAuth: React.Dispatch<React.SetStateAction<AuthState | null>>;
};
export const AuthContext = React.createContext<AuthContextType>({
  setAuth: () => {},
  auth: null,
});

import { type FC } from "react";
import { Link } from "react-router-dom";
import Image from "@double-bagel/components/image";
import Histogram from "@double-bagel/assets/Icons/Histogram.svg";

const LeaderboardButton: FC = () => {
  return (
    <Link to="/leaderboard">
      <div className="relative ml-2 flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-background tb:ml-2 tb:h-[63px] tb:w-[63px] dk:ml-[20px] dk:h-[76px] dk:w-[75px]">
        <Image
          src={Histogram}
          alt={"leaderboardbutton"}
          className="h-7 w-7 tb:h-9 tb:w-9 dk:h-12 dk:w-12"
        />
      </div>
    </Link>
  );
};

export default LeaderboardButton;

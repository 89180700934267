import { type AuthState } from "@double-bagel/context/auth";
import { type UserProfile } from "@double-bagel/endpoints/@types/user";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { useGetCurrentUser } from "@double-bagel/endpoints/endpoints.user";
import { useState, useEffect } from "react";
import { type AsyncStatuses } from "./useAsync";
import { type AsHookWrappedFunc } from "@double-bagel/endpoints/adapter/hook";

type useActualUserProfileReturnType = {
  profile: ClientModel<UserProfile> | null | undefined;
  setProfile: React.Dispatch<React.SetStateAction<ClientModel<UserProfile> | null | undefined>>;
  status: AsyncStatuses;
  fetch: () => Promise<AsHookWrappedFunc<UserProfile>>;
};
const useActualUserProfile = ({
  auth,
  setAuth,
}: {
  auth: AuthState | null;
  setAuth: React.Dispatch<React.SetStateAction<AuthState | null>>;
}): useActualUserProfileReturnType => {
  const { value, error, execute, status } = useGetCurrentUser();
  const [profile, setProfile] = useState<ClientModel<UserProfile> | null | undefined>();
  useEffect(() => {
    if (!error && value) {
      setProfile(value);
    }
  }, [value, error]);
  useEffect(() => {
    if (profile) {
      setAuth((state) => ({ ...state, isEmptyProfile: !!profile.isEmptyProfile }));
    }
  }, [profile]);
  return {
    profile,
    setProfile,
    status,
    fetch: execute,
  };
};

export default useActualUserProfile;

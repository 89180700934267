import classNames from "classnames";
import { type FC } from "react";

type DescriptionTextProps = {
  className?: string;
  children?: React.ReactNode;
};
const DescriptionText: FC<DescriptionTextProps> = ({ className, children }) => (
  <div
    className={classNames(
      className,
      "title1-description text-sm leading-5 tb:text-lg tb:leading-7 dk:text-2xl dk:leading-9",
    )}
  >
    {children}
  </div>
);
export default DescriptionText;

export const createCacheKeyFromArgs = (args: any[]): string =>
  args.reduce<string>((cacheKey, arg) => {
    const current = `_${typeof arg === "object" ? JSON.stringify(args) : `${arg as string}`}_`;
    return `${cacheKey}${current}`;
  }, "");

type AnyFunction<A extends unknown[], R> = (...args: A) => R;

const memoize = <ARGS extends unknown[], RET>(
  fn: AnyFunction<ARGS, RET>,
): AnyFunction<ARGS, RET> => {
  const cache: Record<string, RET> = {};

  return (...args: ARGS) => {
    const cacheKey = createCacheKeyFromArgs(args);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }

    const res = fn(...args);
    cache[cacheKey] = res;
    return res;
  };
};
export default memoize;

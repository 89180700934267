import { useEffect } from "react";

const useIntercomHidden = (isHidden?: boolean): void => {
  useEffect(() => {
    window?.Intercom?.("update", {
      hide_default_launcher: isHidden,
    });
  });
};

export default useIntercomHidden;

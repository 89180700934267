import { type Chat, type User } from "@minchat/react";
import React from "react";

export type MessengerContextType = {
  isMessangerOpen: boolean;
  currentConversationalists: User[] | null;
  currentChat: Chat | null;
} & {
  setCurrentConversationalists: React.Dispatch<React.SetStateAction<User[] | null>> | null;
  setCurrentChat: React.Dispatch<React.SetStateAction<Chat | null>> | null;
  openMessenger: () => void;
  closeMessenger: () => void;
};
export const MessengerContext = React.createContext<MessengerContextType>({
  openMessenger: () => {},
  closeMessenger: () => {},
  setCurrentConversationalists: null,
  setCurrentChat: null,
  currentConversationalists: null,
  currentChat: null,
  isMessangerOpen: false,
});

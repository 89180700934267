import axios from "axios";
import asHook from "./adapter/hook";

export type AuthResponse = {
  token: string;
  expires: number;
};
export type PhoneCodeResponse = {
  verificationId: string;
  resendTimeout: string;
};
export const useSendPhoneCode = asHook(async (phone: string): Promise<PhoneCodeResponse> => {
  const result = await axios.post<PhoneCodeResponse>(`/auth/send_phone_code/`, {
    phone,
  });
  if (axios.isAxiosError(result)) {
    throw new axios.AxiosError(
      result.response?.data?.errors?.[0],
      result.response?.status.toString(),
    );
  }
  return result?.data;
}, "AuthPhoneResponse");

type SignUpVerifyCodeResponse = {
  profile?: Record<string, any>;
};
type SignInVerifyCodeResponse = {
  auth?: AuthResponse;
};
export type VerifyCodeResponse = SignUpVerifyCodeResponse & SignInVerifyCodeResponse;

export const useVerifyPhoneCode = asHook(
  async (verificationId: string, code: string): Promise<VerifyCodeResponse> => {
    const result = await axios.post<VerifyCodeResponse>(`/auth/verify_phone_code/`, {
      verification_id: verificationId,
      code,
    });
    return result.data;
  },
  "AuthCodeResponse",
);

export type SignUpResponse = {
  auth: AuthResponse;
  profile: {
    id: number;
    phone: string;
    name?: string;
  }; // TODO возможно тоже не надо
  name?: string; // TODO not used now
};

export const useSignUp = asHook(async (verificationId: string): Promise<SignUpResponse> => {
  const result = await axios.post<SignUpResponse>("/auth/signup/", {
    verification_id: verificationId,
  });
  return result.data;
}, "SignUpResponse");

type useTournamentCardColorsResult = {
  style: {
    backgroundColor: string;
    color: string;
  };
};
const useTournamentCardColors = (): useTournamentCardColorsResult => {
  return {
    style: {
      backgroundColor: "#2b3c24",
      color: "#eafba1",
    },
  };
};

export default useTournamentCardColors;

import { useEffect, useLayoutEffect, useMemo, useState, type FC } from "react";
import {
  type QuestionByIdResult,
  type SurveyNotificationMessage,
} from "@double-bagel/hooks/websocket/survey/useSurvey";
import classNames from "classnames";
import Modal from "react-modal";
import ProgressBarLayout from "@double-bagel/components/layouts/ProgressBarLayout";
import SingleChoice from "@double-bagel/components/input/specific/radio-button-group/common-single-choice";
import Loader from "@double-bagel/components/loaders/default";
import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";
import MultipleChoice from "@double-bagel/components/input/common-multiple-choice";

interface SurveyPassingModalProps {
  showModal: boolean;
  surveyData: SurveyNotificationMessage | null;
  onClose: () => void;
  getQuestionById: (questionId: number) => void;
  currentQuestion: QuestionByIdResult | null;
  updateQuestionAnswers: (
    questionId: number,
    variantIds?: Array<number>,
    variantText?: string,
  ) => void;
}

const TransitionFull: FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  const [styleTransition, setStyleTransition] = useState({
    opacity: 0,
  });
  useLayoutEffect(() => {
    setStyleTransition({ opacity: 1 });
  }, []);
  return (
    <div
      className={classNames(className, "transition delay-500 duration-200")}
      style={styleTransition}
    >
      {children}
    </div>
  );
};

const ModalContentFull = (
  props: React.ComponentPropsWithRef<"div">,
  children: React.ReactNode,
): React.ReactElement => {
  const { style, className, ...restProps } = props;

  return (
    <div {...restProps} style={{ zIndex: 200 }}>
      <TransitionFull className="h-[100vh] bg-background">{children}</TransitionFull>
    </div>
  );
};

const SurveyPassingModal: FC<SurveyPassingModalProps> = ({
  showModal,
  onClose,
  getQuestionById,
  surveyData,
  currentQuestion,
  updateQuestionAnswers,
}) => {
  const [selectedOption, setSelectedOptions] = useState<string>();
  const [selectedMultipleOptions, setSelectedMultipleOptions] = useState<Array<string>>([]);
  const [selectedOtherOption, setSelectedOtherOption] = useState<string>();
  const [questionIds, setQuestionIds] = useState<SurveyNotificationMessage["questions"]>([]);
  const [currentQuestionId, setCurrentQuestionId] =
    useState<SurveyNotificationMessage["actualQID"]>();
  useEffect(() => {
    if (currentQuestionId) {
      if (currentQuestionId !== currentQuestion?.pk) {
        getQuestionById(currentQuestionId);
      }
    }
  }, [currentQuestionId]);
  useEffect(() => {
    if (surveyData) {
      if (surveyData.finished) {
        onClose();
        return;
      }
      setQuestionIds(surveyData?.questions ?? []);
      if (surveyData.actualQID) {
        setCurrentQuestionId(surveyData.actualQID);
      }
    }
  }, [surveyData]);
  useEffect(() => {
    if (currentQuestionId) getQuestionById(currentQuestionId);
  }, [currentQuestionId]);
  const handleBackButtonClick = (): void => {
    setSelectedMultipleOptions([]);
    setSelectedOptions(undefined);
    setSelectedOtherOption(undefined);
    const currentIndex = questionIds.findIndex((value) => value === currentQuestionId);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : 0;
    const prevQuestion = questionIds[prevIndex];
    setCurrentQuestionId(prevQuestion);
  };
  const questionIndex = questionIds.findIndex((value) => value === currentQuestionId);

  const [options, setOptions] = useState<{ [id: string]: string }>();
  useMemo(() => {
    if (currentQuestion) {
      const options = Object.keys(currentQuestion?.variants).reduce(
        (acc, variantId) => ({
          ...acc,
          [variantId]: currentQuestion.variants[Number(variantId)],
        }),
        {},
      );
      setOptions(options);
      if (currentQuestion?.answers) {
        if (currentQuestion?.type === "SINGLE") {
          const firstAnswerKey = Object.keys(currentQuestion.answers)[0];
          const firstAnswerVariant = currentQuestion.answers[Number(firstAnswerKey)];
          setSelectedOptions(firstAnswerVariant.toString());
        } else if (currentQuestion?.type === "MULTIPLE") {
          const answerIdsOrText = Object.values(currentQuestion.answers);
          const answerIds = answerIdsOrText.filter((item) => Number.isInteger(item)) as number[];
          const answerText = answerIdsOrText.find((item) => typeof item === "string") as
            | string
            | undefined;

          setSelectedOtherOption(answerText);
          setSelectedMultipleOptions(answerIds.map((item) => item.toString()));
        }
      }
    }
  }, [currentQuestion]);
  return (
    <Modal
      contentElement={ModalContentFull}
      isOpen={showModal}
      bodyOpenClassName={"fixed w-[100vw] text-modal"}
      overlayClassName={classNames("fixed w-full z-50 h-[100vh] top-0")}
      shouldCloseOnOverlayClick
      onRequestClose={() => {
        onClose();
      }}
    >
      <div className="relative h-full overflow-y-auto">
        <ProgressBarLayout
          progressbarFill={questionIndex}
          maxValue={questionIds?.length}
          onBackButtonClick={handleBackButtonClick}
        >
          {!currentQuestion && <Loader />}
          <h3 className="font-Unbounded text-lg tb:text-2xl dk:text-3xl">
            {currentQuestion?.value}
          </h3>
          {currentQuestion?.type === "SINGLE" && options && (
            <SingleChoice
              options={options}
              onOptionSelect={function (option): void {
                setSelectedOptions(option);
              }}
              selectedOption={selectedOption}
            />
          )}
          {currentQuestion?.type === "MULTIPLE" && options && (
            <MultipleChoice
              options={Object.keys(options).map((optionId) => ({
                id: optionId,
                label: options[optionId],
              }))}
              onOptionSelect={(options) => {
                const textOption = options.find((item) => item.type === "TEXT");
                const checkBoxOptions = options.filter((item) => item.type === "ID");
                setSelectedOtherOption(textOption?.value);
                setSelectedMultipleOptions(checkBoxOptions.map((item) => item.value));
              }}
              selectedOptions={[
                ...(selectedOtherOption
                  ? [{ type: "TEXT" as const, value: selectedOtherOption }]
                  : []),
                ...selectedMultipleOptions.map((item) => ({
                  type: "ID" as const,
                  value: item,
                })),
              ]}
            />
          )}
          {currentQuestionId && (
            <Button
              isDisabled={
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                !(selectedOption || selectedMultipleOptions.length || selectedOtherOption)
              }
              style={
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                !(selectedOption || selectedMultipleOptions.length || selectedOtherOption)
                  ? ButtonStyle.Disabled
                  : ButtonStyle.Primary
              }
              className="bottom-[38px] mx-auto my-10 w-11/12 tb:w-11/12 dk:w-11/12"
              onClick={() => {
                if (currentQuestion?.type === "SINGLE") {
                  updateQuestionAnswers(currentQuestionId, [Number(selectedOption)], undefined);
                  setSelectedOptions(undefined);
                } else if (currentQuestion?.type === "MULTIPLE") {
                  console.log(selectedMultipleOptions);
                  console.log(selectedOtherOption);
                  updateQuestionAnswers(
                    currentQuestionId,
                    selectedMultipleOptions.map((item) => Number(item)),
                    selectedOtherOption,
                  );
                  setSelectedMultipleOptions([]);
                  setSelectedOtherOption(undefined);
                }
              }}
            >
              Next
            </Button>
          )}
        </ProgressBarLayout>
      </div>
    </Modal>
  );
};

export default SurveyPassingModal;

import { useContext, useMemo, useState } from "react";
import {
  type User,
  type UserProps,
  useMinChat,
  useUser,
  type GroupChatProps,
  type Chat,
} from "@minchat/react";
import { type TournamentMatchModel } from "@double-bagel/endpoints/@types/match";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { getAppConfigValue } from "@double-bagel/helpers";
import { type MessengerContextType, MessengerContext } from "@double-bagel/context/messanger";
import useAuth from "@double-bagel/hooks/useAuth";

export const createMinChatID = (userId: string): string =>
  `${getAppConfigValue("STAND_NAME")}:${userId}`;

export const createMinChatName = (userId: string, userFullName?: string): string =>
  userFullName ?? `USER_WITHOUT_NAME_ID_${userId}`;

export const createMinChatUser = (
  userId: string,
  userFullName?: string,
  metadata?: UserProps["metadata"],
  avatar?: string,
): UserProps => ({
  username: createMinChatID(userId),
  name: createMinChatName(userId, userFullName),
  metadata,
  avatar,
});

type UseInAppMessengerResult = {
  messengerContext: MessengerContextType;
  isLoading: boolean;
  getOrCreateGroupChat: (
    conversationalists: UserProps[],
    title: string,
    metadata?: GroupChatProps["metadata"],
  ) => Promise<Chat | null | undefined>;
  getOrCreateMatchChat: (
    match: ClientModel<TournamentMatchModel>,
    tournamentName?: string,
  ) => Promise<Chat | null | undefined>;
  startConversation?: () => UserProps["username"][];
};

// forceConversation отвечает за открытие созданного чата
// (минуя UI со списком чатов)
export const useInAppMessenger = (forceConversation: boolean = true): UseInAppMessengerResult => {
  const messengerContext = useContext(MessengerContext);
  const { authState } = useAuth();
  const { setCurrentConversationalists, setCurrentChat, currentChat, currentConversationalists } =
    messengerContext;
  const currentMinchatUser = useUser();
  const minChatController = useMinChat();
  const [isLoading, setIsLoading] = useState(false);
  const chatId = currentChat?.getId();
  useMemo(() => {
    if (currentChat) {
      setIsLoading(false);
    }
  }, [chatId]);
  const getOrCreateGroupChat = async (
    conversationalists: UserProps[],
    title: string,
    metadata?: GroupChatProps["metadata"],
  ): Promise<any> => {
    if (!currentMinchatUser) {
      console.error("Current minchat user is not initialized");
      return;
    }
    if (!minChatController) return;
    setIsLoading(true);
    const minchatUsers = await Promise.all(
      conversationalists.map(async (user): Promise<User> => {
        return await minChatController?.createUser(user);
      }),
    );
    setCurrentConversationalists?.(minchatUsers);
    const allChatMembers = minchatUsers;
    const chat = await minChatController.groupChat({
      memberUsernames: allChatMembers.map((item) => item?.username),
      title,
      metadata,
    });
    setCurrentChat?.(chat);
    setIsLoading(false);
    return chat;
  };
  const getOrCreateMatchChat = async (
    match: ClientModel<TournamentMatchModel>,
    tournamentName?: string,
  ): Promise<any> => {
    setIsLoading(true);
    const defaultChatTitle = `${tournamentName}`;
    if (!authState?.userId) {
      return;
    }
    const userMetadata = {
      [authState.userId]: match.id,
    };
    const conversationalists = match.orderedResult.teams.reduce<UserProps[]>((acc, team) => {
      return [
        ...acc,
        ...team?.members?.map((member) =>
          createMinChatUser(
            member.user.toString(),
            member.firstName,
            userMetadata,
            member.avatar?.image,
          ),
        ),
      ];
    }, []);
    const chatMetadata = {
      matchId: match.id,
    };
    const groupChat = await getOrCreateGroupChat(
      conversationalists,
      defaultChatTitle,
      chatMetadata,
    );
    const realChatMetadata = groupChat?.getMetadata();
    if (realChatMetadata && !("matchId" in realChatMetadata)) {
      await groupChat?.setMetaData(chatMetadata);
    }
    return groupChat;
  };
  const startConversation = (): string[] => {
    let result: string[] = [];
    currentConversationalists?.forEach((user) => {
      const username = user?.username;
      if (result) {
        result = [...result, username];
      } else {
        result = [username];
      }
    });
    return result;
  };
  return {
    messengerContext,
    getOrCreateGroupChat,
    getOrCreateMatchChat,
    isLoading,
    startConversation,
  };
};

import {
  TournamentLevel,
  TournamentStatus,
  type TournamentModel,
} from "@double-bagel/endpoints/@types/tournament";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";

type useTournamentCardColorsResult = {
  style: {
    backgroundColor: string;
    color: string;
  };
};
const useTournamentCardColors = (
  tournament: ClientModel<TournamentModel>,
): useTournamentCardColorsResult => {
  const { status, level, gender, type, city } = tournament;
  const { colorsDuels, colorsSeries } = city ?? { colorsDuels: undefined, colorsSeries: undefined };
  if (status === TournamentStatus.FINISHED) {
    return {
      style: { backgroundColor: "#DBDBDB", color: "#7f7f7f" },
    };
  }
  if (type === "DUEL") {
    return {
      style: {
        backgroundColor: colorsDuels ?? "#CBCAFF",
        color: "#5e5bfe",
      },
    };
  } else if (level === TournamentLevel.CASUAL && gender === "MAN") {
    return {
      style: {
        backgroundColor: colorsSeries ?? "#CEECFF",
        color: "#14A5FC",
      },
    };
  } else if (level === TournamentLevel.ROOKIES && gender === "MAN") {
    return {
      style: {
        backgroundColor: colorsSeries ?? "#EDF8FF",
        color: "#57c0ff",
      },
    };
  } else if (level === TournamentLevel.EXPERT && gender === "MAN") {
    return {
      style: {
        backgroundColor: colorsSeries ?? "#CEECFF",
        color: "#14a5fc",
      },
    };
  } else if (level === TournamentLevel.CASUAL && gender === "WOMAN") {
    return {
      style: {
        backgroundColor: colorsSeries ?? "#F3CCFF",
        color: "#FF89E9",
      },
    };
  } else if (level === TournamentLevel.ROOKIES && gender === "WOMAN") {
    return {
      style: {
        backgroundColor: colorsSeries ?? "#FAEAFF",
        color: "#ff89e9",
      },
    };
  } else if (level === TournamentLevel.EXPERT && gender === "WOMAN") {
    return {
      style: {
        backgroundColor: colorsSeries ?? "#F3CCFF",
        color: "#c36ee3",
      },
    };
  }
  return {
    style: {
      backgroundColor: colorsSeries ?? "#54C3BC",
      color: "#7f7f7f",
    },
  };
};

export default useTournamentCardColors;

import { getFirebaseToken } from "@double-bagel/firebase-configure";
import { isSupported, onMessage, type MessagePayload } from "firebase/messaging";
import { useState, useEffect } from "react";
import { messaging } from "..";
import { useCreateFCMDevice } from "@double-bagel/endpoints/endpoint.devices";
import uuid from "@double-bagel/util/uuid";

interface NotificationPayloadProps {
  data?: MessagePayload | undefined;
  open: boolean;
}

const useFirebaseNotification = (): (NotificationPayloadProps | undefined)[] => {
  const [notificationPayload, setNotificationPayload] = useState<
    (NotificationPayloadProps | undefined)[]
  >([]);
  const { execute: createDevice } = useCreateFCMDevice();
  // This is self invoking function that listen of the notification
  void (async (): Promise<void> => {
    const messagingResolve = messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload: MessagePayload) => {
        setNotificationPayload([{ data: payload, open: true }]);
        setTimeout(() => {
          setNotificationPayload([{ open: false }]);
        }, 6000);
      });
    }
  })();

  const handleGetFirebaseToken = async (): Promise<void> => {
    try {
      void getFirebaseToken().then(async (firebaseToken: string | undefined) => {
        if (firebaseToken) {
          await createDevice({
            deviceId: uuid(),
            registrationId: firebaseToken,
            type: "web",
            active: true,
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    void isSupported().then((isSupportedBrowser) => {
      if (!isSupportedBrowser) {
        return;
      }
      if (window.Notification?.permission === "granted") {
        void handleGetFirebaseToken();
      } else {
        void Notification.requestPermission().then(() => {
          void handleGetFirebaseToken();
        });
      }
    });
  }, []);
  return notificationPayload;
};

export default useFirebaseNotification;

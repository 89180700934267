import { useState, type FC, useEffect } from "react";
import classNames from "classnames";

import PlayerAvatar from "@double-bagel/components/avatar/default";
import GameResults, { type BO3Result } from "@double-bagel/components/widgets/game-result";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import Image from "@double-bagel/components/image";

import ClockIcon from "assets/Icons/clock.png";
import { type BackendModels } from "@double-bagel/endpoints/@types/models";
import { Link } from "react-router-dom";
import useMatchCardDeadline from "./match-cards.hooks";

type MatchCardProps = {
  actualMatch: ClientModel<BackendModels.TournamentMatch>;
  currentUserId?: BackendModels.ID;
  hiddenHeader?: boolean;
};

const SoloMatchCard: FC<MatchCardProps> = ({ actualMatch, currentUserId, hiddenHeader }) => {
  const [results, setResults] = useState<{
    user1Result: BO3Result | "win" | "ret.";
    user2Result: BO3Result | "win" | "ret.";
  }>({
    user1Result: [undefined, undefined, undefined],
    user2Result: [undefined, undefined, undefined],
  });
  useEffect(() => {
    if (actualMatch) {
      let resultReport = actualMatch.orderedResult.results[0];
      let resultReportFrom: "own" | "oppo" = "own";
      if (!resultReport) {
        resultReport = actualMatch.orderedResult.results[1];
        resultReportFrom = "oppo";
      }
      const ownRes: BO3Result = [undefined, undefined, undefined];
      const oppoRes: BO3Result = [undefined, undefined, undefined];
      if (resultReport?.resultType === "SUCCESS") {
        resultReport?.scores?.forEach((value, index) => {
          if (resultReportFrom === "own") {
            ownRes[index] = {
              total: value.ownScore,
            };
            oppoRes[index] = {
              total: value.oppoScore,
            };
          } else if (resultReportFrom === "oppo") {
            ownRes[index] = {
              total: value.oppoScore,
            };
            oppoRes[index] = {
              total: value.ownScore,
            };
          }
        });
        setResults({
          user1Result: ownRes,
          user2Result: oppoRes,
        });
      } else if (resultReport?.isWithdraw) {
        if (actualMatch.winner === currentUserId) {
          setResults({
            user1Result: "win",
            user2Result: "ret.",
          });
        } else {
          setResults({
            user1Result: "ret.",
            user2Result: "win",
          });
        }
      } else if (resultReport?.resultType === "RESOLVED") {
        if (actualMatch.winner === currentUserId) {
          setResults({
            user1Result: "win",
            user2Result: "ret.",
          });
        } else {
          setResults({
            user1Result: "ret.",
            user2Result: "win",
          });
        }
      }
    }
  }, [actualMatch]);
  const { isFinished, value } = useMatchCardDeadline(actualMatch);
  return (
    <div className="grid rounded-[10px] border border-solid bg-[#fff] font-GolosText shadow-base tb:rounded-xl tb:border-[2px] dk:rounded-2xl dk:border-[3px]">
      {!hiddenHeader && (
        <div className="flex-flow-col flex justify-between px-3 py-3 tb:px-6 tb:py-5 dk:px-10 dk:py-8">
          <div className="flex items-center text-[10px] leading-4 text-fonts-optional tb:text-base tb:leading-6 dk:text-lg dk:leading-7">
            {actualMatch.tournamentType === "ROUND-ROBIN"
              ? actualMatch.tournamentTitle
              : `round ${actualMatch?.round}`}
          </div>
          <div className="flex items-center gap-1 justify-self-end rounded-full bg-[#EFEFEF] px-2 py-1 font-GolosText text-xs font-normal tb:gap-2 tb:px-[10px] tb:py-1.5 dk:gap-3 dk:px-4 dk:py-2">
            {!isFinished ? (
              <>
                <Image src={ClockIcon} alt="" className="h-3 w-3 tb:h-4 tb:w-4 dk:h-6 dk:w-6" />
                <div className="text-xs leading-4 tb:text-lg tb:leading-6 dk:text-xl dk:leading-7">
                  <span className="font-GolosText text-[#575757]">
                    {actualMatch.tournamentType === "ROUND-ROBIN"
                      ? "Tournament Ends in"
                      : "Matchweek Ends in"}
                  </span>
                  <span className="ml-1 font-GolosText font-bold text-fonts-accentColor">
                    {value}
                  </span>
                </div>
              </>
            ) : (
              <>
                <Image src={ClockIcon} alt="" className="h-3 w-3 tb:h-4 tb:w-4 dk:h-6 dk:w-6" />
                <div className="text-xs leading-4 tb:text-lg tb:leading-6 dk:text-xl dk:leading-7">
                  <span className="font-GolosText text-[#575757]">Matchweek Ended</span>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div
        className={classNames(
          "h-full w-full  px-4 pb-4 pt-3  tb:px-6 tb:pb-6 tb:pt-5  dk:px-10 dk:pb-10 dk:pt-8",
          {
            "border-t-[1px] tb:border-t-[2px] dk:border-t-[3px]": !hiddenHeader,
          },
        )}
      >
        <div className="grid grid-flow-col-dense">
          <div className="flex flex-col justify-center gap-6 tb:gap-3 dk:gap-14">
            {actualMatch?.orderedResult.teams?.map((item) => (
              <div className="flex flex-row items-center gap-3 tb:gap-4 dk:gap-6" key={item.id}>
                <Link to={`/user/${item.members?.[0]?.user}`} className="contents">
                  <PlayerAvatar
                    highlighted={item.userId === currentUserId}
                    image={item.members?.[0]?.avatar?.image ?? ""}
                    classNameWrapper="dk:w-16 dk:h-16 tb:w-14 tb:h-14 w-8 h-8"
                    className="h-8 w-8 bg-clip-content p-[2px] tb:h-14 tb:w-14 tb:p-[3px] dk:h-16 dk:w-16 dk:p-1"
                  />
                  <span
                    className={classNames(
                      "text-base leading-6 text-fonts-accentColor tb:text-2xl dk:text-3xl",
                      {
                        "text-fonts-accentColor": item.members?.[0]?.user === currentUserId,
                        "text-fonts-darken": item.members?.[0]?.user !== currentUserId,
                        "font-semibold": actualMatch?.winner === item.id,
                      },
                    )}
                  >
                    {item.members?.find((item) => item.user === currentUserId)
                      ? "Me"
                      : item.members?.[0]?.firstName ?? `Opponent ${item.userId}`}
                  </span>
                </Link>
                {/* Uncomment when leaderboard is working  */}
                {/* <DefaultBadge
                  title={`#${item.globalRating.toString()}`}
                  className="text-[#000] border-[#FFC657] border-0 rounded-[8px] dk:px-3 dk:py-2 tb:px-1.5 tb:py-1.5 p-1 bg-[#FFC657] dk:text-lg tb:text-base text-[10px] dk:leading-7 tb:leading-6 leading-4"
                /> */}
              </div>
            ))}
          </div>
          {results && <GameResults {...results} />}
        </div>
      </div>
    </div>
  );
};

export default SoloMatchCard;

import { type FocusEventHandler, type DetailedHTMLProps, type FC } from "react";
import type React from "react";
import RadioButton from "./radio";
import classNames from "classnames";

type GroupTitleProps = {
  value: string;
  component?: React.ComponentType;
  titleClass?: string;
};

type OptionsType = Record<string, any>;

type RadioButtonGroupProps<O = OptionsType> = {
  title?: GroupTitleProps;
  options: { [P in keyof O]: O[P] };
  selectedOption?: keyof O;
  onOptionSelect: (option: keyof O) => void;
  renderItemDescription?: (option: keyof O) => React.ReactNode;
  renderItem?: (option: O) => React.ReactNode;
  className?: string;
  variantsClassName?: string;
  emoji?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

const DefaultComponent: FC<
  DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ ...props }) => <div {...props}></div>;

const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  title,
  options,
  onOptionSelect,
  selectedOption,
  renderItem,
  renderItemDescription,
  className,
  variantsClassName,
  inputRef,
  onBlur,
}) => {
  const {
    component: GroupTitleComponent = DefaultComponent,
    titleClass: groupTitleClassname,
    value: titleText,
  } = title ?? { component: undefined, titleClass: undefined, value: undefined };
  return (
    <div className={classNames(className)}>
      {title && (
        <GroupTitleComponent className={groupTitleClassname}>{titleText}</GroupTitleComponent>
      )}
      <div className={classNames(variantsClassName, "cursor-pointer")}>
        {Object.keys(options).map((key, index) => (
          <div
            className={classNames("grid grid-cols-[1fr_auto] grid-rows-[auto_auto]", {
              "mt-3 tb:mt-4 dk:mt-6": index !== 0,
              "": index === 0,
            })}
            key={`${key}-${index}`}
            onClick={(e) => {
              onOptionSelect(key);
            }}
            onBlur={onBlur}
          >
            {renderItem?.(options[key]) ?? (
              <span
                className={classNames(
                  "text-base leading-6 tb:text-xl tb:leading-8 dk:text-3xl dk:leading-9",
                  { "GrayscaleFilter text-fonts-optional": key === "LOCKED_EXPERT" },
                )}
              >
                {options[key]}
              </span>
            )}
            <RadioButton
              inputRef={inputRef}
              onChange={() => {
                onOptionSelect(key);
              }}
              id={`${key}-${index}`}
              checked={selectedOption === key}
              value="Women"
              className="py-2 tb:py-3 dk:py-4"
              labelClassName={classNames("self-center", {
                "border-[#d4d8d4] OpacityFilter": key === "LOCKED_EXPERT",
              })}
              onBlur={index === 0 ? onBlur : undefined}
            />
            {renderItemDescription?.(key)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioButtonGroup;

import { type FC } from "react";
import Button, { ButtonStyle } from "@double-bagel/components/buttons/default-button";
import Image from "@double-bagel/components/image";
import ImageSource from "@double-bagel/assets/Images/bagel-cartoon-rocket 3.png";

type NotificationModalProps = {
  onClose: () => void;
  heading?: string;
  text?: string;
  buttonLabel?: string;
};

const NotificationModal: FC<NotificationModalProps> = ({ heading, text, onClose, buttonLabel }) => {
  return (
    <>
      <Image
        src={ImageSource}
        className="z-10 h-[106px] tb:h-[130px] dk:h-[180px]"
        alt="bagel-cartoon"
      />
      <div className="z-10 mt-3.5 text-base font-semibold leading-6 tb:text-xl tb:leading-8 dk:text-3xl dk:leading-9">
        {heading}
      </div>
      <div className="z-10 mt-2 pt-0 text-center font-GolosText text-base tb:pt-1 tb:text-lg dk:pt-1 dk:text-2xl">
        {text}
      </div>
      <div className="z-10 mt-5 flex flex-col justify-stretch">
        <Button
          style={ButtonStyle.Primary}
          onClick={() => {
            onClose();
          }}
        >
          {buttonLabel}
        </Button>
      </div>
    </>
  );
};

export default NotificationModal;

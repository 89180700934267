import useAsync, { type AsyncStatuses } from "hooks/useAsync";
import { isArray, isObject } from "helpers";
import { type ClientModel, toModel } from "./client-models";
import { DoubleBagelAppException } from "./errors";

type ReturnValueResult<T> = T extends (infer P)[]
  ? ClientModel<P>[]
  : T extends Record<string, unknown>
    ? ClientModel<T>
    : T;

type AsHookResult<T> = {
  status: AsyncStatuses;
  value?: ReturnValueResult<T>;
  error?: any;
  isFetching?: boolean;
};

export type AsHookWrappedFunc<R> = {
  result: ReturnValueResult<R> | null;
  error: Error | null;
};

class UnhandledResponseTypeException extends DoubleBagelAppException {}

function asHook<R extends object[] | object, A extends any[]>(
  fn: (...args: A) => Promise<R>,
  modelName: string,
) {
  return (): AsHookResult<R> & {
    execute: (...args: A) => Promise<AsHookWrappedFunc<R>>;
  } => {
    const { execute, status, value, error } = useAsync(async (...args: A) => {
      const baseResult = await fn(...args);
      if (isObject(baseResult)) {
        const result = toModel(modelName, baseResult);
        return result as ReturnValueResult<R>;
      } else if (isArray(baseResult)) {
        const result = baseResult.map((item) => toModel(modelName, item as R));
        return result as ReturnValueResult<R>;
      } else {
        // В данный момент не считаются валидными ответы отличные от обьекта или списка обьектов
        throw new UnhandledResponseTypeException(
          "Unhandled response type from server. Check as hook method.",
        );
      }
    }, false);
    const isFetching = status === "pending";
    return {
      execute: execute as (...args: A) => Promise<AsHookWrappedFunc<R>>,
      isFetching,
      status,
      value,
      error,
    };
  };
}

export default asHook;

import { type FC } from "react";
import Image from "components/image";
import Icon from "assets/Icons/buttons/nav-back.svg";

interface NavigationBackProps {
  onClick?: () => void;
  className?: string;
}

const NavigationBack: FC<NavigationBackProps> = ({ onClick, className }) => (
  <div className="grid cursor-pointer grid-flow-col-dense items-center gap-2" onClick={onClick}>
    <div className="flex h-[32px] w-[32px] items-center justify-center rounded-full bg-[#FAFAFA] tb:h-[40px] tb:w-[40px] dk:h-[48px] dk:w-[48px]">
      <Image src={Icon.toString()} alt="back" className={className} />
    </div>
  </div>
);

export default NavigationBack;

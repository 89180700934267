import classNames from "classnames";
import { useState, type FC, useEffect } from "react";

export type GameScore = number | undefined;
export type ScoreValue = [GameScore, GameScore, GameScore];

type ScoresInputProps = {
  value: ScoreValue;
  onChange: (value: ScoreValue) => void;
  className?: string;
  isItemsActive?: [boolean, boolean, boolean];
  disabled?: boolean;
  ref1: React.RefObject<HTMLInputElement>;
  ref2: React.RefObject<HTMLInputElement>;
  ref3: React.RefObject<HTMLInputElement>;
  maxLength?: number;
};

const inputCls =
  "h-[39px] tb:h-[45px] dk:h-[68px] w-[28px] tb:w-[36px] dk:w-[66px] rounded-[5px] bg-[#FAFAFA] leading-[29px] text-[#868686] text-center text-base tb:text-[20px] dk:text-[30px]";

const ScoresInput: FC<ScoresInputProps> = ({
  value,
  onChange,
  className,
  isItemsActive,
  disabled,
  ref1,
  ref2,
  ref3,
  maxLength = 1,
}) => {
  const [val1, setVal1] = useState<number | "">();
  const [val2, setVal2] = useState<number | "">();
  const [val3, setVal3] = useState<number | "">();
  useEffect(() => {
    if (value.filter((item) => item !== undefined)?.length) {
      const [val1, val2, val3] = value;
      setVal1(val1);
      setVal2(val2);
      setVal3(val3);
    }
  }, [value]);

  const handleChange = (index: 0 | 1 | 2, value: string): void => {
    let val;
    if (value === "") {
      val = "";
    } else {
      const parsed = parseInt(value);
      if (isNaN(parsed)) {
        return;
      } else {
        val = parsed;
      }
    }
    // const num = value !== "" ? Number(value) : "";
    switch (index) {
      case 0:
        setVal1(val as any);
        break;
      case 1:
        setVal2(val as any);
        break;
      case 2:
        setVal3(val as any);
        break;
    }
  };
  useEffect(() => {
    onChange([
      val1 === "" ? undefined : val1,
      val2 === "" ? undefined : val2,
      val3 === "" ? undefined : val3,
    ]);
  }, [val1, val2, val3]);
  return (
    <div className={classNames("grid grid-flow-col justify-end gap-[12px]", className)}>
      <input
        ref={ref1}
        disabled={disabled ?? !isItemsActive?.[0] ?? false}
        type="text"
        inputMode="numeric"
        pattern="(^[0-9]+$|^$)"
        className={classNames(inputCls, { "bg-fonts-optional": !isItemsActive?.[0] ?? false })}
        value={val1 ?? ""}
        maxLength={1}
        onChange={(event) => {
          handleChange(0, event?.target?.value);
        }}
      />
      <input
        ref={ref2}
        disabled={disabled ?? !isItemsActive?.[1] ?? false}
        type="text"
        inputMode="numeric"
        pattern="/^(\s*|\d+)$/"
        className={classNames(inputCls, { " bg-fonts-optional": !isItemsActive?.[1] ?? false })}
        value={val2 ?? ""}
        maxLength={1}
        onChange={(event) => {
          handleChange(1, event?.target?.value);
        }}
      />
      <input
        ref={ref3}
        disabled={disabled ?? !isItemsActive?.[2] ?? false}
        type="text"
        inputMode="numeric"
        pattern="/^(\s*|\d+)$/"
        className={classNames(inputCls, { " bg-fonts-optional": !isItemsActive?.[2] ?? false })}
        value={val3 ?? ""}
        maxLength={maxLength}
        onChange={(event) => {
          handleChange(2, event?.target?.value);
        }}
      />
    </div>
  );
};

export default ScoresInput;

import { useState, type FC, useEffect } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import Loader from "@double-bagel/components/loaders/default";

type PageLayoutFadingProps = {
  isLoading?: boolean;
  children?: React.ReactNode;
};
const PageLayoutFading: FC<PageLayoutFadingProps> = ({ children, isLoading }) => {
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState<"fadeIn" | "fadeOut">("fadeIn");

  useEffect(() => {
    if (location.pathname !== displayLocation.pathname) setTransistionStage("fadeOut");
    return () => {
      setTransistionStage("fadeIn");
    };
  }, [location, displayLocation]);
  return (
    <>
      {isLoading ? (
        <div className="min-h-[100vh]">
          <Loader className="h-[42px] w-[42px] border-[4px] tb:h-[56px] tb:w-[56px] tb:border-[5px] dk:h-[64px] dk:w-[64px] dk:border-[6px]" />
        </div>
      ) : (
        <div
          className={classNames("h-[100vh] bg-background", {
            "animate-fadeIn": transitionStage === "fadeIn",
            "animate-fadeOut": transitionStage === "fadeOut",
          })}
          onAnimationEnd={() => {
            setTransistionStage("fadeIn");
            setDisplayLocation(location);
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default PageLayoutFading;
